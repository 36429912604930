import { BrowserRouter as Router } from "react-router-dom";
import "./GlobalStyles.css";
// import { HospitalContextProvider } from "./store/data-context";

import AppRoutes from "./routes";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      {/* <HospitalContextProvider> */}
        <div className="App">
          <AppRoutes />
        </div>
      {/* </HospitalContextProvider> */}
    </Router>
  );
}

export default App;
