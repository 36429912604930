/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Phone from "../../../../assets/otp_phone.svg";
import CodeVerficationModal from "../CodeVerficationModal/CodeVerficationModal";
import "./PhoneVerficationModal.css";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import adminController from "../../controller/AdminController";
import notifications from "../../../../Components/toastify/notifications";
function PhoneVerficationModal({ handleClose, show, setShow }) {
  const { admin, data } = useContext(HospitalDataContext);
  const { t, i18n } = useTranslation();
  const [number, setNumber] = useState(data.hospital[0]?.phone2);
  const [code, setCode] = useState(false);
  const handleCloseCode = () => setCode(!code);
  const handleOpenCode = () => setCode(!code);
  const handlePhoneVerfication = (e) => {
    e.preventDefault();

    handleClose();
    handleOpenCode();
  };
  // const countryKey = () => {
  //   if (admin?.hospital_admin?.country_id === 1) {
  //     return 90;
  //   } else if (admin?.hospital_admin?.country_id === 2) {
  //     return 974;
  //   }
  // };
  return (
    <>
      {data?.hospital[0]?.phone_verified_at || data?.hospital?.length === 0 ? (
        ""
      ) : (
        <>
          <Modal show={show} onHide={handleClose} centered>
            <img src={Phone} alt="phone" className="phone" />
            <Modal.Body>
              {data?.hospital[0]?.phone2 ? (
                <Form>
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    controlId="formBasicEmail"
                  >
                    <Form.Label> {t("phone_note")}</Form.Label>
                    {/* /update-hospital */}
                    {i18n.language === "ar" ? (
                      <InputGroup>
                        <Form.Control
                          type="text"
                          className="no-border"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        />
                        {/* <InputGroup.Text className="no-border">
                          {" "}
                          {countryKey()} +
                        </InputGroup.Text> */}
                      </InputGroup>
                    ) : (
                      <InputGroup>
                        {/* <InputGroup.Text className="no-border">
                          + {countryKey()}{" "}
                        </InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          className="no-border"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        />
                      </InputGroup>
                    )}
                  </Form.Group>
                  {number ? "" : <span>{t("edit_phone")}</span>}
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      handlePhoneVerfication(e);
                      adminController.code();
                    }}
                    className="save-btn"
                  >
                    {t("next")}
                  </Button>
                  {/* 
                  <Button
                    variant="primary"
                    onClick={() => {
                      adminController.code();
                    }}
                    className="save-btn"
                  >
                    {t("next")}0000000
                  </Button> */}
                </Form>
              ) : (
                <>
                  {number ? (
                    ""
                  ) : (
                    <article className="edit-phone-mobile">
                      <span>
                        {t("edit_phone")}
                        {/* You Must Enter you mobile number Go to update Profile */}
                      </span>

                      <Link className="update-btn" to={"/update-hospital"}>
                        {t("edit_mobile2")}
                      </Link>
                    </article>
                  )}
                </>
              )}

              <button
                onClick={() => {
                  handleClose();
                }}
                className="next-time-btn"
              >
                {t("next_time")}
              </button>
            </Modal.Body>
          </Modal>
          <CodeVerficationModal
            code={code}
            setCode={setCode}
            handleCloseCode={handleCloseCode}
            setShow={setShow}
            show={show}
            handleClose={handleClose}
            number={number}
          />
        </>
      )}
    </>
  );
}

export default PhoneVerficationModal;
