/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./InfoUserCouponModal.css";
import { useTranslation } from "react-i18next";
function InfoUserCouponModal({
  handleCloseUserInfo,
  showUserInfo,
  userPhone,
  email,
}) {
  const { t } = useTranslation();

  const checkemail = (email) => {
    const facebook = "@facebook";
    const google = "@google";

    const test = email.includes(facebook || google);
    return test;
  };

  const checkPhone = (char) => {
    return /^\d$/.test(char);
  };
  return (
    <>
      <Modal show={showUserInfo} onHide={handleCloseUserInfo}>
        <Modal.Header>
          <Modal.Title className="title-model-clinic-appointment">
            {t("user_Info")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <section className="user-info">
            <fieldset className="inno">
              <label className="label4input" htmlFor="phone">
                {t("phone_number_label")}
              </label>
              <input
                className="input-phone "
                placeholder={t("phone_number_label")}
                name="hospital-name"
                type="text"
                tabIndex="1"
                id="hospital-name"
                value={
                  checkPhone(userPhone) ? `${t("not_verfiy_phone")}` : userPhone
                }
                required
              />
            </fieldset>

            <fieldset className="inno">
              <label className="label4input" htmlFor="email">
                {t("email_label")}
              </label>
              <input
                className="input-phone "
                placeholder={t("email_label")}
                name="hospital-name"
                type="email"
                tabIndex="1"
                id="hospital-name"
                value={checkemail(email) ? `${t("not_verfiy_email")}` : email}
                required
              />
            </fieldset>
          </section>
          <Button
            variant="primary"
            className="save-btn"
            onClick={handleCloseUserInfo}
          >
            {t("close")}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InfoUserCouponModal;
