import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./PatientCouponCard.css";
import InfoUserCouponModal from "../InfoUserCouponModal/InfoUserCouponModal";

// Plugins

const PatientCouponCard = (props) => {
  const { t } = useTranslation();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const handleCloseUserInfo = () => setShowUserInfo(!showUserInfo);
  const handleShowUserInfo = () => setShowUserInfo(true);

  return (
    // <div className="pwide-cont">
    <div className="pwide-cont card-offers" key={props.id}>
      <div className="growing">
        <div className="phead">
          <p>{t("name_coupon")}</p>
        </div>
        <div className="pbody">
          <p>{props.name}</p>
        </div>
      </div>
      <div className="growing">
        <div className="phead">
          <p>{t("patient_name")}</p>
        </div>
        <div className="pbody">
          <p>
            {props.first_name} {props.last_name}
          </p>
        </div>
      </div>
      <div className="growing">
        <div className="phead">
          <p>{t("date_of_purchase")}</p>
        </div>
        <div className="pbody">
          {/* <p>{props.name}</p> */}
          <p>{props.create_at.slice(0, 10)}</p>
        </div>
      </div>
      <div className="growing">
        <div className="phead">
          <p>{t("times_used")}</p>
        </div>
        <div className="pbody">
          <p>{props.number_used}</p>
        </div>
      </div>
      <div className="growing">
        <div className="phead">
          <p>{t("max_allowed")}</p>
        </div>
        <div className="pbody">
          <p>{props.max_allowed_uses}</p>
        </div>
      </div>
      <div className="growing">
        <div className="phead">
          <p>{t("date_use")}</p>
        </div>
        <div className="pbody">
          <p>
            {props.last_used !== null
              ? props.last_used.slice(0, 11)
              : "Not used yet"}
          </p>
        </div>
      </div>
      <div className="growing">
        <div className="phead">
          <p>{t("membership")}</p>
        </div>
        <div className="pbody">
          <p>Free</p>
        </div>
      </div>
      <div className="growing">
        <div className="phead">
          <p>{t("status")}</p>
        </div>
        <div className="pbody">
          {/* <p>{props.name}</p> */}
          <p>{props.status ? "Used" : "Valid"}</p>
        </div>
      </div>

      <div className="growing">
        <div className="phead">
          <p>{t("details")}</p>
        </div>
        <div className="pbody card-btns">
          <button
            onClick={handleShowUserInfo}
            id={props.id}
            className="first-btn info-user-coupon"
          >
            {t("user_Info")}
          </button>
        </div>
        <InfoUserCouponModal
          handleCloseUserInfo={handleCloseUserInfo}
          showUserInfo={showUserInfo}
          userPhone={props.phone}
          email={props.email}
          id={props.id}
        />
      </div>
    </div>
    // </div>
  );
};

export default PatientCouponCard;
