// **In the name of Allah, Most Gracious, Most Merciful**
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthContextProvider } from "./store/auth-context";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import "./index.css";
import App from "./App";
import { HospitalContextProvider } from "./store/data-context";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ["ar", "en", "tr"],
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ["cookie", "path", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "assets/locales/{{lng}}/translation.json",
    },
  });

// I removed these from the end of detection above 'querystring', 'sessionStorage', 'navigator'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <HospitalContextProvider>
      <App />
    </HospitalContextProvider>
  </AuthContextProvider>
);
// document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// (**IGNORE**) a simple example of a context api data persistance
// import { createContext, useState } from 'react';

// export const ProductsContext = createContext({
//   products: []
// });

// export const ProductsProvider = ({ children }) => {
//   const [products, setProducts] = useState(PRODUCTS);
//   const value = { products };

//   return (
//     <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>
//   )
// }

// const { products } = useContext(ProductsContext)
