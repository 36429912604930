import appointmentServices from "../services/AppointmentServices";
import profileDoctorServices from "../../ProfileDoctor/services/ProfileDoctorServices";
import hcpProfileServices from "../../ProfileHcp/services/HcpProfileServices";
class AppointmentController {
  async handleClinicAppointment(startTime, endTime, work_days) {
    try {
      let formData = new FormData();
      formData.append("start_time", startTime);
      formData.append("end_time", endTime);
      formData.append("work_days", work_days);
      const res = await appointmentServices.clinic_appointmet(formData);

      return res;
    } catch (error) {
      return error;
    }
  }

  async handleDoctorAppointment(
    doctorId,
    startTime,
    endTime,
    durationTime,
    day
  ) {
    try {
      let formData = new FormData();
      formData.append("hospital_doctor_id", doctorId);
      formData.append("start", startTime);
      formData.append("end", endTime);
      formData.append("duration", durationTime);
      formData.append("day", day);
      const res = await appointmentServices.doctor_appointmet(formData);

      return res;
    } catch (error) {
      return error;
    }
  }
  async handleHcpAppointment(hcpId, startTime, endTime, durationTime, day) {
    try {
      let formData = new FormData();
      formData.append("hospital_hcp_id", hcpId);
      formData.append("start", startTime);
      formData.append("end", endTime);
      formData.append("duration", durationTime);
      formData.append("day", day);
      const res = await appointmentServices.hcp_appointmet(formData);

      return res;
    } catch (error) {
      return error;
    }
  }

  handleKeywords(e, workDays, setWorkDays) {
    let updateDays = [...workDays];
    if (e.target.checked) {
      updateDays = [...workDays, e.target.value];
    } else {
      updateDays.splice(workDays.indexOf(e.target.value), 1);
    }
    setWorkDays(updateDays);
  }
  handleStartTime(e, setStartTime) {
    setStartTime(e.target.value);
  }
  checkTime(end, startTime, setErrorTime) {
    const s = startTime.split(":");
    const e = end.split(":");
    const start_time = Number(s[0]);
    const end_time = Number(e[0]);
    if (end_time < start_time || end_time === start_time) {
      setErrorTime("You Must choose a time bigger than the start Time");
    } else {
      setErrorTime("");
    }
  }
  handleEndTime(e, startTime, setErrorTime, setEndTime) {
    setEndTime(e.target.value);
    const end = e.target.value;
    this.checkTime(end, startTime, setErrorTime);
  }

  handleDay(e, setDay) {
    setDay(e.target.value);
  }
  handleDurationTime(e, setDurationTime) {
    setDurationTime(e.target.value);
  }

  async handleClinicApppointment(
    e,
    startTime,
    endTime,
    workDays,
    notifications,
    setStartTime,
    setEndTime,
    setWorkDays,
    handleClose,
    t
  ) {
    e.preventDefault();
    const res = await this.handleClinicAppointment(
      startTime,
      endTime,
      workDays.toString()
    );
    if (res.message === "success") {
      notifications.notifySuccess(t("done_clinic_appointment"));
      setStartTime("");
      setEndTime("");
      setWorkDays([]);
      handleClose();
    } else {
      if (res.error.work_days) {
        notifications.notifyError(t("error_work_day"));
      } else if (res.error.start_time) {
        notifications.notifyError(t("error_start_time"));
      } else {
        notifications.notifyError(t("error_end_time"));
      }
    }
  }

  async handleDoctorApppointment(
    e,
    doctorId,
    startTime,
    endTime,
    durationTime,
    day,
    notifications,
    t,
    setStartTime,
    setEndTime,
    setDay,
    setDurationTime,
    setDoctorId,
    handleCloseDr
  ) {
    e.preventDefault();
    const res = await this.handleDoctorAppointment(
      doctorId,
      startTime,
      endTime,
      durationTime,
      day
    );
    if (res.message === "success") {
      notifications.notifySuccess(t("done_doctor_appointment"));
      setStartTime("");
      setEndTime("");
      setDay();
      setDurationTime();
      setDoctorId();
      handleCloseDr();
    } else {
      if (doctorId === null) {
        notifications.notifyError(t("error_id_doctor"));
      } else if (res.error?.day) {
        notifications.notifyError(t("error_work_day"));
      } else if (res.error?.start) {
        notifications.notifyError(t("error_start_time"));
      } else if (res.error?.end) {
        notifications.notifyError(t("error_end_time"));
      } else {
        notifications.notifyError(t("error_duration"));
      }
    }
  }
  async handleHcpApppointment(
    e,
    hcpId,
    startTime,
    endTime,
    durationTime,
    day,
    notifications,
    t,
    setStartTime,
    setEndTime,
    setDay,
    setDurationTime,
    setHcpId,
    handleCloseHcp
  ) {
    e.preventDefault();
    const res = await this.handleHcpAppointment(
      hcpId,
      startTime,
      endTime,
      durationTime,
      day
    );
    if (res.message === "success") {
      notifications.notifySuccess(t("done_hcp_appointment"));
      setStartTime("");
      setEndTime("");
      setDay();
      setDurationTime();
      setHcpId();
      handleCloseHcp();
    } else {
      if (hcpId === undefined) {
        notifications.notifyError(t("error_id_hcp"));
      } else if (res.error.day) {
        notifications.notifyError(t("error_work_day"));
      } else if (res.error.start) {
        notifications.notifyError(t("error_start_time"));
      } else if (res.error.end) {
        notifications.notifyError(t("error_end_time"));
      } else {
        notifications.notifyError(t("error_duration"));
      }
    }
  }
  dayName(dateDay, weekday) {
    const date = new Date(dateDay?.slice(0, 10));
    const numberDay = date.getDay();
    return weekday[numberDay];
  }

  async handleAcceptance(e, setIsAdded) {
    const res = await appointmentServices.approve_appointment(e.target.id);
    if (res.message === "success") {
      setIsAdded(true);
      window.location.reload(false);
      // <AddedSuccessfullyModal text={"تم الموافقة على المريض بنجاح"} />
    } else {
      // logic to display a text area with error details
    }
  }

  async handleFinish(e, setIsFinished) {
    const res = await appointmentServices.reject_appointment(e.target.id);
    if (res.message === "success") {
      setIsFinished(true);
      window.location.reload(false);
    } else {
      // logic to display a text area with error details
    }
  }

  async handleAcceptanceHcp(e, setIsAdded) {
    const res = await appointmentServices.approve_hcp_appointment(e.target.id);
    if (res.message === "success") {
      setIsAdded(true);
      window.location.reload(false);
      // <AddedSuccessfullyModal text={"تم الموافقة على المريض بنجاح"} />
    } else {
      // logic to display a text area with error details
    }
  }

  async handleFinishHcp(e, setIsFinished) {
    const res = await appointmentServices.reject_hcp_appointment(e.target.id);
    if (res.message === "success") {
      setIsFinished(true);
      window.location.reload(false);
    } else {
      // logic to display a text area with error details
    }
  }
  async handle_finish_appointment(e) {
    const res = await appointmentServices.finish_appointment(e.target.id);
    console.log({ resFinish_appointment: res });
    if (res.message === "success") {
      // setIsFinished(true);
      window.location.reload(false);
    } else {
      // logic to display a text area with error details
    }
  }

  // "hospital_appointment_id" => "required",
  //             "date" => "required",
  //             "hospital_doctor_work_hour_id" => "required",
  //             "hospital_doctor_id" => "required
  async handleEditApppointment(
    e,
    hospital_appointment_id,
    date,
    hospital_doctor_work_hour_id,
    hospital_doctor_id,
    handleClose,
    notifications,
    t
  ) {
    e.preventDefault();

    let formData = new FormData();
    formData.append("hospital_appointment_id", hospital_appointment_id);
    formData.append("date", date);
    formData.append(
      "hospital_doctor_work_hour_id",
      hospital_doctor_work_hour_id
    );
    formData.append("hospital_doctor_id", hospital_doctor_id);
    const res = await appointmentServices.edit_appointment(formData);
    // console.log(res);
    if (res.message === "success") {
      // notifications.notifySuccess(t("done_edit_appointment"));
      handleClose();
      window.location.reload(false);
    } else {
      // notifications.notifyError(t("error_try_again"));
    }
  }
  async handleGetDay(doctorId, nameDay, setWorkSchedule) {
    const res = await profileDoctorServices.get_work_hour_profile_doctor(
      doctorId,
      nameDay
    );
    setWorkSchedule(res.work_schedule);

    // if (res.message === "success") {
    //   notifications.notifySuccess(t("done_edir_appointment"));
    //   handleClose();
    //   window.location.reload(false);
    // } else {
    //   notifications.notifyError(t("error_try_again"));
    // }
  }

  async handleHcpEditApppointment(
    e,
    hospital_hcp_appointment_id,
    date,
    hospital_hcp_work_hour_id,
    hospital_hcp_id,
    handleClose,
    notifications,
    t
  ) {
    e.preventDefault();

    let formData = new FormData();
    formData.append("hospital_hcp_appointment_id", hospital_hcp_appointment_id);
    formData.append("date", date);
    formData.append("hospital_hcp_work_hour_id", hospital_hcp_work_hour_id);
    formData.append("hospital_hcp_id", hospital_hcp_id);
    const res = await appointmentServices.edit_hcp_appointment(formData);
    if (res.message === "success") {
      notifications.notifySuccess(t("done_edit_appointment"));
      handleClose();
      window.location.reload(false);
    } else {
      notifications.notifyError(t("error_try_again"));
    }
  }
  async handleGetDayHcp(hcpId, nameDay, setWorkScheduleHcp) {
    const res = await hcpProfileServices.get_work_hour_profile_hcp(
      hcpId,
      nameDay
    );
    setWorkScheduleHcp(res.work_schedule);

    // if (res.message === "success") {
    //   notifications.notifySuccess(t("done_edir_appointment"));
    //   handleClose();
    //   window.location.reload(false);
    // } else {
    //   notifications.notifyError(t("error_try_again"));
    // }
  }

  getDate(e, date, setDay, weekCurrent, setNameDay, doctorId, nameDay) {
    const d = new Date(date);
    const days = d.getDay();
    setDay(e.target.value);
    const nnnnnn = weekCurrent[days]?.value;
    setNameDay(nnnnnn);
    appointmentController.handleGetDay(doctorId, nameDay);
  }
}

const appointmentController = new AppointmentController();

export default appointmentController;
