/* eslint-disable no-unused-vars */
import { useState, useContext } from "react";
import "./ReservationDetails.css";
import appointmentServices from "../../services/AppointmentServices";
import appointmentController from "../../controller/AppointmentController";
import { RiStethoscopeFill } from "react-icons/ri";
import { AiOutlineFieldTime } from "react-icons/ai";
import { IoTicketOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import { BiTime } from "react-icons/bi";

import image from "./profile-pic.jpeg";

const ReservationDetailsHcp = ({
  accepted,
  finished,
  id,
  firstName,
  lastName,
  date,
  day,
  time,
}) => {
  const [isAdded, setIsAdded] = useState(false);
  // const [isRemoved, setIsRemoved] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { t } = useTranslation();
  const { weekday } = useContext(HospitalDataContext);

  //   const handleAcceptance = (e) => {
  //     async function approveAppointment() {
  //       const res = await appointmentServices.approve_appointment(e.target.id);
  //       if (res.message === "success") {
  //         setIsAdded(true);
  //         window.location.reload(false);
  //         // <AddedSuccessfullyModal text={"تم الموافقة على المريض بنجاح"} />
  //       } else {
  //         // logic to display a text area with error details
  //       }
  //     }
  //     approveAppointment();
  //   };

  //   const handleFinish = (e) => {
  //     async function finishAppointment() {
  //       const res = await appointmentServices.reject_appointment(e.target.id);
  //       if (res.message === "success") {
  //         setIsFinished(true);
  //         window.location.reload(false);
  //       } else {
  //         // logic to display a text area with error details
  //       }
  //     }
  //     finishAppointment();
  //   };
  return (
    <div className="pwide-cont2">
      <div>
        <h4 className="main-heading">{t("reservation_details")}</h4>
        <div className="distribute">
          {/* <p>
            <RiStethoscopeFill className="title-icon" />
            كشف بالمنزل
          </p> */}
          <p>
            <AiOutlineFieldTime className="title-icon" />
            {date}
            {appointmentController.dayName(day, weekday)}
          </p>
          {/* <p>
            <IoTicketOutline className="title-icon" />
            تم الدفع اونلاين
          </p> */}
          <p>
            <BiTime className="title-icon" />
            {time}
          </p>
        </div>
      </div>
      <div>
        <h4 className="main-heading">{t("patient_data")}</h4>
        <div className="card-body">
          {/* <div className="sm">
            <img src={image} alt="" />
          </div> */}
          <div className="bg">
            <p className="bolded">{firstName + " " + lastName}</p>
            {/* <p className="faded">سبب الاتصال : استشارة</p>
            <p className="faded">مدة الاتصال : ١٥ دقيقة</p> */}
          </div>
        </div>
      </div>
      <div className="card-btns">
        {accepted && <div className="action-yes">{t("reservation_done")}</div>}
        {finished && (
          <div className="action-no">{t("reservation_finished")}</div>
        )}
        {!accepted && !finished && (
          <button
            onClick={(e) =>
              appointmentController.handleAcceptanceHcp(e, setIsAdded)
            }
            // onClick={handleAcceptance}
            id={id}
            className="first-btn"
          >
            {t("accept")}
          </button>
        )}
        {!accepted && !finished && (
          <button
            onClick={(e) =>
              appointmentController.handleFinishHcp(e, setIsFinished)
            }
            // onClick={handleFinish}
            id={id}
            className="second-btn"
          >
            {t("reject")}
          </button>
        )}
      </div>
    </div>
  );
};

export default ReservationDetailsHcp;
