import axiosProvider from "../../../api/base";

class AdminServices {
  // Get Admin Profile
  async get_hospital_admin() {
    try {
      const response = await axiosProvider.getRequest("/get-hospital-admin");
      return response;
    } catch (error) {
      console.log({ error });
      return error;
    }
  }
  async codeV() {
    try {
      const response = await axiosProvider.getRequest("/request-otp");
      return response;
    } catch (error) {
      console.log({ error });
      return error;
    }
  }
  async codeconfimation(codeNumber) {
    try {
      const response = await axiosProvider.postRequest("/verify-phone", {
        pin: codeNumber,
      });
      return response;
    } catch (error) {
      console.log({ error });
      return error;
    }
  }

  async editAdminPhone(number) {
    try {
      const response = await axiosProvider.postRequest("/change-phone", {
        phone: number,
      });
      return response;
    } catch (error) {
      console.log({ error });
      return error;
    }
  }
}

const adminServices = new AdminServices();

export default adminServices;
