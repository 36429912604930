import hospitalServices from "../services/HospitalServices";

class HospitalController {
  // handleCreateHospital
  async handleCreateHospital(data) {
    try {
      if (data.logo === null || data.logo === undefined) {
        return null;
      } else if (
        data.hospitalCountry === "" ||
        data.hospitalCity === "" ||
        data.name === "" ||
        data.adress === "" ||
        data.phone === "" ||
        data.bio === "" ||
        data.email === "" ||
        // data.site === "" ||
        data.facebook === "" ||
        data.instagram === "" ||
        data.youtube === "" ||
        data.twitter === "" ||
        data.linkedin === ""
      ) {
        return null;
      } else {
        let formData = new FormData();
        formData.append("logo", data.logo);
        formData.append("name", data.name);
        formData.append("adress", data.adress);
        formData.append("phone", data.phone);
        formData.append("bio", data.bio);
        formData.append("email", data.email);
        // formData.append("site", data.site);
        formData.append("country_id", data.hospitalCountry);
        formData.append("city_id", data.hospitalCity);
        formData.append("province_id", data.province || 1);
        formData.append("facebook_link", data.facebook);
        formData.append("twitter_link", data.twitter);
        formData.append("youtube_link", data.youtube);
        formData.append("linkedin_link", data.linkedin);
        formData.append("instagram_link", data.instagram);
        const res = await hospitalServices.createHospital(formData);
        return res;
      }
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  // Update the hospital
  async handleUpdateHospital(data) {
    try {
      if (
        data.hospitalCountry === "" ||
        data.hospitalCity === "" ||
        data.name === "" ||
        data.adress === "" ||
        data.phone === "" ||
        data.phone2 === "" ||
        data.bio === "" ||
        data.email === "" ||
        // data.site === "" ||
        data.facebook === "" ||
        data.instagram === "" ||
        data.youtube === "" ||
        data.twitter === "" ||
        data.linkedin === ""
      ) {
        return null;
      } else {
        let formData = new FormData();
        // formData.append("logo", data.logo);
        formData.append("name", data.name);
        formData.append("adress", data.adress);
        formData.append("phone", data.phone);
        formData.append("phone2", data.phone2);
        formData.append("bio", data.bio);
        formData.append("email", data.email);
        // formData.append("site", data.site);
        formData.append("country_id", data.hospitalCountry);
        formData.append("city_id", data.hospitalCity);
        formData.append("province_id", data.hospitalProvince || 1);
        formData.append("facebook_link", data.facebook);
        formData.append("twitter_link", data.twitter);
        formData.append("youtube_link", data.youtube);
        formData.append("linkedin_link", data.linkedin);
        formData.append("instagram_link", data.instagram);
        const res = await hospitalServices.updateHospital(formData);
        return res;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  // Update Hospital Photo
  async handleUpdatePhoto(file) {
    try {
      const res = await hospitalServices.updatePhoto(file);
      return res;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  async handleTranslateHospital(data) {
    try {
      let formData = new FormData();
      formData.append("name_ar", data.name_ar);
      formData.append("name_en", data.name_en);
      formData.append("name_tr", data.name_tr);
      formData.append("adress_ar", data.adress_ar);
      formData.append("adress_en", data.adress_en);
      formData.append("adress_tr", data.adress_tr);
      formData.append("bio_ar", data.bio_ar);
      formData.append("bio_en", data.bio_en);
      formData.append("bio_tr", data.bio_tr);

      const res = await hospitalServices.TranslateHospital(formData);
      return res;
      // }
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
  handleChooseCountry(e, setHospitalCountry, setCountryId) {
    setHospitalCountry(e.target.value);
    setCountryId(e.target.value);
  }

  handleChooseCity(e, setHospitalCity) {
    const index = e.target.value;
    setHospitalCity(index);
  }

  async handleSubmitBranch(
    e,
    name,
    address,
    data,
    hospitalCity,
    notifications,
    handleClose,
    t
  ) {
    e.preventDefault();

    const enteredName = name.current.value;
    const enteredAddress = address.current.value;

    try {
      let formData = new FormData();
      formData.append("hospital_id", data.hospital[0].id);
      formData.append("city_id", hospitalCity);
      formData.append("name", enteredName);
      formData.append("street_name", enteredAddress);
      const response = await hospitalServices.createBranch(formData);
      if (response.message === "success") {
        notifications.notifySuccess(t("done_add_newBranch"));
        handleClose();

        return;
      } else {
        notifications.notifyError(t("error_branch"));
      }
      return response;
    } catch (error) {
      return error;
    }
  }
  handleInputChange(e, setImages) {
    setImages(e.target.files[0]);
  }

  async handleGalleryUpload(
    e,
    setLoading,
    images,
    data,
    navigate,
    notifications,
    t
  ) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("img", images);
    formData.append("hospital_id", data.hospital[0].id);

    try {
      const response = await hospitalServices.uploadImageGallery(formData);
      // logic for image added successfully
      if (response.message === "success") {
        setLoading(false);
        navigate("/");
        notifications.notifySuccess(t("done_uploade_photo"));
        window.location.reload(false);
      } else {
        notifications.notifyError(response.error);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  handleHospitalImage(e, setImage) {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      });
    }
  }

  async handleHospitalData(
    e,
    setLoading,
    fromData,
    setErrMsg,
    navigate,
    authCtx
  ) {
    e.preventDefault();
    setLoading(true);
    const resHos = await hospitalController.handleCreateHospital(fromData);
    if (!resHos) {
      setLoading(false);
      setErrMsg(
        "Please make sure to upload hospital profile picture and fill out all the fields above"
      );
      return;
    } else {
      authCtx.createHospital(resHos);
      setLoading(false);
      navigate("/");
      window.location.reload(false);
    }
  }
  openImg(imgSrc, setTempImgSrc, setModel) {
    setTempImgSrc(imgSrc);
    setModel(true);
  }
  async handleHospitalDataTranslate(
    e,
    setLoading,
    fromData,
    name_ar,
    name_en,
    name_tr,
    adress_ar,
    adress_en,
    adress_tr,
    bio_ar,
    bio_en,
    bio_tr,
    t,
    notifications
  ) {
    e.preventDefault();
    setLoading(true);
    if (
      name_ar === "" ||
      name_en === "" ||
      name_tr === "" ||
      adress_ar === "" ||
      adress_en === "" ||
      adress_tr === "" ||
      bio_ar === "" ||
      bio_en === "" ||
      bio_tr === ""
    ) {
      notifications.notifyError(t("epmty_form_translate"));
    } else {
      // eslint-disable-next-line no-unused-vars
      const response = await this.handleTranslateHospital(fromData);
      setLoading(false);
      notifications.notifySuccess(t("done_translate"));
    }
  }

  async handleUpdateHospitalData(
    e,
    setLoading,
    fromData,
    image,
    notifications,
    t,
    navigate,
    setShow
    // back
  ) {
    e.preventDefault();
    setLoading(true);
    const resHos = await hospitalController.handleUpdateHospital(fromData);
    const resPhoto = await hospitalController.handleUpdatePhoto(image.file);

    if (!resHos || !resPhoto) {
      setLoading(false);
      notifications.notifyError(t("epmty_form_uptate"));
      return;
    } else {
      notifications.notifySuccess(t("updata_hospital_notify"));
      // back();
      navigate("/");
      // localStorage.setItem("nav-tab", 1);
      window.location.reload(false);
      setShow(true);
    }
  }
  // handleChooseCountry(e, setHospitalCountry2, setCountryId2) {
  //   setHospitalCountry2(e.target.value);
  //   setCountryId2(e.target.value);
  // }

  // handleChooseCity(e, setHospitalCity2) {
  //   const index = e.target.selectedIndex;
  //   const el = e.target.childNodes[index];
  //   const option = el.getAttribute("id");
  //   setHospitalCity2(option);
  // }

  async handleLocation(postion, notifications, t, show) {
    try {
      let formData = new FormData();
      formData.append("latitude", postion.lat);
      formData.append("longitude", postion.lng);
      const res = await hospitalServices.setLocation(formData);
      if (res.message === "success") {
        if (show) {
          notifications.notifySuccess(t("set_location"));
          return res;
        }
      } else {
        notifications.notifyError(t("error_location"));
      }
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  async removeImage(id, notifications, t) {
    console.log(id);

    try {
      let formData = new FormData();
      formData.append("id", id);
      const res = await hospitalServices.removeImage(id);
      console.log(res);
      if (res.message === "success") {
        notifications.notifySuccess(t("remove_image"));
        window.location.reload(false);
      } else {
        notifications.notifyError(t("error_remove_image"));
      }
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  async handleDoctorLocation() {
    // eslint-disable-next-line no-unused-vars
    const res = await hospitalServices.setDoctorLocation();
  }
}
const hospitalController = new HospitalController();
export default hospitalController;
