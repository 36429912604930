/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./EditHcpAppointmentModal.css";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import appointmentController from "../../controller/AppointmentController";
import notifications from "../../../../Components/toastify/notifications";
function EditHcpAppointmentModal({ handleCloseHcp, showHcp, id }) {
  const { t } = useTranslation();
  const [hcpId, setHcpId] = useState();
  const { hcp, weekCurrent } = useContext(HospitalDataContext);
  const [WorkScheduleHcp, setWorkScheduleHcp] = useState("");
  const [timeId, setimeId] = useState("");
  const [dates, setDate] = useState(null);
  // const [nameDay, setNameDay] = useState("");

  const getDate = (e) => {
    setDate(e.target.value);
    const d = new Date(e.target.value);
    let day1 = d.getDay();
    // const nnnnnn = weekCurrent[day1]?.value;
    // setNameDay(nnnnnn);

    appointmentController.handleGetDayHcp(
      hcpId,
      weekCurrent[day1]?.value,
      setWorkScheduleHcp
    );
  };
  return (
    <>
      {/* here Hcp */}
      <Modal show={showHcp} onHide={handleCloseHcp}>
        <Modal.Header>
          <Modal.Title className="title-model-clinic-appointment">
            {/* {t("hcp_appoinmen_hcp")} */}
            تعديل موعد المرضى
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={(e) =>
              appointmentController.handleHcpEditApppointment(
                e,
                id,
                dates,
                timeId,
                hcpId,
                handleCloseHcp,
                notifications,
                t
              )
            }
          >
            {t("choose_hcp")}
            <select
              className="select-times"
              name="hospital-country"
              id="hospital-address1"
              value={hcpId}
              onChange={(e) => setHcpId(e.target.value)}
            >
              <option value="invalid">-- {t("choose_hcp")} --</option>
              {hcp?.hospital_hcp_departments?.map((depart) => {
                return depart?.hospital_hcp.map((hcp) => {
                  return (
                    <option key={hcp.hcp.id} value={hcp.id}>
                      {hcp.hcp?.title
                        ? hcp.hcp.title
                        : "" +
                          " " +
                          hcp.hcp?.first_name +
                          " " +
                          hcp.hcp?.last_name}
                    </option>
                  );
                });
              })}
            </select>{" "}
            <div className="date-continer">
              <span>{t("chose_date")}</span>
              <input type="date" onChange={getDate} />
            </div>
            <div className="doctor-time">
              {WorkScheduleHcp &&
                WorkScheduleHcp?.map((time) => {
                  return (
                    <article>
                      <input
                        type="radio"
                        id="age1"
                        name="age"
                        value={time.id}
                        onClick={(e) => setimeId(e.target.value)}
                      />
                      <label for="age1">{time.start_time}</label>
                    </article>
                  );
                })}
            </div>
            <Button variant="primary" type="submit" className="save-btn">
              {t("save")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* end  clinic*/}
    </>
  );
}

export default EditHcpAppointmentModal;
