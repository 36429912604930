export const fetchData = async (dataObject) => {
  const promises = Object.values(dataObject).map((func) => func());
  const results = await Promise.all(promises);

  const resultObject = Object.keys(dataObject).reduce((acc, key, index) => {
    acc[key] = results[index];
    return acc;
  }, {});

  return resultObject;
};
