import axiosProvider from "../../../api/base";

class ProfileDoctorServices {
  // Get Doctor Profile Info
  async get_doctor_profile(uniqueID) {
    try {
      const response = await axiosProvider.getRequest(
        `/get-doctor-profile/${uniqueID}`
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
  async get_work_hour_profile_doctor(hospitalDoctorID, day) {
    try {
      const response = await axiosProvider.getRequest(
        `/get-doctor-day-schedule/${hospitalDoctorID}/${day}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }
  async delelet_doctor(doctorID) {
    try {
      const response = await axiosProvider.postRequest("/delete-doctor", {
        doctor_id: doctorID,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async doctor_appointment_form_profile(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/create-doctor-week-schedule",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
}

const profileDoctorServices = new ProfileDoctorServices();

export default profileDoctorServices;
