import NavBar from '../NavComponent';
import './CallUs.css';

const CallUs = () => {
  return (
    <div>
      <NavBar />
      <div className='call-all'>
        <h2 className='headline'>العنوان</h2>
        <p className='parag'>YEŞİLKÖY MH. ATATÜRK CD EGS BUSINESS PARK BLOK, D:NO:12/1, 34149 Bakırköy/İstanbul</p>
        <h2 className='headline'>البريد الإلكتروني</h2>
        <p className='parag'>info@sehapracto.com</p>
        <h2 className='headline'>التليفون</h2>
        <p className='parag'>905551988316</p>
      </div>
    </div>
  );
}

export default CallUs;