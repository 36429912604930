import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../store/data-context";
import { useNavigate } from "react-router-dom";
import "./HospitalOffers.css";

// Plugins

const HospitalOffers = () => {
  const { t } = useTranslation();
  const { Offers, data } = useContext(HospitalDataContext);
  const navigate = useNavigate();

  return (
    <>
      <div className="hospital-data">
        <h2 className="edit-hos-data"> {t("offers")} </h2>
        <button
          className="create-offer"
          onClick={() => navigate("/create-offer")}
        >
          {t("create-offer")}
        </button>
      </div>
      <div>
        <p className="offer_code">
          {" "}
          {t("Offer_code")}:{data.special_code}{" "}
        </p>
      </div>

      {Offers &&
        Offers.offers?.map((offer) => (
          <div className="pwide-cont card-offers" key={offer.id}>
            <div className="growing">
              <div className="phead">
                <p>{t("title")}</p>
              </div>
              <div className="pbody">
                <p>{offer.title}</p>
              </div>
            </div>
            <div className="growing">
              <div className="phead">
                <p>{t("img")}</p>
              </div>
              <div className="pbody">
                <img
                  src={offer.cover_img}
                  alt={offer.title}
                  className="offer-img"
                />
              </div>
            </div>
            {/* <div className="growing">
              <div className="phead">
                <p>{t("details")} </p>
              </div>
              <div className="pbody">
                <p>{offer.details}</p>
              </div>
            </div> */}
            {/* <div className="growing">
              <div className="phead">
                <p>{t("pt_instruction")} </p>
              </div>
              <div className="pbody">
                <p>{offer.pt_instruction}</p>
              </div>
            </div> */}
            {/* <div className="growing">
              <div className="phead">
                <p>{t("price")}</p>
              </div>
              <div className="pbody">
                <p>{offer.price}</p>
              </div>
            </div> */}
            {/* <div className="growing">
              <div className="phead">
                <p>{t("price_before")}</p>
              </div>
              <div className="pbody">
                <p>{offer.price_before}</p>
              </div>
            </div> */}
            {/* <div className="growing">
              <div className="phead">
                <p>{t("discount")}</p>
              </div>
              <div className="pbody">
                <p>{offer.discount}</p>
              </div>
            </div> */}
            <div className="growing">
              <div className="phead">
                <p>{t("start_date")}</p>
              </div>
              <div className="pbody">
                <p>{offer.start_date.slice(0, 11)}</p>
              </div>
            </div>
            <div className="growing">
              <div className="phead">
                <p>{t("end_date")}</p>
              </div>
              <div className="pbody">
                <p>{offer.end_date.slice(0, 11)}</p>
              </div>
            </div>

            <div className="growing">
              <div className="phead">
                <p>{t("details")}</p>
              </div>
              <div className="pbody card-btns">
                <button
                  onClick={() => navigate(`/edit-offer/${offer.id}`)}
                  id={offer.id}
                  className="first-btn"
                >
                  {t("edit")}
                </button>

                <button
                  onClick={() => navigate(`/coupon/${offer.id}`)}
                  id={offer.id}
                  className="first-btn"
                >
                  {t("coupons")}
                </button>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default HospitalOffers;
