/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import doctorController from "../../controller/DoctorController";
import NavComponent from "../../../../Components/Nav/NavComponent";
import Spinner from "../../../../Components/Spinner";
import "./AddingDoctor.css";

const AddingDoctor = () => {
  const { t } = useTranslation();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const handleChange = (e) => {
  //   if (e.target.files.length) {
  //     setImage({
  //       preview: URL.createObjectURL(e.target.files[0]),
  //       raw: e.target.files[0],
  //     });
  //   }
  // };

  // const handleUpload = async (e) => {
  //   alert("api not received and logic not implemented yet");
  //   // e.preventDefault();
  //   // const formData = new FormData();
  //   // formData.append("doctor-image", image.raw);
  //   // formData.append("doctor-name", name);
  //   // formData.append("doctor-phone", phone);
  //   // formData.append("doctor-email", email);
  //   // formData.append("doctor-specialization", specialization);
  //   // formData.append("doctor-password", password);
  //   // console.log(formData);
  //   // await fetch("YOUR_URL", {
  //   //   method: "POST",
  //   //   headers: {
  //   //     "Content-Type": "multipart/form-data",
  //   //   },
  //   //   body: formData,
  //   // });
  //   // // this is a then-able object I should add .then here

  //   // setName("");
  //   // setPhone("");
  //   // setEmail("");
  //   // setSpecialization("");
  //   // setPassword("");
  // };

  return (
    <div className="adding-all">
      <NavComponent />
      <div className="doc-all2">
        <p className="doc-name">{t("adding_doctor")}</p>
        <img className="doc-pic" alt="" />
      </div>
      <div className="conto6">
        <h4>{t("please_enter")}</h4>
        <form
          id="contact6"
          action="/sjkfdl"
          method="post"
          onSubmit={(e) => doctorController.handleUpload(e)}
        >
          <div className="flexing">
            <div className="imgflex">
              <fieldset className="inno imgall">
                <label htmlFor="upload-admin" className="imgblock">
                  {image.preview ? (
                    <img
                      src={image.preview}
                      alt="dummy"
                      width="300"
                      height="300"
                    />
                  ) : (
                    <>
                      <span className="fa-stack fa-4x mt-1 mb-2">
                        <i className="fa-regular fa-image"></i>
                      </span>
                      <h5>{t("choose_photo")}</h5>
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-admin"
                  style={{ display: "none" }}
                  onChange={(e) => doctorController.handleChange(e, setImage)}
                />
              </fieldset>
            </div>

            <div className=".form-all">
              <fieldset className="inno">
                <label className="label4input" htmlFor="admin-name">
                  {t("name_label")}
                </label>
                <input
                  placeholder={t("name_label")}
                  type="text"
                  tabIndex="1"
                  id="admin-name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  autoFocus
                />
              </fieldset>
              <fieldset className="inno">
                <label className="label4input" htmlFor="admin-number">
                  {t("phone_number_label")}
                </label>
                <input
                  placeholder={t("phone_number_label")}
                  type="tel"
                  tabIndex="2"
                  id="admin-number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </fieldset>
              <fieldset className="inno">
                <label className="label4input" htmlFor="admin-email">
                  {t("email_label")}
                </label>
                <input
                  placeholder={t("email_label")}
                  type="email"
                  tabIndex="3"
                  id="admin-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </fieldset>
              <fieldset className="inno">
                <label className="label4input" htmlFor="admin-specialization">
                  {t("specialization")}
                </label>
                <input
                  placeholder={t("specialization")}
                  type="text"
                  tabIndex="4"
                  id="admin-specialization"
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                  required
                />
              </fieldset>
              <fieldset className="inno">
                <label className="label4input" htmlFor="admin-newpass">
                  {t("password_label")}
                </label>
                <input
                  placeholder={t("password_label")}
                  type="password"
                  tabIndex="5"
                  id="admin-newpass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </fieldset>
            </div>
          </div>
          <fieldset className="doc-btn">
            <button
              name="submit-admin-account"
              type="submit"
              id="contact-submit1"
              data-submit="...Sending"
            >
              {isLoading ? <Spinner /> : t("adding_doctor")}
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default AddingDoctor;
