import profileDoctorServices from "../services/ProfileDoctorServices";

class DoctorController {
  async handleDeleteDoctor(e, navigate, doctorID, notifications, t) {
    e.preventDefault();
    const response = await profileDoctorServices.delelet_doctor(doctorID);
    if (response.message === "success") {
      notifications.notifySuccess(t("doctor_deleted_successfully"));
      navigate("/");
      window.location.reload(false);
    } else {
      notifications.notifyError(t("can_not_delete"));
      // Cannot delete, Please contact technical support
    }
  }
  async handleDoctorFormProfileAppointment(
    id,
    startTime,
    endTime,
    workDays,
    duration
  ) {
    try {
      let formData = new FormData();
      formData.append("hospital_doctor_id", id);
      formData.append("start", startTime);
      formData.append("end", endTime);
      formData.append("days", JSON.stringify(workDays));
      formData.append("duration", duration);

      const res = await profileDoctorServices.doctor_appointment_form_profile(
        formData
      );

      return res;
    } catch (error) {
      return error;
    }
  }
  async handleDoctorApppointment(
    e,
    doctorId,
    startTime,
    endTime,
    workDays,
    durationTime,
    notifications,
    setStartTime,
    setEndTime,
    setWorkDays,
    setDurationTime,
    handleCloseDr,
    t
  ) {
    e.preventDefault();
    const res = await this.handleDoctorFormProfileAppointment(
      doctorId,
      startTime,
      endTime,
      workDays,
      durationTime
    );
    if (workDays.length === 0) {
      notifications.notifyError(t("work_day_empty"));
    } else {
      if (res.message === "success") {
        notifications.notifySuccess(t("done_doctor_appointment"));
        setStartTime("");
        setEndTime("");
        setWorkDays([]);
        setDurationTime();
        handleCloseDr();
      } else {
        if (doctorId === null) {
          notifications.notifyError(t("error_id_doctor"));
        } else if (res.error?.day) {
          notifications.notifyError(t("error_work_day"));
        } else if (res.error?.start) {
          notifications.notifyError(t("error_start_time"));
        } else if (res.error?.end) {
          notifications.notifyError(t("error_end_time"));
        } else {
          notifications.notifyError(t("error_duration"));
        }
      }
    }
  }
  checkTime(end, startTime, setErrorTime) {
    const s = startTime.split(":");
    const e = end.split(":");
    const start_time = Number(s[0]);
    const end_time = Number(e[0]);
    if (end_time < start_time || end_time === start_time) {
      setErrorTime("You Must choose a time bigger than the start Time");
    } else {
      setErrorTime("");
    }
  }
  handleDay(e, setDay) {
    setDay(e.target.value);
  }
  handleDurationTime(e, setDurationTime) {
    setDurationTime(e.target.value);
  }
  handleStartTime(e, setStartTime) {
    setStartTime(e.target.value);
  }
  handleEndTime(e, startTime, setErrorTime, setEndTime) {
    setEndTime(e.target.value);
    const end = e.target.value;
    this.checkTime(end, startTime, setErrorTime);
  }
  handleKeywords(e, workDays, setWorkDays) {
    let updateDays = [...workDays];
    if (e.target.checked) {
      updateDays = [...workDays, e.target.value];
    } else {
      updateDays.splice(workDays.indexOf(e.target.value), 1);
    }
    setWorkDays(updateDays);
  }
}

const doctorController = new DoctorController();

export default doctorController;
