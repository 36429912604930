/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./DoctorModal.css";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import appointmentController from "../../controller/AppointmentController";
import notifications from "../../../../Components/toastify/notifications";
function DoctorModal({ handleCloseDr, showDr }) {
  const { t } = useTranslation();
  const [durationTime, setDurationTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [errortime, setErrorTime] = useState("");

  const [doctorId, setDoctorId] = useState();
  const [day, setDay] = useState("");

  const { week, times, duration, Doctors } = useContext(HospitalDataContext);

  // const handleDoctorApppointment = async (e) => {
  //   e.preventDefault();
  //   const res = await appointmentController.handleDoctorAppointment(
  //     doctorId,
  //     startTime,
  //     endTime,
  //     durationTime,
  //     day
  //   );
  //   if (res.message === "success") {
  //     notifications.notifySuccess(t("done_doctor_appointment"));
  //     setStartTime("");
  //     setEndTime("");
  //     setDay();
  //     setDurationTime();
  //     setDoctorId();
  //     handleCloseDr();
  //   } else {
  //     if (doctorId === null) {
  //       notifications.notifyError(t("error_id_doctor"));
  //     } else if (res.error?.day) {
  //       notifications.notifyError(t("error_work_day"));
  //     } else if (res.error?.start) {
  //       notifications.notifyError(t("error_start_time"));
  //     } else if (res.error?.end) {
  //       notifications.notifyError(t("error_end_time"));
  //     } else {
  //       notifications.notifyError(t("error_duration"));
  //     }
  //   }
  // };
  // const handleDay = (e) => {
  //   setDay(e.target.value);
  // };
  // const handleDurationTime = (e) => {
  //   setDurationTime(e.target.value);
  // };
  // const handleStartTime = (e) => {
  //   setStartTime(e.target.value);
  // };
  // const handleEndTime = (e) => {
  //   setEndTime(e.target.value);
  // };
  return (
    <>
      {/* here doctor */}

      <Modal show={showDr} onHide={handleCloseDr}>
        <Modal.Header>
          <Modal.Title className="title-model-clinic-appointment">
            {t("doctor_working_hours")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={(e) =>
              appointmentController.handleDoctorApppointment(
                e,
                doctorId,
                startTime,
                endTime,
                durationTime,
                day,
                notifications,
                t,
                setStartTime,
                setEndTime,
                setDay,
                setDurationTime,
                setDoctorId,
                handleCloseDr
              )
            }
          >
            {t("choose_doctor")}
            <select
              className="select-times"
              name="hospital-country"
              id="hospital-address1"
              value={doctorId}
              onChange={(e) => setDoctorId(e.target.value)}
            >
              <option value="invalid">-- {t("choose_doctor")} --</option>
              {Doctors?.hospitalDepartments?.map((depart) => {
                return depart?.hospital_doctor.map((doctor) => {
                  return (
                    <option key={doctor.doctor.id} value={doctor.id}>
                      {doctor.doctor.title +
                        " " +
                        doctor.doctor.first_name +
                        " " +
                        doctor.doctor.last_name}
                    </option>
                  );
                });
              })}
            </select>{" "}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {t("doctor_working_days")}
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) => appointmentController.handleDay(e, setDay)}
              >
                <option value=""> {t("day")} </option>

                {week.map((day) => (
                  <option key={day.id} value={day.value}>
                    {day.day}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("work_time")} </Form.Label>
              <br />
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) =>
                  appointmentController.handleStartTime(e, setStartTime)
                }
              >
                <option value=""> {t("from")} </option>
                {times.map((time) => (
                  <option key={time.id} value={time.hour}>
                    {time.hour}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <>
                <select
                  className="select-times"
                  name=""
                  id=""
                  onChange={(e) =>
                    appointmentController.handleEndTime(
                      e,
                      startTime,
                      setErrorTime,
                      setEndTime
                    )
                  }
                >
                  <option value=""> {t("to")} </option>

                  {times.map((time) => (
                    <option key={time.id} value={time.hour}>
                      {time.hour}
                    </option>
                  ))}
                </select>
                <span>{errortime}</span>
              </>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("appointment_duration")}</Form.Label>
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) =>
                  appointmentController.handleDurationTime(e, setDurationTime)
                }
              >
                <option value=""> {t("duration")} </option>

                {duration.map((time) => (
                  <option key={time.id} value={time.time}>
                    {time.time}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Button variant="primary" type="submit" className="save-btn">
              {t("save")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* end  doctor*/}
    </>
  );
}

export default DoctorModal;
