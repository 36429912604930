import sectionsServices from "../services/SectionsServices";

class SectionsController {
  handleSection(e, setSelectedOption) {
    setSelectedOption(e.target.value);
  }
  async handleAddSection(e, setIsLoading, selectedOption, notifications, t) {
    e.preventDefault();
    setIsLoading(true);
    const response = await sectionsServices.Add_department(selectedOption);
    if (response.message === "success") {
      notifications.notifySuccess(t("specialty_added_successfully"));
      setIsLoading(false);
    } else if (
      response.message === "fail" &&
      response.error === "department exists"
    ) {
      notifications.notifyError(t("already_depart_exit"));
      setIsLoading(false);
    } else if (selectedOption === undefined) {
      notifications.notifyError(t("error_id__depart_doctor"));
      setIsLoading(false);
    } else {
      notifications.notifyError(response.error);
      setIsLoading(false);
    }
  }
  async toggleDepartmenntDoctorsStatus(e, id) {
    e.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const res = await sectionsServices.toggle_doctor_depertment(id);
    window.location.reload(false);
  }
}

const sectionsController = new SectionsController();

export default sectionsController;
