import axiosProvider from "../../../api/base";

class HcpServices {
  // get-all-hcp-specialties
  async get_all_hcp_specialties() {
    const response = await axiosProvider.getRequest("/get-all-hcp-specialties");
    return response;
  }

  // Add department to hcp
  async Add_hcp_department(id) {
    try {
      const response = await axiosProvider.postRequest("/add-hcp-department", {
        specialty_id: id,
      });
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  // Add Hcp
  async add_hcp(data) {
    try {
      const response = await axiosProvider.postRequest("/add-hcp", {
        phone: data.phone,
        hospital_hcp_department_id: data.hospital_hcp_department_id,
      });
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async add_hcp_by_email(data) {
    try {
      const response = await axiosProvider.postRequest("/add-hcp-email", {
        email: data.email,
        hospital_hcp_department_id: data.hospital_hcp_department_id,
      });
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // Get All Hcp Staff
  async get_hcp_staff() {
    try {
      const response = await axiosProvider.getRequest("/get-all-hcp-staff");
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
  async get_all_approve_hcp_appointment(id) {
    try {
      const response = await axiosProvider.postRequest(
        "/approve-hcp-appointment",
        {
          hospital_appointment_id: id,
        }
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  async get_all_finish_hcp_appointment(id) {
    try {
      const response = await axiosProvider.postRequest(
        "/finish-hcp-appointment",
        {
          hospital_appointment_id: id,
        }
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  async get_all_reject_hcp_appointment(id) {
    try {
      const response = await axiosProvider.postRequest(
        "/reject-hcp-appointment",
        {
          hospital_appointment_id: id,
        }
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
  async toggle_hcp_depertment(id) {
    try {
      const response = await axiosProvider.postRequest(
        "/toggle-hcp-department",
        {
          department_id: id,
        }
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
}

const hcpServices = new HcpServices();

export default hcpServices;
