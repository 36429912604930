import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
// import sectionsServices from "../services/SectionsServices";
import notifications from "../../../Components/toastify/notifications";
import { HospitalDataContext } from "../../../store/data-context";
import sectionsController from "../controller/SectionsController";
import Spinner from "../../../Components/Spinner";
import loader from "../../../Components/loader.gif";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import "./MedicalSections.css";

const MedicalDepartments = () => {
  const { t } = useTranslation();
  const { medicalSpecialties, Doctors } = useContext(HospitalDataContext);
  const [selectedOption, setSelectedOption] = useState();
  const [isLoading, setIsLoading] = useState(false);

  if (medicalSpecialties.length === 0) {
    return <img className="loader" src={loader} alt="" />;
  } else {
    return (
      <div className="schedule-cont backgound">
        <h3 className="doctors-h3">{t("medical_specialties")}</h3>
        <div className="tab-switch">
          <div className="mytabs">
            <input
              type="radio"
              id="tabfree"
              name="mytabs"
              defaultChecked="checked"
            />
            <label className="tab-label" htmlFor="tabfree">
              {t("add_specialty")}
            </label>
            <div className="tab">
              <form
                // className="add-remove-all"
                onSubmit={(e) =>
                  sectionsController.handleAddSection(
                    e,
                    setIsLoading,
                    selectedOption,
                    notifications,
                    t
                  )
                }
              >
                <div className="s-all1">
                  <div className="">
                    <span className="label4selections">{t("departments")}</span>
                  </div>
                  <br />

                  <div className="s-tag1">
                    <span
                      htmlFor="hospital-address1"
                      className="selection-label"
                    >
                      {t("medical_specialties")}
                    </span>
                    {document.body.dir === "rtl" ? (
                      <select
                        name="hospital-country"
                        id="hospital-address1"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <option value="invalid">
                          {t("choose_department_from_here")}
                        </option>
                        {medicalSpecialties?.medical_specialties?.map(
                          (i, n) => (
                            <option key={i.id} value={i.id}>
                              {i.name_ar}
                            </option>
                          )
                        )}
                      </select>
                    ) : (
                      <select
                        name="hospital-country"
                        id="hospital-address1"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <option value="invalid">
                          {t("choose_department_from_here")}
                        </option>
                        {medicalSpecialties?.medical_specialties.map((i, n) => (
                          <option key={i.id} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <button
                    name="submit-admin-account"
                    type="submit"
                    id="contact-submit1"
                    data-submit="...Sending"
                  >
                    {isLoading ? <Spinner /> : t("add")}
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>

            <input type="radio" id="tabgold" name="mytabs" />
            <label className="tab-label" htmlFor="tabgold">
              {t("add_remove_specialty")}
            </label>
            <div className="tab test">
              <div className="department">
                {Doctors?.hospitalDepartments?.map((dep) => {
                  return (
                    <section className="department-section" key={dep.id}>
                      <article>
                        <Avatar
                          className="avatar"
                          alt="Remy Sharp"
                          src={dep.medical_specialty.img}
                        />
                        <Typography className="dep-name">
                          {document.body.dir === "rtl"
                            ? dep.medical_specialty.name_ar
                            : dep.medical_specialty.name}
                        </Typography>
                      </article>
                      <button
                        onClick={(e) =>
                          sectionsController.toggleDepartmenntDoctorsStatus(
                            e,
                            dep.id
                          )
                        }
                        className={dep.is_hidden ? "show-hcp" : "hide"}
                      >
                        {dep.is_hidden ? t("show") : t("hidden")}
                      </button>
                    </section>
                  );
                })}
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>

      // <form
      //   className="add-remove-all"
      //   onSubmit={(e) =>
      //     sectionsController.handleAddSection(
      //       e,
      //       setIsLoading,
      //       selectedOption,
      //       notifications,
      //       t
      //     )
      //   }
      // >
      //   <div className="tab-switch">
      //     <h3 className="doctors-h3">{t("departments")}</h3>
      //     <div className="mytabs">
      //       <input
      //         type="radio"
      //         id="tabfree"
      //         name="mytabs"
      //         defaultChecked="checked"
      //       />
      //       <label htmlFor="tabfree" className="tab-label">
      //         {t("add_department")}
      //       </label>

      //       <input type="radio" id="tabgold" name="mytabs" />
      //       <label htmlFor="tabgold" className="tab-label">
      //         {t("edit_remove_dep")}
      //       </label>
      //     </div>

      //     {/* <label htmlFor="doc-spec">إضافة تخصص طبي</label>
      //           <select placeholder='اختر التخصص' name="add-speciality" id="doc-spec">
      //               <option value="spec1"></option>
      //           </select>
      //           <label htmlFor="doc-spec2">حذف تخصص طبي متاح</label>
      //           <select placeholder='اختر التخصص' name="add-speciality" id="doc-spec2"></select> */}

      //     <fieldset className="s-all1">
      //       <div className="s-label">
      //         <label className="label4selections">
      //           {t("medical_specialties")}
      //         </label>
      //       </div>
      //       <br />

      //       <div className="s-tag1">
      //         <label htmlFor="hospital-address1" className="selection-label">
      //           {t("add_medical_specialty")}
      //         </label>
      //         {document.body.dir === "rtl" ? (
      //           <select
      //             name="hospital-country"
      //             id="hospital-address1"
      //             value={selectedOption}
      //             onChange={(e) =>
      //               sectionsController.handleSection(e, setSelectedOption)
      //             }
      //           >
      //             <option value="invalid">
      //               {t("choose_department_from_here")}
      //             </option>
      //             {medicalSpecialties?.medical_specialties?.map((i, n) => (
      //               <option key={i.id} value={i.id}>
      //                 {i.name_ar}
      //               </option>
      //             ))}
      //           </select>
      //         ) : (
      //           <select
      //             name="hospital-country"
      //             id="hospital-address1"
      //             value={selectedOption}
      //             onChange={(e) =>
      //               sectionsController.handleSection(e, setSelectedOption)
      //             }
      //           >
      //             <option value="invalid">
      //               {t("choose_department_from_here")}
      //             </option>
      //             {medicalSpecialties?.medical_specialties.map((i, n) => (
      //               <option key={i.id} value={i.id}>
      //                 {i.name}
      //               </option>
      //             ))}
      //           </select>
      //         )}
      //       </div>
      //       <ToastContainer />
      //       <button
      //         name="submit-admin-account"
      //         type="submit"
      //         id="contact-submit1"
      //         data-submit="...Sending"
      //       >
      //         {isLoading ? <Spinner /> : t("add")}
      //       </button>
      //     </fieldset>
      //   </div>
      // </form>
    );
  }
};

export default MedicalDepartments;
