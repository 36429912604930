import doctorServices from "../services/DoctorServices";

class DoctorController {
  onSelectChange(e, setSelectedOption) {
    setSelectedOption(
      e.target.options[e.target.selectedIndex].getAttribute("id")
    );
  }
  async handleAddDoctor(
    e,
    setIsLoading,
    phone,
    selectedOption,
    notifications,
    t
  ) {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      phone,
      hospital_department_id: selectedOption,
    };

    const res = await doctorServices.Add_doctor(data);

    if (res.message === "success") {
      setIsLoading(false);
      notifications.notifySuccess(t("done_add_doctor"));
    } else if (res.message === "fail" && res.error === "doctor not found") {
      notifications.notifyError(t("not_exit_doctor"));
      setIsLoading(false);
    } else if (
      res.message === "fail" &&
      res.error === "doctor already registered in hospital"
    ) {
      notifications.notifyError(t("exit_doctor"));
      setIsLoading(false);
    } else if (
      res.name === "AxiosError" ||
      res.message === "Request failed with status code 500"
    ) {
      notifications.notifyError(t("error_add_doctor"));
      setIsLoading(false);
    } else {
      notifications.notifyError(res.error);
      setIsLoading(false);
    }
  }
  async handleAddDoctorByEmail(
    e,
    setIsLoading,
    email,
    setEmail,
    setSelectedOption,
    selectedOption,
    notifications,
    t
  ) {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      email,
      hospital_department_id: selectedOption,
    };

    const res = await doctorServices.Add_doctor_by_email(data);

    if (res.message === "success") {
      setIsLoading(false);
      notifications.notifySuccess(t("done_add_doctor"));
      setEmail("");
      setSelectedOption();
      window.location.reload(true);
    } else if (res.message === "fail" && res.error === "doctor not found") {
      notifications.notifyError(t("not_exit_doctor"));
      setIsLoading(false);
    } else if (
      res.message === "fail" &&
      res.error === "doctor already registered in hospital"
    ) {
      notifications.notifyError(t("exit_doctor"));
      setIsLoading(false);
    } else if (
      res.name === "AxiosError" ||
      res.message === "Request failed with status code 500"
    ) {
      notifications.notifyError(t("error_add_doctor"));
      setIsLoading(false);
    } else {
      notifications.notifyError(res.error);
      setIsLoading(false);
    }
  }
  handleChange = (e, setImage) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  async handleUpload(e) {
    alert("api not received and logic not implemented yet");
    // e.preventDefault();
    // const formData = new FormData();
    // formData.append("doctor-image", image.raw);
    // formData.append("doctor-name", name);
    // formData.append("doctor-phone", phone);
    // formData.append("doctor-email", email);
    // formData.append("doctor-specialization", specialization);
    // formData.append("doctor-password", password);
    // console.log(formData);
    // await fetch("YOUR_URL", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    //   body: formData,
    // });
    // // this is a then-able object I should add .then here

    // setName("");
    // setPhone("");
    // setEmail("");
    // setSpecialization("");
    // setPassword("");
  }
}

const doctorController = new DoctorController();

export default doctorController;
