import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
// import axiosProvider from "../../../../api/base";
import hospitalController from "../../controller/HospitalController";
import NavBar from "../../../../Components/Nav/NavComponent";
import notifications from "../../../../Components/toastify/notifications";
import Spinner from "../../../../Components/Spinner";
import "./CreateGallery.css";

const CreateGallery = () => {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data } = useContext(HospitalDataContext);
  const navigate = useNavigate();

  // const handleInputChange = (e) => {
  //   setImages(e.target.files[0]);
  // };

  // const handleGalleryUpload = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const formData = new FormData();
  //   formData.append("img", images);
  //   formData.append("hospital_id", data.hospital[0].id);

  //   try {
  //     const response = await axiosProvider.postRequest(
  //       "/add-hospital-img",
  //       formData
  //     );
  //     // logic for image added successfully
  //     if (response.message === "success") {
  //       setLoading(false);
  //       navigate("/");
  //       notifications.notifySuccess(t("done_uploade_photo"));
  //       window.location.reload(false);
  //     } else {
  //       notifications.notifyError(response.error);
  //     }
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div>
      <NavBar />
      <div className="div-cont2">
        <form
          onSubmit={(e) =>
            hospitalController.handleGalleryUpload(
              e,
              setLoading,
              images,
              data,
              navigate,
              notifications,
              t
            )
          }
          className="gallery-cont"
        >
          <div className="h3-cont">
            <h3>{t("hospital_gallery_photos")}</h3>
          </div>
          <div className="file-cont">
            <input
              type="file"
              name="img-collection"
              accept="image/jpg, image/png, image/jpeg"
              onChange={(e) =>
                hospitalController.handleInputChange(e, setImages)
              }
              required
            />
          </div>
          <br />
          <div className="submit-cont">
            <button type="submit">{loading ? <Spinner /> : t("upload")}</button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

// }

export default CreateGallery;
