import hospitalOffersServices from "../services/HospitalOffersServices";
class HospitalOffersController {
  // handleCreateHospital
  async handleHospitalOffers(e, data, notifications, t) {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("title", data.title);
      formData.append("title_en", data.titleEnglish);
      formData.append("title_ar", data.titleArabic);
      formData.append("title_tr", data.titleTurkish);
      formData.append("start_date", data.startDate);
      formData.append("end_date", data.endDate);
      formData.append("details", data.details);
      formData.append("details_en", data.detailsEnglish);
      formData.append("details_ar", data.detailsArabic);
      formData.append("details_tr", data.detailsTurkish);
      formData.append("instructions", data.instruction);
      formData.append("instructions_en", data.instructionEnglish);
      formData.append("instructions_ar", data.instructionArabic);
      formData.append("instructions_tr", data.instructionTurkish);
      // formData.append("country_id", data.offerCountryId);
      // formData.append("city_id", data.offerCityId);
      formData.append("cover_img", data.image.file);
      const res = await hospitalOffersServices.hospitalOffers(formData);
      if (res.message === "success") {
        notifications.notifySuccess(t("Create_offer"));
      } else {
        notifications.notifyError(t("error_branch"));
      }
      return res;
    } catch (error) {
      return error;
    }
  }
  async toggleCouponStatus(e, id, notifications, t) {
    e.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const res = await hospitalOffersServices.toggelCouponActive(id);

    if (res.message === "success") {
      notifications.notifySuccess(t("done_change-status_coupon"));
      window.location.reload(false);
    } else {
      notifications.notifyError(t("error_branch"));
    }
  }

  async handleCoupon(e, data, notifications, t) {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("offering_id", data.offerId);
      formData.append("discount_type_id", data.typesDiscounId);
      formData.append("title", data.title);
      if (data.price === null) {
        formData.append("price", 0);
      } else {
        formData.append("price", data.price);
      }
      formData.append("how_to_use", data.howToUse);
      formData.append("saved_amount", data.savedAmount);
      formData.append("number_uses", data.numberDuplicated);
      formData.append("use_frequency", data.typesDuplicatedId);

      if (data.discountPercentage === null) {
        formData.append("discount_percentage", 0);
      } else {
        formData.append("discount_percentage", data.discountPercentage);
      }

      const res = await hospitalOffersServices.createCoupon(formData);
      if (res.message === "success") {
        notifications.notifySuccess(t("done_create_coupon"));
      } else {
        notifications.notifyError(t("error_branch"));
      }
      return res;
    } catch (error) {
      return error;
    }
  }

  async handleEditCoupon(e, data, notifications, t, navigate) {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("id", data.offerId);
      formData.append("discount_type_id", data.typesDiscounId);
      formData.append("title", data.title);
      formData.append("price", data.price);
      formData.append("how_to_use", data.howToUse);
      formData.append("saved_amount", data.savedAmount);
      formData.append("number_uses", data.numberDuplicated);
      formData.append("use_frequency", data.typesDuplicatedId);
      formData.append("discount_percentage", data.discountPercentage);

      const res = await hospitalOffersServices.updateCoupon(formData);
      if (res.message === "success") {
        notifications.notifySuccess(t("done_edit_coupon"));
        // navigate(`/coupon/34`);
        // window.location.reload(false);
      } else {
        notifications.notifyError(t("error_branch"));
      }
      return res;
    } catch (error) {
      return error;
    }
  }

  async handleEditHospitalOffers(e, data, notifications, t) {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("id", data.id);
      formData.append("title", data.tts);
      formData.append("title_en", data.titleEnglish);
      formData.append("title_ar", data.titleArabic);
      formData.append("title_tr", data.titleTurkish);
      formData.append("start_date", data.startDate);
      formData.append("end_date", data.endDate);
      formData.append("details", data.details);
      formData.append("details_en", data.detailsEnglish);
      formData.append("details_ar", data.detailsArabic);
      formData.append("details_tr", data.detailsTurkish);
      formData.append("instructions", data.instruction);
      formData.append("instructions_en", data.instructionEnglish);
      formData.append("instructions_ar", data.instructionArabic);
      formData.append("instructions_tr", data.instructionTurkish);

      const res = await hospitalOffersServices.editHospitalOffers(formData);
      if (res.message === "success") {
        notifications.notifySuccess(t("edit_offer"));
      } else {
        notifications.notifyError(t("error_branch"));
      }
      return res;
      // }
    } catch (error) {
      return error;
    }
  }
  handleHospitalImageoffer(e, setImage) {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      });
    }
  }

  handleChooseCountry(e, setOfferCountry, setCountryId) {
    setOfferCountry(e.target.value);
    setCountryId(e.target.value);
  }

  handleChooseCity(e, setOfferCity) {
    const index = e.target.value;
    setOfferCity(index);
  }

  handleChooseMedicalSpecialty(e, setMedicalSpecialty) {
    setMedicalSpecialty(e.target.value);
  }
  handleChooseOffersCategory(e, setOfferCategory) {
    setOfferCategory(e.target.value);
  }
}
const hospitaloffersController = new HospitalOffersController();
export default hospitaloffersController;
