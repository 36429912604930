/* eslint-disable no-unused-vars */
import { useState, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";

import TabsHcp from "../TabsHcp/TabsHcp";
import ClinicModal from "../ClinicModal/ClinicModal";
import DoctorModal from "../DoctorModal/DoctorModal";
import HcpModal from "../HcpModal/HcpModal";
import loader from "../../../../Components/loader.gif";
import TabsDoctor from "../Tabs/TabsDoctor";

import "./ScheduleAppointment.css";

const ScheduleAppointment = () => {
  const { appointments, data } = useContext(HospitalDataContext);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showDr, setShowDr] = useState(false);
  const [showHcp, setShowHcp] = useState(false);
  const handleClose = () => setShow(!show);
  const handleShow = () => setShow(true);
  const handleCloseDr = () => setShowDr(false);
  const handleShowDr = () => setShowDr(true);
  const handleCloseHcp = () => setShowHcp(false);
  const handleShowHcp = () => setShowHcp(true);
  // console.log({ appointments });
  if (appointments.length === 0) {
    return <img className="loader" src={loader} alt="" />;
  } else {
    return (
      <div className="schedule-cont">
        <div className="tab-switch">
          <div className="mytabs">
            <input
              type="radio"
              id="tabfree"
              name="mytabs"
              defaultChecked="checked"
            />
            <label className="tab-label" htmlFor="tabfree">
              {t("schedule_doctor_appointments")}
            </label>
            <div className="tab">
              {/* <h2 className="choose-doc">{t("choose_doctor")}</h2> */}
              <div className="s-tag1">
                {/* <select
                  name="hospital-country"
                  id="hospital-address1"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="invalid">--اختر الطبيب من القائمة--</option>
                  <option></option>
                </select> */}
                <button className="clinic-appointment-btn" onClick={handleShow}>
                  {t("clinic_appointments")}
                </button>
                <button
                  className="doctor-appintment-btn"
                  onClick={handleShowDr}
                >
                  {t("doctor_appointments")}
                </button>
              </div>
              <ClinicModal handleClose={handleClose} show={show} />
              <DoctorModal handleCloseDr={handleCloseDr} showDr={showDr} />
              <TabsDoctor appointments={appointments} />
            </div>

            <input type="radio" id="tabgold" name="mytabs" />
            <label className="tab-label" htmlFor="tabgold">
              {t("schedule_hcp_appointments")}
            </label>
            <div className="tab">
              <div className="s-tag1">
                {/* <select
                  name="hospital-country"
                  id="hospital-address1"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="invalid">--اختر الطبيب من القائمة--</option>
                  <option></option>
                </select> */}

                <button
                  className="doctor-appintment-btn"
                  onClick={handleShowHcp}
                >
                  {t("hcp_appointments")}
                </button>
              </div>
              <HcpModal handleCloseHcp={handleCloseHcp} showHcp={showHcp} />
              <TabsHcp appointments={appointments} />
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  }
};

export default ScheduleAppointment;
