import axios from "axios";

// export default axios.create({
//     baseURL: 'https://app.sehapracto.net/hospitaladminapi'
// })

const baseURL = "https://app.sehapracto.net/hospitaladminapi";
const token = localStorage.getItem("token");

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
class AxiosProvider {
  //? add url to work with localy or for testing

  // baseURL = "https://app.sehapracto.net/hospitaladminapi";

  async getRequest(path) {
    try {
      if (localStorage.getItem("token")) {
        const response = await axios({
          url: `${path}`,
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        return response["data"];
      }
    } catch (error) {
      return error;
    }
  }

  // async postRequest(path, data, token = "") {
  async postRequest(path, data) {
    try {
      const response = await axios({
        // url: `${this.baseUrl}${path}`,
        url: `${path}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      });
      return response["data"];
    } catch (error) {
      return error;
    }
  }
}

const axiosProvider = new AxiosProvider();

export default axiosProvider;
