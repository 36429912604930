import axiosProvider from "../../../api/base";

class HospitalServices {
  // Create a Admin Hospital
  async createHospital(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/create-hospital",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // Update a Hospital
  async updateHospital(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/update-hospital-profile",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // Update Hospital Picture
  async updatePhoto(file) {
    try {
      const response = await axiosProvider.postRequest(
        "/update-hospital-icon",
        {
          img: file,
        }
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // Get Admin Hospital
  async get_hospital() {
    try {
      const response = await axiosProvider.getRequest("/hospital");
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // Get selec drop down options for the countries and cities from API
  async get_countries() {
    try {
      const response = await axiosProvider.getRequest("/get-all-countries");
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // getting the city for the specific selected country
  async get_cities_for_country(countryId) {
    try {
      const response = await axiosProvider.getRequest(
        `/get-country-cities/${countryId}`
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // Add image to hospital picture gallery
  async Add_img_to_hospital(img) {
    const response = await axiosProvider.postRequest("/add-hospital-img", {
      img,
    });
    return response;
  }
  async createBranch(data) {
    try {
      const response = await axiosProvider.postRequest("/create-branch", data);
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  // not used yet
  async get_branches(branchId) {
    try {
      const response = await axiosProvider.getRequest(
        `/get-branch/${branchId}`
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  //Translate Hospital
  async TranslateHospital(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/update-hospital-profile-translation",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  async uploadImageGallery(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/add-hospital-img",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async removeImage(data) {
    try {
      const response = await axiosProvider.postRequest("/remove-hospital-img", {
        id: data,
      });
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  async setLocation(data) {
    try {
      const response = await axiosProvider.postRequest("/set-location", data);
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async setDoctorLocation() {
    try {
      const response = await axiosProvider.getRequest("/set-dr-location");
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
}

const hospitalServices = new HospitalServices();

export default hospitalServices;
