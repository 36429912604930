import axiosProvider from "../../../api/base";

class AuthServices {
  async login(data) {
    const response = await axiosProvider.postRequest("/login", data);
    return response;
  }

  async register(data) {
    const response = await axiosProvider.postRequest("/register", data);
    return response;
  }
  async changePassword(oldPassword, newPassword) {
    const response = await axiosProvider.postRequest("/change-password", {
      password: oldPassword,
      new_password: newPassword,
    });
    return response;
  }
  async recoverPassword(recoveryEmail) {
    const response = await axiosProvider.postRequest("/forgot-password", {
      email: recoveryEmail,
    });
    return response;
  }
}

const authServices = new AuthServices();

export default authServices;
