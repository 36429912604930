import adminServices from "../services/AdminServices";

class AdminController {
  handleChange(e, setImage) {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  }

  async handleEditAdminPhone(
    e,
    number,
    notifications,
    handleEditPhoneClose,
    t
  ) {
    e.preventDefault();

    const res = await adminServices.editAdminPhone(number);
    if (res.message === "success") {
      notifications.notifySuccess(t("done_edit_phone"));
      handleEditPhoneClose();
      window.location.reload(false);
    } else {
      notifications.notifyError(t("error_code"));
    }
  }

  handlePhoneVerfication(
    e,
    notifications,
    handleClose,
    t,
    handleOpenCode,
    code
  ) {
    e.preventDefault();
    handleClose();
    handleOpenCode();
  }
  handleCodeVerfication(e) {
    e.preventDefault();
  }
  async code() {
    // eslint-disable-next-line no-unused-vars
    const res = await adminServices.codeV();
  }
  async confirmationCode(
    codeNumber,
    handleClose,
    handleCloseCode,
    notifications,
    t
  ) {
    const res = await adminServices.codeconfimation(codeNumber);
    if (res.message === "success") {
      notifications.notifySuccess(t("done_code"));
      handleClose();
      handleCloseCode();
      window.location.reload(false);
    } else {
      notifications.notifyError(t("error_code"));
    }
  }
}

const adminController = new AdminController();

export default adminController;
