/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import loader from "../../../../Components/loader.gif";
import { HospitalDataContext } from "../../../../store/data-context";
import hospitalServices from "../../../HospitalAccount/services/HospitalServices";
import hospitaloffersController from "../../controller/HospitalOffersController";
import Spinner from "../../../../Components/Spinner";
import notifications from "../../../../Components/toastify/notifications";
import NavBar from "../../../../Components/Nav/NavComponent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreateHospitalOffers.css";

const CreateHospitalOffers = () => {
  const { t } = useTranslation();
  const { setShow, Offers, offerCategories, data, countriesData } =
    useContext(HospitalDataContext);
  const navigate = useNavigate();
  const [image, setImage] = useState({ preview: null, file: null });
  const [title, setTitle] = useState("");
  const [titleEnglish, setTitleEnglish] = useState("");
  const [titleArabic, setTitleArabic] = useState("");
  const [titleTurkish, setTitleTrukish] = useState("");
  const [offerCountryId, setOfferCountry] = useState(1);
  const [offerCityId, setOfferCity] = useState(1);
  const [details, setDetails] = useState("");
  const [detailsEnglish, setDetailsEnglish] = useState("");
  const [detailsArabic, setDetailsArabic] = useState("");
  const [detailsTurkish, setDetailsTurkish] = useState("");

  const [facebook_link, setFacebookLink] = useState("");
  const [twitter_link, setTwitterLink] = useState("");
  const [instagrm_link, setInstagrmLink] = useState("");
  const [youtube_link, setYoutubeLink] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");

  const [instruction, setInstruction] = useState("");
  const [instructionEnglish, setInstructionEnglish] = useState("");
  const [instructionArabic, setInstructionArabic] = useState("");
  const [instructionTurkish, setInstructionTurkish] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [hospitalId, setHospitalId] = useState(data.hospital[0].id);
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [cities2, setCities2] = useState("");

  useEffect(() => {
    async function fetchingCities() {
      try {
        const resCities = await hospitalServices.get_cities_for_country(
          countryId || 1
        );
        setCities2(resCities.cities);
      } catch (error) {}
    }
    fetchingCities();
  }, [countryId]);
  const fromData = {
    image,
    title,
    titleEnglish,
    titleArabic,
    titleTurkish,
    offerCountryId,
    offerCityId,
    details,
    detailsEnglish,
    detailsArabic,
    detailsTurkish,
    facebook_link,
    twitter_link,
    instagrm_link,
    youtube_link,
    whatsapp,
    website,
    email,
    instruction,
    instructionEnglish,
    instructionArabic,
    instructionTurkish,
    endDate,
    startDate,
    hospitalId,
  };
  const handleDetails = (html) => {
    setDetails(html);
  };
  const handleDetailsEnglish = (html) => {
    setDetailsEnglish(html);
  };
  const handleDetailsArabic = (html) => {
    setDetailsArabic(html);
  };
  const handleDetailsTurkish = (html) => {
    setDetailsTurkish(html);
  };

  const handleInstruction = (html) => {
    setInstruction(html);
  };
  const handleInstructionEnglish = (html) => {
    setInstructionEnglish(html);
  };
  const handleInstructionArabic = (html) => {
    setInstructionArabic(html);
  };
  const handleinstructionTurkish = (html) => {
    setInstructionTurkish(html);
  };

  if (0) {
    return <img src={loader} alt="" />;
  } else {
    return (
      <div className="update-cont">
        <NavBar />
        <h2 className="edit-hos-data"> {t("create-offer")}</h2>
        {/* <div>
          <h2>HTML Content:</h2>
          <div dangerouslySetInnerHTML={{ __html: editorHtml }} />
        </div> */}

        <div className="conto8b">
          <form
            id="contact8b"
            onSubmit={(e) =>
              hospitaloffersController.handleHospitalOffers(
                e,
                fromData,
                notifications,
                t
                // navigate,
                // setShow
              )
            }
            encType="multipart/form-data"
          >
            <fieldset className="inno">
              <label htmlFor="upload-hospital" className="imgblock">
                {image.preview ? (
                  <img
                    src={image.preview}
                    name="hospital-photo"
                    alt="dummy"
                    className="object-cover w-40 h-40"
                  />
                ) : (
                  <>
                    <span className="fa-stack fa-4x mt-3 mb-2">
                      <i className="fa-solid fa-upload"></i>
                    </span>
                    <h5>{t("choose_hospital_image-offers")}</h5>
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-hospital"
                style={{ display: "none" }}
                onChange={(e) =>
                  hospitaloffersController.handleHospitalImageoffer(e, setImage)
                }
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-name">
                {t("title")}
              </label>
              <input
                placeholder={t("title")}
                name="hospital-name"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="title_english">
                {t("title_english")}
              </label>
              <input
                placeholder={t("title_english")}
                name="title_english"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitleEnglish(e.target.value)}
                value={titleEnglish}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="title_arabic">
                {t("title_arabic")}
              </label>
              <input
                placeholder={t("title_arabic")}
                name="title_arabic"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitleArabic(e.target.value)}
                value={titleArabic}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="title_turkish">
                {t("title_turkish")}
              </label>
              <input
                placeholder={t("title_turkish")}
                name="title_turkish"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitleTrukish(e.target.value)}
                value={titleTurkish}
                required
              />
            </fieldset>

            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="details">
                  {t("details")}
                </label>
              </div>
              <ReactQuill
                value={details}
                onChange={handleDetails}
                className="text-editor"
              />

              <br />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="details_english">
                  {t("details_english")}
                </label>
              </div>
              <ReactQuill
                value={detailsEnglish}
                onChange={handleDetailsEnglish}
                className="text-editor"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="details_arabic">
                  {t("details_arabic")}
                </label>
              </div>
              <ReactQuill
                value={detailsArabic}
                onChange={handleDetailsArabic}
                className="text-editor"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="details_turkish">
                  {t("details_turkish")}
                </label>
              </div>
              <ReactQuill
                value={detailsTurkish}
                onChange={handleDetailsTurkish}
                className="text-editor"
              />
            </fieldset>

            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="instruction">
                  {t("instruction")}
                </label>
              </div>
              <ReactQuill
                value={instruction}
                onChange={handleInstruction}
                className="text-editor"
              />
            </fieldset>

            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="instruction_english">
                  {t("instruction_english")}
                </label>
              </div>
              <ReactQuill
                value={instructionEnglish}
                onChange={handleInstructionEnglish}
                className="text-editor"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="instruction_arabic">
                  {t("instruction_arabic")}
                </label>
              </div>
              <ReactQuill
                value={instructionArabic}
                onChange={handleInstructionArabic}
                className="text-editor"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="instruction_turkish">
                  {t("instruction_turkish")}
                </label>
              </div>
              <ReactQuill
                value={instructionTurkish}
                onChange={handleinstructionTurkish}
                className="text-editor"
              />
            </fieldset>

            <fieldset className="inno">
              <div className="icon-cont"></div>
              <label className="label4input" htmlFor="start_date">
                {t("start_date")}:
              </label>
              <input
                name="start_date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                tabIndex="8"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont"></div>
              <label className="label4input" htmlFor="end_date">
                {t("end_date")}:
              </label>
              <input
                name="end_date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                tabIndex="8"
                id="hospital-name"
              />
            </fieldset>
            <ToastContainer />
            <fieldset>
              <button
                name="submit-hospital"
                type="submit"
                id="contact-submit2"
                data-submit="...Sending"
              >
                {loading ? <Spinner /> : t("save")}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
};

export default CreateHospitalOffers;
