import RegisterForm from "../components/Register/RegisterForm";
import NavComponent from "../../../Components/Nav/NavComponent";

const Register = () => {
  return (
    <div>
      <NavComponent />
      <RegisterForm />
    </div>
  );
};

export default Register;
