import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { HospitalDataContext } from "../../../../store/data-context";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
// import hcpServices from "../../services/HcpServices";
import hcpController from "../../controller/HcpController";
import notifications from "../../../../Components/toastify/notifications";
import loader from "../../../../Components/loader.gif";
import Spinner from "../../../../Components/Spinner";
import "./HcpDepartments.css";
// import "../../../Appointment/components/ScheduleAppointment/ScheduleAppointment.css";
const HcpDepartments = () => {
  const { hcpSpecialties, hcp } = useContext(HospitalDataContext);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState();
  const [isLoading, setIsLoading] = useState(false);

  if (hcpSpecialties.length === 0) {
    return <img className="loader" src={loader} alt="" />;
  } else {
    return (
      <div className="schedule-cont backgound">
        <h3 className="doctors-h3">{t("hcp_specialties")}</h3>
        <div className="tab-switch">
          <div className="mytabs">
            <input
              type="radio"
              id="tabfree"
              name="mytabs"
              defaultChecked="checked"
            />
            <label className="tab-label" htmlFor="tabfree">
              {t("add_specialty")}
            </label>
            <div className="tab">
              <form
                // className="add-remove-all"
                onSubmit={(e) =>
                  hcpController.handleAddSection(
                    e,
                    setIsLoading,
                    selectedOption,
                    notifications,
                    t
                  )
                }
              >
                <div className="s-all1">
                  <div className="">
                    <span className="label4selections">{t("departments")}</span>
                  </div>
                  <br />

                  <div className="s-tag1">
                    <span
                      htmlFor="hospital-address1"
                      className="selection-label"
                    >
                      {t("hcp_specialties")}
                    </span>
                    {document.body.dir === "rtl" ? (
                      <select
                        name="hospital-country"
                        id="hospital-address1"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <option value="invalid">
                          {t("choose_department_from_here")}
                        </option>
                        {hcpSpecialties?.specialties?.map((i, n) => (
                          <option key={i.id} value={i.id}>
                            {i.name_ar}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        name="hospital-country"
                        id="hospital-address1"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <option value="invalid">
                          {t("choose_department_from_here")}
                        </option>
                        {hcpSpecialties?.specialties.map((i, n) => (
                          <option key={i.id} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <button
                    name="submit-admin-account"
                    type="submit"
                    id="contact-submit1"
                    data-submit="...Sending"
                  >
                    {isLoading ? <Spinner /> : t("add")}
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>

            <input type="radio" id="tabgold" name="mytabs" />
            <label className="tab-label" htmlFor="tabgold">
              {t("add_remove_specialty")}
            </label>
            <div className="tab">
              <div className="department">
                {hcp?.hospital_hcp_departments?.map((dep) => {
                  return (
                    <section className="department-section" key={dep.id}>
                      <article>
                        <Avatar
                          className="avatar"
                          alt="Remy Sharp"
                          src={dep.specialty.img}
                        />
                        <Typography className="dep-name">
                          {document.body.dir === "rtl"
                            ? dep.specialty.name_ar
                            : dep.specialty.name}
                          {}
                        </Typography>
                      </article>
                      <button
                        onClick={(e) =>
                          hcpController.toggleDepartmentStatus(e, dep.id)
                        }
                        className={dep.is_hidden ? "show-hcp" : "hide"}
                      >
                        {dep.is_hidden ? t("show") : t("hidden")}
                      </button>
                    </section>
                  );
                })}
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  }
};

export default HcpDepartments;
