/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import hospitalServices from "../../services/HospitalServices";
import Button from "react-bootstrap/Button";
import useFetch from "../../../../Hooks/useFetch";
import Loader from "../../../../Components/Loader/loader";
import { HospitalDataContext } from "../../../../store/data-context";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
import "./HospitalForm.css";

const HospitalForm = ({ countries, handleShow }) => {
  const { data, isLoading } = useContext(HospitalDataContext);
  const { t } = useTranslation();
  const [hospitalCity, setHospitalCity] = useState(1);

  const countryIdN = () => {
    if (data.length !== 0) {
      return data?.hospital[0]?.country_id;
    } else {
      return;
    }
  };
  const { data: cityNamesss } = useFetch({
    actionMethod: hospitalServices.get_cities_for_country(countryIdN()),
    dep: countryIdN(),
  });

  const country = countries?.countries?.find(
    (count) => count.id === countryIdN()
  );

  const cityIdN = () => {
    if (data.length !== 0) {
      return data?.hospital[0]?.city_id;
    } else {
      return;
    }
  };
  // const cityIdN = data?.hospital[0]?.city_id;
  const city = cityNamesss?.cities?.find((city) => city.id === cityIdN());
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="conto8">
        <form id="contact8">
          <fieldset className="inno">
            <label htmlFor="upload-hospital" className="imgblock">
              <img
                src={data?.hospital[0]?.logo}
                name="hospital-photo"
                alt="hospital logo"
                className="object-cover w-40 h-40"
              />
            </label>
            <section className="top-button">
              <Link className="translate-btn" to={"/translate-hospital"}>
                {t("translate")}
              </Link>
              <div className="gallery-blk">
                <Button
                  className="all-location-btn"
                  variant="primary"
                  onClick={handleShow}
                >
                  {t("add_location")}
                </Button>
              </div>
            </section>
          </fieldset>
          <fieldset className="inno">
            <label className="label4input" htmlFor="hospital-name">
              {t("hospital_name")}
            </label>
            <input
              placeholder={t("hospital_name")}
              name="hospital-name"
              type="text"
              tabIndex="1"
              id="hospital-name"
              value={data?.hospital[0]?.name || ""}
              // onChange={(e) => setName(e.target.value.trim())}
              required
              readOnly
            />
          </fieldset>
          <fieldset className="inno">
            <label className="label4input" htmlFor="hospital-email">
              {t("email_label")}
            </label>
            <input
              placeholder={t("email_label")}
              name="hospital-email"
              type="text"
              tabIndex="4"
              id="hospital-email"
              value={data?.hospital[0]?.email || ""}
              // onChange={(e) => setEmail(e.target.value.trim())}
              required
              readOnly
            />
          </fieldset>

          <fieldset className="inno">
            <label className="label4input" htmlFor="landline-number">
              {t("phone_number_label")}
            </label>
            <input
              placeholder={t("phone_number_label")}
              name="landline-number"
              type="number"
              tabIndex="4"
              id="landline-number"
              value={data?.hospital[0]?.phone || ""}
              // onChange={(e) => setPhone(e.target.value.trim())}
              required
              readOnly
            />
          </fieldset>
          <fieldset className="inno">
            <label className="label4input" htmlFor="mobile-number">
              {t("mobile_number_label")}
            </label>
            <input
              placeholder={t("mobile_number_label")}
              name="mobile-number"
              // mobileInput
              type="number"
              tabIndex="4"
              id="mobile-number"
              value={data?.hospital[0]?.phone2 || ""}
              // onChange={(e) => setPhone(e.target.value.trim())}
              required
              readOnly
            />
          </fieldset>
          <fieldset className="inno">
            <div className="icon-cont">
              <AiFillFacebook className="social-icons face" />
            </div>
            <br />
            <label className="label4input" htmlFor="hospital-name">
              {t("facebook")}:
            </label>
            <input
              name="hospital-name"
              type="text"
              tabIndex="1"
              id="hospital-name"
              defaultValue={data?.hospital[0]?.facebook_link || ""}
            />
          </fieldset>

          <fieldset className="inno">
            <div className="icon-cont">
              <AiOutlineInstagram className="social-icons insta" />
            </div>
            <label className="label4input" htmlFor="hospital-name">
              {t("instagram")}:
            </label>
            <input
              name="hospital-name"
              type="text"
              tabIndex="1"
              id="hospital-name"
              defaultValue={data?.hospital[0]?.instagram_link || ""}
            />
          </fieldset>

          <fieldset className="inno">
            <div className="icon-cont">
              <AiOutlineTwitter className="social-icons twitter" />
            </div>
            <label className="label4input" htmlFor="hospital-name">
              {t("twitter")}:
            </label>
            <input
              name="hospital-name"
              type="text"
              tabIndex="1"
              id="hospital-name"
              defaultValue={data?.hospital[0]?.twitter_link || ""}
            />
          </fieldset>

          <fieldset className="inno">
            <div className="icon-cont">
              <AiFillLinkedin className="social-icons linked" />
            </div>
            <label className="label4input" htmlFor="hospital-name">
              {t("linkedin")}:
            </label>
            <input
              name="hospital-name"
              type="text"
              tabIndex="1"
              id="hospital-name"
              defaultValue={data?.hospital[0]?.linkedin_link || ""}
            />
            {/* value={data?.hospital[0]?.name || ""} onChange={(e) => setName(e.target.value.trim())} required */}
          </fieldset>

          <fieldset className="inno">
            <div className="icon-cont">
              <AiFillYoutube className="social-icons tube" />
            </div>
            <label className="label4input" htmlFor="hospital-name">
              {t("youtube")}:
            </label>
            <input
              name="hospital-name"
              type="text"
              tabIndex="1"
              id="hospital-name"
              defaultValue={data?.hospital[0]?.youtube_link || ""}
            />
          </fieldset>
          <fieldset className="s-all1">
            <div className="s-label">
              <label className="label4input" htmlFor="hospital-address">
                {t("hospital_address")}
              </label>
            </div>
            <br />
            <div className="s-tag1">
              <div>
                <select
                  name="hospital-city"
                  id="hospital-address"
                  value={hospitalCity}
                  onChange={(e) => setHospitalCity(e.target?.value)}
                >
                  <option>{country.name}</option>
                </select>
              </div>
              <div>
                <select
                  name="hospital-city"
                  id="hospital-address"
                  value={hospitalCity}
                  onChange={(e) => setHospitalCity(e.target?.value)}
                >
                  <option>{city?.name}</option>
                </select>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <textarea
              placeholder={t("address_details")}
              tabIndex="6"
              name="hospital-address-details"
              id="hospital-address-details"
              cols="30"
              rows="4"
              value={data?.hospital[0]?.adress || ""}
              // onChange={(e) => setAdress(e.target.value.trim())}
              readOnly
            ></textarea>
          </fieldset>
          <fieldset className="inno innolast">
            <label className="label4input" htmlFor="hospital-info">
              {t("hospital_bio")}
            </label>
            <textarea
              placeholder={t("hospital_bio")}
              tabIndex="7"
              name="hospital-info"
              id="hospital-info"
              cols="30"
              rows="6"
              value={data?.hospital[0]?.bio || ""}
              // onChange={(e) => setBio(e.target.value.trim())}
              required
              readOnly
            ></textarea>
          </fieldset>
          <fieldset className="update-btn-cont">
            <Link className="update-btn" to={"/update-hospital"}>
              {t("edit")}
            </Link>
          </fieldset>
        </form>
      </div>
    );
  }

  // }
};

export default HospitalForm;
