/* eslint-disable no-unused-vars */
// if the user is new and there's no hospital already created, a Blank white page will be displayed after a little bit
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../store/auth-context";
import { HospitalDataContext } from "../../../store/data-context";
import HospitalForm from "../components/HospitalForm/HospitalForm";
import HospitalGallery from "../components/HospitalGallery/HospitalGallery";
import HospitalBranch from "../components/HospitalBranch/HospitalBranch";
import hospitalController from "../controller/HospitalController";
import Map from "../components/map/map";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import loader from "../../../Components/loader.gif";
import "../components/map/style.css";
const HospitalData = () => {
  const { data, countriesData } = useContext(HospitalDataContext);
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  if (!authCtx.account) {
    return (
      <div className="create-acc1">
        {t("create_hospital")} <a href="/createhospital">{t("here")}</a>
      </div>
    );
  } else if (!data || !countriesData) {
    <img className="loader" src={loader} alt="" />;
  } else {
    return (
      <div>
        <HospitalForm
          data={data}
          countries={countriesData}
          handleShow={handleShow}
        />
        <HospitalBranch data={data} />
        <HospitalGallery />
        <>
          <div className="gallery-blk">
            <label></label>
            <Button
              className="gallery-btn"
              variant="primary"
              onClick={() => hospitalController.handleDoctorLocation()}
            >
              {t("add_location_to_doctor")}
            </Button>
          </div>

          <Modal show={show} onHide={handleClose} className="map-modal">
            <Modal.Header>
              <Modal.Title>{t("add_location")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Map show={show} />
              {/* <Button
                  variant="primary"
                  type="submit"
                  onClick={() => hospitalController.handleLocation()}
                >
                  {t("save")} */}
              {/* </Button> */}
            </Modal.Body>
          </Modal>
        </>
      </div>
    );
  }
};

export default HospitalData;
