import axiosProvider from "../../../api/base";

class HospitalOffersServices {
  // Create a offer Hospital
  async hospitalOffers(data) {
    try {
      const response = await axiosProvider.postRequest("/offer", data);
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  // edit a offer Hospital

  async editHospitalOffers(data) {
    try {
      const response = await axiosProvider.postRequest("/edit-offer", data);
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // Get Admin Hospital
  async get_offers() {
    try {
      const response = await axiosProvider.getRequest("/offers");
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  async createCoupon(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/offering-coupon",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  async updateCoupon(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/update-offering-coupon",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async getAllCouponsById(id) {
    try {
      const response = await axiosProvider.getRequest(
        `/offering-coupons/${id}`
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  async getdiscountTypes() {
    try {
      const response = await axiosProvider.getRequest("/discount-types");
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  async getCouponById(id) {
    try {
      const response = await axiosProvider.getRequest(`/offering-coupon/${id}`);
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async toggelCouponActive(id) {
    try {
      const response = await axiosProvider.getRequest(
        `/toggle-coupon-active/${id}`
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  async get_offer_categories() {
    try {
      const response = await axiosProvider.getRequest("/offer-categories");
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async getBookedCoupons() {
    try {
      const response = await axiosProvider.getRequest("/booked-coupons");
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }

  // Add image to hospital picture gallery
  async Add_img_to_hospital(img) {
    const response = await axiosProvider.postRequest("/add-hospital-img", {
      img,
    });
    return response;
  }
  async createBranch(data) {
    try {
      const response = await axiosProvider.postRequest("/create-branch", data);
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async get_offer_by_id(id) {
    try {
      const response = await axiosProvider.getRequest(`/offer/${id}`);
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
}

const hospitalOffersServices = new HospitalOffersServices();

export default hospitalOffersServices;
