/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import hospitalServices from "../../services/HospitalServices";
import hospitalController from "../../controller/HospitalController";
// import axiosProvider from "../../../../api/base";
import loader from "../../../../Components/loader.gif";
import notifications from "../../../../Components/toastify/notifications";
import "./BranchFormModal.css";

function BranchFormModal({ data, countries }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const name = useRef();
  const address = useRef();
  const [hospitalCountry, setHospitalCountry] = useState(1);
  const [hospitalCity, setHospitalCity] = useState(1);
  const [countryId, setCountryId] = useState("");
  const [cities2, setCities2] = useState("");

  useEffect(() => {
    // getting the cities for a specific country
    async function fetchingCities() {
      try {
        const resCities = await hospitalServices.get_cities_for_country(
          countryId || 1
        );
        setCities2(resCities.cities);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchingCities();
  }, [countryId]);

  // const handleChooseCountry = (e) => {
  //   setHospitalCountry2(e.target.value);
  //   setCountryId2(e.target.value);
  // };

  // const handleChooseCity = (e) => {
  //   const index = e.target.selectedIndex;
  //   const el = e.target.childNodes[index];
  //   const option = el.getAttribute("id");
  //   setHospitalCity2(option);
  // };

  // const handleSubmitBranch = async (e) => {z
  //   e.preventDefault();

  //   const enteredName = name.current.value;
  //   const enteredAddress = address.current.value;

  //   try {
  //     let formData = new FormData();
  //     formData.append("hospital_id", data.hospital[0].id);
  //     formData.append("city_id", hospitalCity2);
  //     formData.append("name", enteredName);
  //     formData.append("street_name", enteredAddress);
  //     const response = await axiosProvider.postRequest(
  //       "/create-branch",
  //       formData
  //     );
  //     if (response.message === "success") {
  //       notifications.notifySuccess(t("done_add_newBranch"));
  //       handleClose();

  //       return;
  //     } else {
  //       notifications.notifyError(t("error_branch"));
  //     }
  //     return response;
  //   } catch (error) {
  //     return error;
  //   }
  // };

  if (!countries || !cities2) {
    return <img src={loader} alt="" />;
  } else {
    return (
      <>
        <Button className="add-btnnn" variant="primary" onClick={handleShow}>
          {t("add_another_address")}
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{t("add_another_branch")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={(e) =>
                hospitalController.handleSubmitBranch(
                  e,
                  name,
                  address,
                  data,
                  hospitalCity,
                  notifications,
                  handleClose,
                  t
                )
              }
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("branch_name")}</Form.Label>
                <Form.Control type="text" ref={name} />
                {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text> */}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("country")}</Form.Label>
                <br />
                <select
                  name="hospital-country"
                  id="hospital-address"
                  onChange={(e) =>
                    hospitalController.handleChooseCountry(
                      e,
                      setHospitalCountry,
                      setCountryId
                    )
                  }
                >
                  <option value="">--{t("choose_country")}--</option>
                  {countries?.countries?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("city")}</Form.Label>
                {/* <Form.Control type="select" ref={city} /> */}
                <br />
                <select
                  name="hospital-city"
                  id="hospital-address"
                  onChange={(e) =>
                    hospitalController.handleChooseCity(e, setHospitalCity)
                  }
                >
                  <option value="">--{t("choose_city")}--</option>
                  {cities2.map((city) => (
                    <option key={city.id} id={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("governorate")}</Form.Label>
              <Form.Control type="text" ref={province} />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("address")}</Form.Label>
                <Form.Control type="text" ref={address} />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>الرمز البريدي</Form.Label>
              <Form.Control type="text" ref={postalCode} />
              </Form.Group> */}

              {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Check me out" />
              </Form.Group> */}
              <Button variant="primary" type="submit">
                {t("save")}
              </Button>
              <ToastContainer />
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default BranchFormModal;
