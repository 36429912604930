import React, { useContext, useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../store/auth-context";

import ScheduleAppointmentView from "../../../Appointment/views/ScheduleAppointmentView";
import ManagingAdminAccount from "../../../AdminAccount/components/ManagingAdminAccount/ManagingAdminAccount";
import MedicalSections from "../../../MedicalDepartments/views/MedicalSections";
import HospitalData from "../../../HospitalAccount/views/HospitalData";
import Doctors from "../../../Doctors/views/Doctors";
import DoctorsList from "../../../Doctors/components/DoctorsList/DoctorsList";
import HcpDepartments from "../../../hcp/component/HcpDepartment/HcpDepartments";
import AddHcp from "../../../hcp/component/AddHcp/AddHcp";
import HcpList from "../../../hcp/component/HcpList/HcpList";
// import BloodBank from "../../../BloodBank/views/BloodBank";
import HospitalOffers from "../../../hopitalOffers/views/HospitalOffers";

import { RiAdminLine, RiNurseLine, RiHospitalLine } from "react-icons/ri";
import { GiSandsOfTime } from "react-icons/gi";
import { MdOutlineLocalHospital } from "react-icons/md";
import { FcDepartment } from "react-icons/fc";
import "./AdminMain.css";

const AdminMain = () => {
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const [tab, setTab] = useState("");

  // this is for detecting if user is using a mobile device to render different elements
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 500;

  // the following is for persisting the selected tab after a refresh
  useEffect(() => {
    const t = window.localStorage.getItem("nav-tab");
    const tab = JSON.parse(t);
    if (tab === null || tab === undefined) {
      return;
    } else {
      setTab(tab);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("nav-tab", JSON.stringify(tab));
  }, [tab]);

  const handleChangeTab = (index) => {
    setTab(index);
  };

  return (
    <div className="admin-main">
      <Tabs onSelect={handleChangeTab} selectedIndex={tab || 0}>
        <TabList>
          <Tab>
            {isMobile ? (
              <RiAdminLine className="tab-icon-main" />
            ) : (
              <p>{t("managing_admin_p")}</p>
            )}
          </Tab>
          <Tab>
            {isMobile ? (
              <RiHospitalLine className="tab-icon-main" />
            ) : (
              <p>{t("hospital_data_p")}</p>
            )}
          </Tab>
          {authCtx.account && (
            <>
              <Tab>
                {isMobile ? (
                  <MdOutlineLocalHospital className="tab-icon-main" />
                ) : (
                  <p>{t("departments")}</p>
                )}
              </Tab>
              <Tab>
                {isMobile ? (
                  <FcDepartment className="tab-icon-main" />
                ) : (
                  <p>{t("doctors")}</p>
                )}
              </Tab>
              <Tab>
                {isMobile ? (
                  <RiNurseLine className="tab-icon-main" />
                ) : (
                  <p>{t("doctor_data")}</p>
                )}
              </Tab>
              <Tab>
                {isMobile ? (
                  <GiSandsOfTime className="tab-icon-main" />
                ) : (
                  <p>{t("schedule_appointments")}</p>
                )}
              </Tab>
              <Tab>
                {isMobile ? (
                  <GiSandsOfTime className="tab-icon-main" />
                ) : (
                  <p>{t("hcp_departments")}</p>
                )}
              </Tab>
              <Tab>
                {isMobile ? (
                  <GiSandsOfTime className="tab-icon-main" />
                ) : (
                  <p>{t("add_hcps")}</p>
                )}
              </Tab>
              <Tab>
                {isMobile ? (
                  <GiSandsOfTime className="tab-icon-main" />
                ) : (
                  <p>{t("hcps_data")}</p>
                )}
              </Tab>
              {/* <Tab>
                {isMobile ? (
                  <GiSandsOfTime className="tab-icon-main" />
                ) : (
                  <p>{t("blood_bank")}</p>
                )}
              </Tab> */}
              <Tab>
                {isMobile ? (
                  <GiSandsOfTime className="tab-icon-main" />
                ) : (
                  <p>{t("hospital-offers")}</p>
                )}
              </Tab>
            </>
          )}
        </TabList>
        <TabPanel>
          <div className="panel-content">
            <ManagingAdminAccount />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <HospitalData />
          </div>
        </TabPanel>
        {authCtx.account && (
          <>
            <TabPanel>
              <MedicalSections />
            </TabPanel>
            <TabPanel>
              <Doctors />
            </TabPanel>
            <TabPanel>
              <DoctorsList />
            </TabPanel>
            <TabPanel>
              <ScheduleAppointmentView />
            </TabPanel>
            <TabPanel>
              <HcpDepartments />
            </TabPanel>
            <TabPanel>
              <AddHcp />
            </TabPanel>
            <TabPanel>
              <HcpList />
            </TabPanel>
            {/* <TabPanel>
              <BloodBank />
            </TabPanel> */}
            <TabPanel>
              <HospitalOffers />
            </TabPanel>
          </>
        )}
      </Tabs>
    </div>
  );
};

export default AdminMain;
