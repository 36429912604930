import NavBar from "../../../Components/Nav/NavComponent";
import DoctorProfile from "../components/DoctorProfileComponent/DoctorProfile.component";

const DoctorProfilePage = () => {
  return (
    <>
      <NavBar />
      <DoctorProfile />
    </>
  );
};

export default DoctorProfilePage;
