/* eslint-disable no-unused-vars */
import { useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import Loader from "../../../../Components/Loader/loader";
import notifications from "../../../../Components/toastify/notifications";
import { HospitalDataContext } from "../../../../store/data-context";
import adminController from "../../controller/AdminController";
import PhoneVerficationModal from "../PhoneVerficationModal/PhoneVerficationModal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsPlusCircle } from "react-icons/bs";
import "./ManagingAdminAccount.css";
const ManagingAdminAccount = () => {
  const { t } = useTranslation();
  const { admin, show, setShow, handleClose, data, isLoading } =
    useContext(HospitalDataContext);
  const [image, setImage] = useState({ preview: "", raw: "" });
  // const number = useRef();
  const [showEditPHone, setShowEditPHone] = useState(false);
  const handleEditPhoneClose = () => setShowEditPHone(false);
  const handleEditPhoneShow = () => setShowEditPHone(true);
  const [email, setEmail] = useState("");

  const [number, setNumber] = useState(admin?.hospital_admin?.phone);
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="conto7">
        <form id="contact7" action="" method="post">
          <fieldset className="inno relatived">
            <label htmlFor="upload-admin" className="imgblock">
              <img
                src={admin?.hospital_admin?.profile_img}
                alt="dummy"
                width="300"
                height="300"
              />
              <div className="photo-plus">
                <BsPlusCircle />
              </div>
            </label>
            <input
              type="file"
              id="upload-admin"
              style={{ display: "none" }}
              onChange={(e) => adminController.handleChange(e, setImage)}
            />
          </fieldset>
          <fieldset className="inno">
            <label className="label4input" htmlFor="admin-name">
              {t("name_label")}
            </label>
            <input
              placeholder={t("name_label")}
              type="text"
              tabIndex="1"
              id="admin-name"
              value={
                admin?.hospital_admin?.first_name +
                " " +
                admin?.hospital_admin?.last_name
              }
              required
              autoFocus
              readOnly
            />
          </fieldset>

          <fieldset className="inno">
            <label className="label4input" htmlFor="admin-number">
              {t("phone_number_label")}
            </label>
            <section className="edit-phone-section">
              <input
                placeholder={t("phone_number_label")}
                type="tel"
                tabIndex="2"
                id="admin-number"
                value={admin?.hospital_admin?.phone}
                required
                readOnly
              />
              <Button
                className="add-btnnn mr-2"
                variant="primary"
                onClick={handleEditPhoneShow}
              >
                {t("edit")}
              </Button>
            </section>
          </fieldset>

          <fieldset className="inno">
            <label className="label4input" htmlFor="admin-email">
              {t("email_label")}
            </label>
            <input
              placeholder={t("email_label")}
              type="email"
              tabIndex="3"
              id="admin-email"
              // onChange={(e) => setEmail(e.target.value)}
              value={admin?.hospital_admin?.email}
              required
              readOnly
            />
          </fieldset>
          <fieldset className="noaccount3">
            <p>
              <a className="noaccount3" href="/resetpassword">
                {t("change_password")}
              </a>
            </p>
          </fieldset>
          <Modal show={showEditPHone} onHide={handleEditPhoneClose}>
            <Modal.Header>
              <Modal.Title>{t("edit-phone_number_label")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onSubmit={(e) =>
                  adminController.handleEditAdminPhone(
                    e,
                    number,
                    notifications,
                    handleEditPhoneClose,
                    t
                  )
                }
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{t("phone_number_label")}</Form.Label>

                  <Form.Control
                    type="number"
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                  />
                </Form.Group>

                <Button variant="primary" type="submit">
                  {t("save")}
                </Button>
                <ToastContainer />
              </Form>
            </Modal.Body>
          </Modal>
        </form>

        <PhoneVerficationModal
          show={show}
          handleClose={handleClose}
          setShow={setShow}
        />
      </div>
    );
  }
};

export default ManagingAdminAccount;
