import { Navigate, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./store/auth-context";
import LogIn from "./modules/authentication/views/Login";
import Register from "./modules/authentication/views/Register";
import ForgotPassword from "./modules/authentication/views/ForgotPassword/ForgotPassword";
import ResetPassword from "./modules/authentication/views/ResetPassword/ResetPassword";
import AddingDoctor from "./modules/Doctors/components/AddingDoctor/AddingDoctor";
import Home from "./modules/Navigation/views/Home";
import DoctorProfile from "./modules/ProfileDoctor/views/DoctorProfilePage";
import CreateHospital from "./modules/HospitalAccount/components/CreateHospital/CreateHospital";
import AboutUs from "./Components/Nav/AboutUs/AboutUs";
import CallUs from "./Components/Nav/CallUs/CallUs";
import CreateGallery from "./modules/HospitalAccount/components/CreateGallery/CreateGallery";
import UpdateHospital from "./modules/HospitalAccount/components/UpdateHospital/UpdateHospital";
import TranslateHospital from "./modules/HospitalAccount/components/TranslateHospital/TranslateHospital";
import HcpProfile from "./modules/ProfileHcp/views/HcpProfilePage";
import EditHospitalOffers from "./modules/hopitalOffers/component/editOffer/EditHospitalOffers";
import CreateHospitalOffers from "./modules/hopitalOffers/component/CreateHospitalOffer/CreateHospitalOffers";
import Coupon from "./modules/hopitalOffers/component/coupon/Coupon";
import CreateCoupon from "./modules/hopitalOffers/component/CreateCoupon/CreateCoupon";
import EditCoupon from "./modules/hopitalOffers/component/editCoupon/EditCoupon";

const AppRoutes = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  // const thereIsHospital = authCtx.thereIsHospital;
  return (
    <Routes>
      <Route path="/" element={isLoggedIn ? <Home /> : <LogIn />} />
      <Route path="/login" element={!isLoggedIn ? <LogIn /> : <Home />} />
      <Route path="/register" element={!isLoggedIn ? <Register /> : <Home />} />
      <Route
        path="/forgotpassword"
        element={!isLoggedIn ? <ForgotPassword /> : <Home />}
      />
      <Route
        path="/resetpassword"
        element={isLoggedIn ? <ResetPassword /> : <LogIn />}
      />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<CallUs />} />
      <Route
        path="/addingdoctor"
        element={isLoggedIn && authCtx.account ? <AddingDoctor /> : <LogIn />}
      />
      <Route
        path="/createhospital"
        element={
          isLoggedIn && !authCtx.account ? <CreateHospital /> : <LogIn />
        }
      />
      <Route
        path="/update-hospital"
        element={isLoggedIn && authCtx.account ? <UpdateHospital /> : <LogIn />}
      />
      <Route
        path="/translate-hospital"
        element={
          isLoggedIn && authCtx.account ? <TranslateHospital /> : <LogIn />
        }
      />
      <Route
        path="/gallery"
        element={isLoggedIn ? <CreateGallery /> : <LogIn />}
      />
      <Route
        path="/doctorprofile/:uniqueID"
        element={isLoggedIn && authCtx.account ? <DoctorProfile /> : <LogIn />}
      />
      <Route
        path="/hcpprofile/:uniqueID"
        element={isLoggedIn && authCtx.account ? <HcpProfile /> : <LogIn />}
      />
      <Route
        path="/edit-offer/:uniqueID"
        element={
          isLoggedIn && authCtx.account ? <EditHospitalOffers /> : <LogIn />
        }
      />
      <Route
        path="/coupon/:uniqueID"
        element={isLoggedIn && authCtx.account ? <Coupon /> : <LogIn />}
      />
      <Route
        path="/create-offer"
        element={
          isLoggedIn && authCtx.account ? <CreateHospitalOffers /> : <LogIn />
        }
      />
      <Route
        path="/create-coupon/:uniqueID"
        element={isLoggedIn && authCtx.account ? <CreateCoupon /> : <LogIn />}
      />
      <Route
        path="/edit-coupon/:uniqueID"
        element={isLoggedIn && authCtx.account ? <EditCoupon /> : <LogIn />}
      />
      {/* the next is for the Removed Successfully modal for a later implementation */}
      {/* <Route
          path="/removedsuccessfully"
          element={<RemovedSuccessfullyModal />}
        /> */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;

// this is an example before changing redirect method
// {isLoggedIn && <Route path='/resetpassword' element={<ResetPassword />} />}

// This is the old return before changing my mind on the implementation method

/* <Routes>
The next line is old code in terms of errors. (the first line should be commented)
<Route path="/" element={isLoggedIn ? <Home /> : <LogIn />} />
<Route path="/" element={!isLoggedIn ? <LogIn /> : <Home />} />
<Route path="/" element={!isCreated ? <new /> : <Home />} />
<Route path="/" element={isLoggedIn && isCreated && <Home />} />
<Route path="/login" element={!isLoggedIn ? <LogIn /> : <Home />} />
<Route path="/register" element={!isLoggedIn ? <Register /> : <Home />} />
<Route path="/forgotpassword" element={isLoggedIn ? <ForgotPassword /> : <LogIn />} />
<Route path="/resetpassword" element={isLoggedIn ? <ResetPassword /> : <LogIn />} />
<Route path="/addingdoctor" element={isLoggedIn ? <AddingDoctor /> : <LogIn />} />
<Route path="/doctorprofile/:uniqueID" element={isLoggedIn ? <DoctorProfile /> : <LogIn />} />
<Route path="*" element={<Navigate to="/" replace />} />
</Routes> */
