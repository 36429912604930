// Not used because we're redirecting now

// import { useEffect } from 'react';
import NavBar from '../NavComponent';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div>
      <NavBar />
      <div className='who-cont'>
        <div className='parag-cont'>
          <h1>What is SehaPracto</h1>
          <p>
            SehaPracto is an online platform to connect health care service providers with a consolidated customer base in Turkey. SehaPracto brings in a new value proposition to the healthcare sector in Turkey by giving healthcare providers greater market visibility and a dedicated online market space. Similarly, through this online portal, customers can browse various doctors and other healthcare providers in their locality (city, neighborhood, etc.) and get in contact with the health care service provider of their need.
          </p>
        </div>
        <div className='img-cont'>
          <img src="doc-pic.jpeg" alt="" />
        </div>
      </div>
      <div className='who-cont2'>
        <h4 className='bolded'>Objectives</h4>
        <ul>
          <li>To offer the best online marketplace for healthcare service providers and customers.</li>
          <li>To develop and offer a user-friendly website and Android/iOS app to facilitate our customers find the available service providers in their locality and avail their services.</li>
          <li>To consolidate data from supply and demand side of health care services sector and constitute a data bank of service providers and customers nationally.</li>
          <li>To support local, national health services providers to expand their visibility and exposure through the platform to new customers and clients.</li>
          <li>To capture a significant share of service sector market nationally.</li>
          <li>Online bookings with various types of doctors’ specialists and healthcare providers (dentists, allergists, dermatologists, psychologists, ophthalmologists, Physiotherapists, etc.)</li>
          <li>Patient-powered search (a search engine that bridges the gap between healthcare jargon and colloquial language)</li>
          <li>Access to care continuum with multiple points of access</li>
        </ul>
        <h4 className='bolded'>Mission Statement</h4>
        <p>We see ourselves as pioneers in heralding in a new age of online healthcare market place. We plan to transform the customer experience, ensure greater customer satisfaction, provide local healthcare provider greater access to national market and successfully disrupt the traditional demand aggregator businesses.</p>
        <h4 className='bolded'>Services</h4>
        <p>The system uses online electronic platform to provide booking medical appointments for doctors of different specialties and various healthcare providers (such as Physiotherapists, Dietitians, Speech therapists, etc.). It also allows to communicate with the healthcare provider ONLINE through the website and application for consultations and diagnosis.</p>
        <p>SehaPracto offers a dedicated space to users and service providers on its website/app. Following important features are enabled on the platform</p>
        <ul>
          <li>Business profile creation with upload of photos, videos, description of doctor specialty, category, experience and contact information</li>
          <li>Online advanced search option with location, service type and price filters</li>
          <li>Customer review section to rate the services provided</li>
          <li>Ability to share a healthcare service providers profile on major social media platforms such as Face Book, Twitter and Instagram.</li>
          <li>Chat Widget to facilitate any communication between customers and service providers</li>
          <li>E-payment integration</li>
          <li>Optional creation of user profiles with login and password</li>
          <li>Built in favorite list creation options for users</li>
        </ul>
      </div>
    </div>
  );
}

export default AboutUs;