import hcpServices from "../services/HcpServices";

class HcpController {
  async handleAddSection(e, setIsLoading, selectedOption, notifications, t) {
    e.preventDefault();
    setIsLoading(true);
    const response = await hcpServices.Add_hcp_department(selectedOption);

    if (response.message === "success") {
      notifications.notifySuccess(t("done_add_depart"));
      setIsLoading(false);
    } else if (
      response.message === "fail" &&
      response.error === "department exists"
    ) {
      notifications.notifyError(t("already_depart_exit"));
      setIsLoading(false);
    } else if (selectedOption === undefined) {
      notifications.notifyError(t("error_id__depart_hcp"));
      setIsLoading(false);
    } else {
      notifications.notifyError(response.error);
      setIsLoading(false);
    }
  }
  onSelectChange(e, setSelectedOption) {
    setSelectedOption(
      e.target.options[e.target.selectedIndex].getAttribute("id")
    );
  }
  async handleAddHcp(e, setIsLoading, phone, selectedOption, notifications, t) {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      phone,
      hospital_hcp_department_id: selectedOption,
    };

    const res = await hcpServices.add_hcp(data);
    if (res.message === "success") {
      setIsLoading(false);
      notifications.notifySuccess(t("done_add_hcp"));
    } else if (res.message === "fail" && res.error === "hcp not found") {
      notifications.notifyError(t(t("not_exit_hcp")));

      setIsLoading(false);
    } else if (
      res.message === "fail" &&
      res.error === "hcp already registered in hospital"
    ) {
      notifications.notifyError(t("exit_hcp"));

      setIsLoading(false);
    } else if (
      res.name === "AxiosError" ||
      res.message === "Request failed with status code 500"
    ) {
      notifications.notifyError(t("error_add_hcp"));
      setIsLoading(false);
    } else {
      notifications.notifyError(res.error);

      setIsLoading(false);
    }
  }

  async handleAddHcpByEmail(
    e,
    setIsLoading,
    email,
    selectedOption,
    notifications,
    t
  ) {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      email,
      hospital_hcp_department_id: selectedOption,
    };

    const res = await hcpServices.add_hcp_by_email(data);
    if (res.message === "success") {
      setIsLoading(false);
      notifications.notifySuccess(t("done_add_hcp"));
    } else if (res.message === "fail" && res.error === "hcp not found") {
      notifications.notifyError(t(t("not_exit_hcp")));

      setIsLoading(false);
    } else if (
      res.message === "fail" &&
      res.error === "hcp already registered in hospital"
    ) {
      notifications.notifyError(t("exit_hcp"));

      setIsLoading(false);
    } else if (
      res.name === "AxiosError" ||
      res.message === "Request failed with status code 500"
    ) {
      notifications.notifyError(t("error_add_hcp"));
      setIsLoading(false);
    } else {
      notifications.notifyError(res.error);

      setIsLoading(false);
    }
  }
  async toggleDepartmentStatus(e, id) {
    e.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const res = await hcpServices.toggle_hcp_depertment(id);
    window.location.reload(false);
  }
}

const hcpController = new HcpController();

export default hcpController;
