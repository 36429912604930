import "./LogInForm.css";
import { useTranslation } from "react-i18next";
import { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import authController from "../../controller/AuthController";
import Spinner from "../../../../Components/Spinner";
import { HospitalDataContext } from "../../../../store/data-context";
import notifications from "../../../../Components/toastify/notifications";
import { ToastContainer } from "react-toastify";
import AuthContext from "../../../../store/auth-context";

const LogInForm = () => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { setShow } = useContext(HospitalDataContext);

  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [error, setError] = useState();

  // const [isLoggedInState, setIsLoggedInState] = useState(authCtx.isLoggedIn);

  // const handleLogin = async (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   // console.warn(email, password);
  //   const enteredEmail = emailInputRef.current.value;
  //   const enteredPassword = passwordInputRef.current.value;
  //   //validate => all values good to go (send request to api) or show error(seperate function)=======function

  //   // const token = localStorage.getItem("token");
  //   // const data = { enteredEmail, enteredPassword };
  //   // const res = await authController.handleLogin(data);
  //   // console.log(res, "test");
  //   fetch("https://app.sehapracto.net/hospitaladminapi/login", {
  //     method: "POST",
  //     body: JSON.stringify({
  //       email: enteredEmail,
  //       password: enteredPassword,
  //       // returnSecureToken: true,
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((res) => {
  //       // setIsLoading(false);
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         return res.json().then((data) => {
  //           setLoading(false);
  //           let errorMessage = "Authentication failed!";
  //           // if (data && data.error && data.error.message) {
  //           //   errorMessage = data.error.message;
  //           // }

  //           throw new Error(errorMessage);
  //         });
  //       }
  //     })
  //     .then((data) => {
  //       if (data.error) {
  //         setError(data.error);
  //         setLoading(false);
  //         return;
  //       }

  //       // new code about to be implemented
  //       // const expirationTime = new Date(
  //       //   new Date().getTime() + +data.expiresIn * 1000
  //       // );
  //       authCtx.login(data);
  //       setLoading(false);

  //       // authCtx.isLoggedIn = true;
  //       // console.log({ dataToHaveToken: data });
  //       // could implement a function for changing user state to logged in

  //       navigate("/");
  //       window.location.reload(false);
  //     })
  //     .catch((err) => {
  //       // console.log({ errFromLoginCatch: err });
  //       setLoading(false);
  //       alert(err.message);
  //     });
  // };

  return (
    <div className="login-form">
      <form
        id="contact2"
        onSubmit={(e) =>
          authController.handleLogin(
            e,
            setLoading,
            emailRef,
            passwordRef,
            navigate,
            authCtx,
            notifications,
            t,
            setShow
          )
        }
      >
        <h3>{t("login")}</h3>
        <fieldset>
          <label htmlFor="email">{t("email_label")}</label>
          <input
            type="email"
            tabIndex="1"
            id="email"
            ref={emailRef}
            required
            autoFocus
          />
        </fieldset>
        <fieldset>
          <label htmlFor="password">{t("password_label")}</label>
          <input
            type="password"
            tabIndex="2"
            id="password"
            ref={passwordRef}
            minLength="8"
            required
          />
        </fieldset>
        {/* {error && (
          <p
            className={`bg-red-50 text-red-600 px-4 py-2 rounded-lg border border-red-100`}
          >
            {error}
          </p>
        )} */}
        <ToastContainer />
        <fieldset>
          <button
            name="submit"
            type="submit"
            id="contact-submit"
            data-submit="...Sending"
          >
            {loading ? <Spinner /> : t("login")}
          </button>
        </fieldset>
        {/* the next is for social media login links for a future iteration */}
        {/* <fieldset>
          <div className="or">{t("or")}</div>
        </fieldset>
        <fieldset className="social-buttons">
          <button type="button" className="google-btn">{t("login_google")}</button>
          <button type="button">{t("login_facebook")}</button>
          <button type="button" onClick={authController.testingFun}>
            test
          </button>
        </fieldset> */}
        <fieldset className="noaccount">
          <p>
            {t("no_account")} <a href="/register">{t("create_account")}</a>
          </p>
        </fieldset>
        <fieldset className="noaccount2">
          <p>
            <a className="noaccount2" href="/forgotpassword">
              {t("forgot_password")}
            </a>
          </p>
        </fieldset>
      </form>
    </div>
  );
};

export default LogInForm;
