/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./InfoUserModal.css";
import { useTranslation } from "react-i18next";
function InfoUserModal({ handleCloseInfo, showInfo, phone, emailUser }) {
  const { t } = useTranslation();

  const checkemail = (email) => {
    const facebook = "@facebook";
    const google = "@google";

    const test = email.includes(facebook || google);
    return test;
  };

  const checkPhone = (char) => {
    return /^\d$/.test(char);
  };

  return (
    <>
      <Modal show={showInfo} onHide={handleCloseInfo}>
        <Modal.Header>
          <Modal.Title className="title-model-clinic-appointment">
            {t("user_Info")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Form> */}
          <section className="user-info">
            <fieldset className="inno">
              <label className="label4input" htmlFor="phone">
                {t("phone_number_label")}
              </label>
              <input
                className="input-phone "
                placeholder={t("phone_number_label")}
                name="hospital-name"
                type="text"
                tabIndex="1"
                id="hospital-name"
                value={checkPhone(phone) ? `${t("not_verfiy_phone")}` : phone}
                required
              />
            </fieldset>

            {/* <label>
              {t("phone")}:{phone}
              {}
            </label> */}

            <fieldset className="inno">
              <label className="label4input" htmlFor="email">
                {t("email_label")}
              </label>
              <input
                className="input-phone "
                placeholder={t("email_label")}
                name="hospital-name"
                type="email"
                tabIndex="1"
                id="hospital-name"
                value={
                  checkemail(emailUser) ? `${t("not_verfiy_email")}` : emailUser
                }
                required
              />
            </fieldset>
          </section>
          <Button
            variant="primary"
            className="save-btn"
            onClick={handleCloseInfo}
          >
            {t("close")}
          </Button>
          {/* </Form> */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InfoUserModal;
