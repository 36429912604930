import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";

// import PatientCard from '../PatientCard/PatientCard';
import PatientCardWide from "../PatientCardWide/PatientCardWide";
import "./Tabs.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

export default function TabsDoctor(props) {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    typography: {
      fontFamily: [
        "Almarai",
        "Nunito",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });

  return (
    <div className="tabs-cont">
      <ThemeProvider theme={theme}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="basic tabs example"
            >
              <Tab
                value={0}
                className="hor-tab"
                label={t("new")}
                sx={{ width: "33%", fontSize: "17px" }}
              />
              <Tab
                value={1}
                className="hor-tab"
                label={t("next")}
                sx={{ width: "33%", fontSize: "17px" }}
              />
              <Tab
                value={2}
                className="hor-tab"
                label={t("finished")}
                sx={{ width: "33%", fontSize: "17px" }}
              />

              {/* <Tab className='hor-tab' label={t("available")} sx={{ width: '25%', fontSize: "20px" }} {...a11yProps(3)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="cards-cont">
              {props?.appointments?.pending_hospital_appointments?.map(
                (appo) => (
                  <PatientCardWide
                    key={appo.id}
                    appointmentId={appo.id}
                    firstName={appo?.user?.first_name}
                    lastName={appo?.user?.last_name}
                    phone={appo.user.phone}
                    email={appo.user.email}
                    departmentEn={
                      appo?.hospital_department?.medical_specialty.name
                    }
                    departmentAr={
                      appo.hospital_department.medical_specialty.name_ar
                    }
                    departmentTr={
                      appo.hospital_department.medical_specialty.name
                    }
                    date={appo.date}
                    time={appo.time}
                    doctorfirstName={appo.hospital_doctor?.doctor?.first_name}
                    doctorlastName={appo.hospital_doctor?.doctor?.last_name}
                    day={appo.date_time_stamp}
                    appo={appo}
                  />
                )
              )}
              {/* the next line before editing */}
              {/* .map(appo => <PatientCard key={appo.id} appointmentId={appo.id} firstName={appo.user.first_name} lastName={appo.user.last_name} */}
              {/* doctor={"غير محدد"} department={appo.hospital_department.medical_specialty.name_ar} />)} */}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="cards-cont">
              {props?.appointments?.approved_hospital_appointments?.map(
                (appo) => (
                  <PatientCardWide
                    key={appo.id}
                    accepted={true}
                    appointmentId={appo.id}
                    firstName={appo.user.first_name}
                    lastName={appo.user.last_name}
                    phone={appo.user.phone}
                    email={appo.user.email}
                    departmentEn={
                      appo.hospital_department.medical_specialty.name
                    }
                    departmentAr={
                      appo.hospital_department.medical_specialty.name_ar
                    }
                    departmentTr={
                      appo.hospital_department.medical_specialty.name
                    }
                    date={appo.date}
                    time={appo.time}
                    doctorfirstName={appo.hospital_doctor?.doctor?.first_name}
                    doctorlastName={appo.hospital_doctor?.doctor?.last_name}
                    day={appo.date_time_stamp}
                  />
                )
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="cards-cont">
              {props?.appointments?.finished_hospital_appointments?.map(
                (appo) => (
                  <PatientCardWide
                    key={appo.id}
                    rejected={true}
                    appointmentId={appo.id}
                    firstName={appo.user.first_name}
                    lastName={appo.user.last_name}
                    phone={appo.user.phone}
                    email={appo.user.email}
                    department={
                      appo.hospital_department.medical_specialty.name_ar
                    }
                    date={appo.date}
                    time={appo.time}
                    doctorfirstName={appo.hospital_doctor?.doctor?.first_name}
                    doctorlastName={appo.hospital_doctor?.doctor?.last_name}
                    day={appo.date_time_stamp}
                  />
                )
              )}
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            Item Four
          </TabPanel> */}
        </Box>
      </ThemeProvider>
    </div>
  );
}
