/* eslint-disable no-unused-vars */

import React, { useEffect, useState,useContext } from "react";
import { useTranslation } from "react-i18next";

import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import hospitalController from "../../controller/HospitalController"
import { HospitalDataContext } from "../../../../store/data-context";
import L from "leaflet";
import { ToastContainer } from "react-toastify";
import notifications from "../../../../Components/toastify/notifications";


const styleMap={ height: "50vh"}
const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});

export default function Map({show}) {
  const { t } = useTranslation();
  // const { position, setPosition } = useContext(HospitalDataContext);

  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);
    // console.log( position,"1");
    const map = useMap();
    // console.log({ map });
    useEffect(() => {
      map.locate().on("locationfound", function (e) {
        // console.log({ locationfoundEvernt: e });
        // setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
        const radius = e.accuracy;
        const circle = L.circle(e.latlng, radius);
        // circle.addTo(map);
        setBbox(e.bounds.toBBoxString().split(","));
      });
    }, [map]);
    useEffect(() => {
      map.on("click", function (e) {
        // console.log({ clickEvernt: e });
        // alert("Lat, Lon : " + e.latlng.lat + ", " + e.latlng.lng);
        setPosition(e.latlng);
      });
    }, [map]);
    useEffect(()=>{
    
      const loc= hospitalController.handleLocation(position,notifications,t,show)
    },[position])
  
    return position === null ? null : (
      <Marker position={position} icon={icon} draggable={true} animate={true}>
        {/* <Popup> */}
          {/* You are here. <br /> */}
          {/* Map bbox: <br /> */}
          {/* <b>position</b>: <br /> */}
          {/* <b>lat</b>: {position.lat} <br /> */}
          {/* <b>lng</b>: {position.lng} <br /> */}

          {/* <b>Southwest lng</b>: {bbox[0]} <br />
          <b>Southwest lat</b>: {bbox[1]} <br />
          <b>Northeast lng</b>: {bbox[2]} <br />
        <b>Northeast lat</b>: {bbox[3]} */}
        {/* </Popup> */}
      </Marker>
    );
  }

  return(
  <>
  <MapContainer
    center={[49.1951, 16.6068]}
    zoom={13}
    scrollWheelZoom
    style={styleMap}
  >
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <LocationMarker />
  </MapContainer>
  <ToastContainer/>
  </>
  );
}






















// import React, { useEffect, useState } from "react";
// import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// import "./style.css"
// import LocationMarker from "./mar"

// const styleMap={ height: "50vh" ,marginTop:"15px",marginRight:"55px"}
// export default function Map() {


 

//   return (
//     // <>
//     <MapContainer
//       center={[49.1951, 16.6068]}
//       zoom={13}
//       scrollWheelZoom
//       style={styleMap}
//     >
//       <TileLayer
//         attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//       />
//       <LocationMarker  />
//       {/* <Marker position={position} icon={icon} draggable={true} animate={true}>
//         <Popup>
//           You are here. <br />
//           Map bbox: <br />
//           <b>position</b>: <br />
//           <b>lat</b>: {position.lat} <br />
//           <b>lng</b>: {position.lng} <br />
//         <button  className="location-btn" onClick={handleLocation}>Set My location</button>
//         </Popup>

//       </Marker> */}
//     </MapContainer>
    
   
//   );
// }