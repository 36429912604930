/* eslint-disable no-unused-vars */
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import BranchFormModal from "../BranchFormModal/BranchFormModal";
import loader from "../../../../Components/loader.gif";

import "./HospitalBranch.css";

const HospitalBranch = () => {
  const { t } = useTranslation();
  const { data, countriesData } = useContext(HospitalDataContext);
  // const branchData = data?.hospital[0]?.hospital_branch;
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="branch-cont">
      <BranchFormModal
        data={data}
        countries={countriesData}
        setOpenModal={setOpenModal}
      />

      {data.length === 0 ? (
        <img src={loader} alt="" />
      ) : (
        <table>
          <thead>
            <tr>
              <th className="tr-headings">{t("number")}</th>
              <th className="tr-headings">{t("branch_name")}</th>
              <th className="tr-headings">{t("city")}</th>
              <th className="tr-headings">{t("address")}</th>

              {/* <th>تعديل الفرع</th> */}
            </tr>
          </thead>
          <tbody>
            {data?.hospital[0]?.hospital_branch.map(
              ({ city, id, name, street_name }) => (
                <tr key={id}>
                  <td className="num-row">
                    <input
                      className="table-td"
                      name="position"
                      defaultValue={id}
                      type="text"
                      placeholder="Type Position"
                    />
                  </td>
                  <td>
                    <input
                      className="table-td"
                      name="name"
                      defaultValue={name}
                      type="text"
                      placeholder="Type Name"
                    />
                  </td>
                  <td className="table-td">
                    <input
                      className="table-td"
                      name="city"
                      defaultValue={city.name_ar}
                      type="text"
                      placeholder="Type city"
                    />
                  </td>
                  <td className="table-td">
                    <input
                      className="table-td"
                      name="street"
                      defaultValue={street_name}
                      type="text"
                      placeholder="Type street"
                    />
                  </td>
                  {/* <td className='table-td'>
                <input className='table-td' name="belediye" type="text" value={province_id} placeholder="Type belediye" />
              </td> */}
                  {/* <td>
                <button className='edit-btn'>تعديل</button>
              </td> */}
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default HospitalBranch;
