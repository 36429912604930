/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useRef } from "react";
import VerificationInput from "react-verification-input";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Phone from "../../../../assets/otp.svg";
import "./CodeVerficationModal.css";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import adminController from "../../controller/AdminController";
import notifications from "../../../../Components/toastify/notifications";
function CodeVerficationModal({
  code,
  setCode,
  handleCloseCode,
  show,
  setShow,
  handleClose,
  number,
}) {
  const { admin, data } = useContext(HospitalDataContext);
  const { t, i18n } = useTranslation();
  // const [number, setNumber] = useState(data.hospital[0].phone2);
  const [codeNumber, setCodeNumber] = useState();
  const changeNumber = () => {
    setCode(false);
    setShow(true);
  };
  // const confirmationCode = (codeNumber) => {

  // };
  const countryKey = () => {
    if (data?.hospital[0]?.country_id === 1) {
      return 90;
    } else if (data?.hospital[0]?.country_id === 2) {
      return 974;
    }
  };
  return (
    <>
      <Modal show={code} onHide={handleCloseCode} centered>
        <img src={Phone} alt="phone" className="phone" />
        <article className="container-code-phone">
          <Form.Label className="activate-note">
            <b>{t("activate_note")}</b>
          </Form.Label>
          <span>{t("code_note")}</span>
          <span>
            {" "}
            {i18n.language === "ar" ? (
              <span>
                {number} {countryKey()} +
              </span>
            ) : (
              <span>
                +{countryKey()} {number}
              </span>
            )}{" "}
          </span>
        </article>
        <Modal.Body>
          <Form
            onSubmit={(e) =>
              adminController.handleCodeVerfication(
                e,
                notifications,
                handleCloseCode,
                t
              )
            }
          >
            <article className="inpu-code-verfy">
              {" "}
              <VerificationInput
                onChange={(e) => setCodeNumber(e)}
                length={6}
              />
            </article>

            <button
              onClick={() => adminController.code()}
              className="change-number-btn"
            >
              {t("change_number")}
            </button>
            <Button
              variant="primary"
              type="submit"
              className="save-btn"
              onClick={() =>
                adminController.confirmationCode(
                  codeNumber,
                  handleClose,
                  handleCloseCode,
                  notifications,
                  t
                )
              }
            >
              {t("sure")}
            </Button>
          </Form>
          <article className="resend-code">
            <span> {t("not_recive")}</span>
            <button onClick={changeNumber} className="resend-btn">
              {t("resend_code")}
            </button>
          </article>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CodeVerficationModal;
