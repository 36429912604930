import NavBar from "../../../Components/Nav/NavComponent";
import HcpProfile from "../components/HcpProfile/HcpProfile";

const HcpProfilePage = () => {
  return (
    <>
      <NavBar />
      <HcpProfile />
    </>
  );
};

export default HcpProfilePage;
