import authServices from "../services/AuthServices";

class AuthController {
  async handleLogin(
    e,
    setLoading,
    emailRef,
    passwordRef,
    navigate,
    authCtx,
    notifications,
    t,
    setShow
  ) {
    e.preventDefault();
    setLoading(true);
    // console.warn(email, password);
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    //validate => all values good to go (send request to api) or show error(seperate function)=======function

    const data = { email, password };
    // const token = localStorage.getItem("token");
    // const data = { enteredEmail, enteredPassword };
    // const res = await authController.handleLogin(data);
    // console.log(res, "test");
    // fetch("https://app.sehapracto.net/hospitaladminapi/login", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     email: enteredEmail,
    //     password: enteredPassword,
    //     // returnSecureToken: true,
    //   }),
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // })
    const response = await authServices.login(data);
    if (response.message === "success") {
      notifications.notifySuccess(t("successfuly_login"));
      authCtx.login(response);
      setLoading(false);
      setShow(true);
      navigate("/");
      // window.location.reload(false);
    } else {
      if (response.message === "fail" && response.error === "user not found") {
        notifications.notifyError(t("user_not_found"));
        setLoading(true);
      } else if (
        response.message === "fail" &&
        response.error === "wrong password"
      ) {
        notifications.notifyError(t("wrong_password"));
        setLoading(true);
      }
    }
    setLoading(false);

    // .then((res) => {
    //   // setIsLoading(false);
    //   if (res.ok) {
    //     return res.json();
    //   } else {
    //     return res.json().then((data) => {
    //       setLoading(false);
    //       let errorMessage = "Authentication failed!";
    //       // if (data && data.error && data.error.message) {
    //       //   errorMessage = data.error.message;
    //       // }

    //       throw new Error(errorMessage);
    //     });
    //   }
    // })
    // .then((data) => {
    //   if (data.error) {
    //     setError(data.error);
    //     setLoading(false);
    //     return;
    //   }

    //   // new code about to be implemented
    //   // const expirationTime = new Date(
    //   //   new Date().getTime() + +data.expiresIn * 1000
    //   // );
    //   authCtx.login(data);
    //   setLoading(false);

    //   // authCtx.isLoggedIn = true;
    //   // console.log({ dataToHaveToken: data });
    //   // could implement a function for changing user state to logged in

    //   // navigate("/");
    //   // window.location.reload(false);
    // })
    // .catch((err) => {
    //   console.log({ err });
    //   setLoading(false);
    //   alert(err.message);
    // });
  }
  async handleRegister(
    e,
    setLoading,
    firstNameInputRef,
    lastNameInputRef,
    emailInputRef,
    passwordInputRef,
    phoneInputRef,
    navigate,
    notifications,
    t
  ) {
    e.preventDefault();
    setLoading(true);
    const first_name = firstNameInputRef.current.value;
    const last_name = lastNameInputRef.current.value;
    const email = emailInputRef.current.value;
    const password = passwordInputRef.current.value;
    const phone = phoneInputRef.current.value;
    // const enteredHospitalName = passwordInputRef.current.value;
    // const enteredHospitalPhone = passwordInputRef.current.value;
    const data = {
      first_name,
      last_name,
      email,
      phone,
      password,
      country_id: 1,
      returnSecureToken: true,
    };
    // optional: Add validation
    const response = await authServices.register(data);
    if (response.message === "success") {
      notifications.notifySuccess(t("Successfuly Login"));
      // authCtx.login(data.token);
      // setAccountCreated(true);
      setLoading(false);

      navigate("/login");
    } else {
      if (
        response.message === "fail" &&
        response.error === "admin not created"
      ) {
        notifications.notifyError(t("user_found"));
        setLoading(true);
      }
    }
    setLoading(false);

    // fetch("https://app.sehapracto.net/hospitaladminapi/register", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     first_name: enteredFirstName,
    //     last_name: enteredLastName,
    //     email: enteredEmail,
    //     phone: enteredPhone,
    //     password: enteredPassword,
    //     country_id: 1,
    //     // returnSecureToken: true
    //   }),
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // })
    //   .then((res) => {
    //     // setIsLoading(false);
    //     if (res.ok) {
    //       return res.json();
    //     } else {
    //       return res.json().then((data) => {
    //         let errorMessage = "Authentication failed!!!";
    //         // if (data && data.error && data.error.message) {
    //         //   errorMessage = data.error.message;
    //         // }
    //         setIsLoading(false);

    //         throw new Error(errorMessage);
    //       });
    //     }

    //     // should delete next line
    //     // return res.json();
    //   })
    //   .then((data) => {
    //     setIsLoading(false);
    //     // the next line may not be needed because there isn't token returned here
    //     // authCtx.login(data.token);
    //     // setAccountCreated(true);
    //     navigate("/login");
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     alert(err.message);
    //   });
  }
  handleOld(e, setOldPassword) {
    setOldPassword(e.target.value);
  }

  handleNew(e, setNewPassword) {
    setNewPassword(e.target.value);
  }

  async handleChangePassword(
    e,
    oldPassword,
    newPassword,
    notifications,
    setOldPassword,
    setNewPassword,
    t
  ) {
    e.preventDefault();

    try {
      const response = await authServices.changePassword(
        oldPassword,
        newPassword
      );
      if (response.message === "success") {
        notifications.notifySuccess(t("done_reset_password"));
      } else {
        notifications.notifyError(response.error);
      }
    } catch (error) {
      console.log(error);
    }
    setOldPassword("");
    setNewPassword("");
  }
  handleRecoveryEmail(e, setRecoveryEmail) {
    setRecoveryEmail(e.target.value);
  }

  async handlePasswordRecovery(e, recoveryEmail, setIsDone, setRecoveryEmail) {
    e.preventDefault();
    // LOGIC GOES HERE FOR PASSWORD RESET
    try {
      const response = await authServices.recoverPassword(recoveryEmail);
      if (response.message === "success") {
        setIsDone(response.info);
      } else {
        setIsDone(response.error);
      }
    } catch (error) {}

    setRecoveryEmail("");
  }
}
const authController = new AuthController();

export default authController;
