import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import authController from "../../controller/AuthController";
import NavComponent from "../../../../Components/Nav/NavComponent";
// import axiosProvider from "../../../../api/base";
import notifications from "../../../../Components/toastify/notifications";
import "./ResetPassword.css";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  // const handleOld = (e) => {
  //   setOldPassword(e.target.value);
  // };

  // const handleNew = (e) => {
  //   setNewPassword(e.target.value);
  // };

  // const handleEmailForm = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await axiosProvider.postRequest("/change-password", {
  //       password: oldPassword,
  //       new_password: newPassword,
  //     });
  //     if (response.message === "success") {
  //       notifications.notifySuccess(t("done_reset_password"));
  //     } else {
  //       notifications.notifyError(response.error);
  //     }
  //   } catch (error) {}
  //   setOldPassword("");
  //   setNewPassword("");
  // };

  return (
    <div>
      <NavComponent />
      <div className="reset-container">
        <form
          id="contact5"
          action="#"
          method="post"
          onSubmit={(e) =>
            authController.handleChangePassword(
              e,
              oldPassword,
              newPassword,
              notifications,
              setOldPassword,
              setNewPassword,
              t
            )
          }
        >
          <h3>{t("resetting_your_password")}</h3>
          <fieldset>
            <label htmlFor="password" className="recovery-mail">
              كلمة المرور القديمة
            </label>
            <input
              type="password"
              tabIndex="1"
              id="password"
              value={oldPassword}
              onChange={(e) => authController.handleOld(e, setOldPassword)}
              required
              autoFocus
            />
          </fieldset>
          <fieldset>
            <label htmlFor="password" className="recovery-mail">
              كلمة المرور الجديدة
            </label>
            <input
              type="password"
              tabIndex="2"
              id="repeated-password"
              value={newPassword}
              onChange={(e) => authController.handleNew(e, setNewPassword)}
              required
            />
          </fieldset>
          <ToastContainer />
          <fieldset>
            <button
              name="submit"
              type="submit"
              id="contact-submit"
              data-submit="...Sending"
            >
              {t("save")}
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
