/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./EditAppointmentModal.css";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import appointmentController from "../../controller/AppointmentController";
import notifications from "../../../../Components/toastify/notifications";

function EditAppointmentModal({ handleClose, show, id }) {
  const { t } = useTranslation();
  const [doctorId, setDoctorId] = useState();
  const [dates, setDate] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [nameDay, setNameDay] = useState("");
  const { Doctors, weekCurrent } = useContext(HospitalDataContext);
  const [WorkSchedule, setWorkSchedule] = useState("");
  const [timeId, setimeId] = useState("");
  const ToDay = new Date().toISOString().split("T")[0];

  const getDate = (e) => {
    setDate(e.target.value);
    const d = new Date(e.target.value);
    let day1 = d.getDay();
    const nnnnnn = weekCurrent[day1]?.value;
    setNameDay(nnnnnn);

    appointmentController.handleGetDay(
      doctorId,
      weekCurrent[day1]?.value,
      setWorkSchedule
    );
  };

  // const [message, setMessage] = useState("");
  // const handleChange = (event) => {
  //   // 👇 Get input value from "event"
  //   setMessage(event.target.value);
  //   const d = new Date(event.target.value);
  //   let day1 = d.getDay();
  //   const nnnnnn = weekCurrent[day1]?.value;
  //   setNameDay(nnnnnn);
  // };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="title-model-clinic-appointment">
            {t("edit_appointment_pateint")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={(e) =>
              appointmentController.handleEditApppointment(
                e,
                id,
                dates,
                timeId,
                doctorId,
                handleClose,
                notifications,
                t
              )
            }
          >
            {t("choose_doctor")}
            <select
              className="select-times"
              name="hospital-country"
              id="hospital-address1"
              value={doctorId}
              onChange={(e) => setDoctorId(e.target.value)}
            >
              <option value="invalid">-- {t("choose_doctor")} --</option>
              {Doctors?.hospitalDepartments?.map((depart) => {
                return depart?.hospital_doctor.map((doctor) => {
                  return (
                    <option key={doctor.doctor.id} value={doctor.id}>
                      {doctor.doctor.title +
                        " " +
                        doctor.doctor.first_name +
                        " " +
                        doctor.doctor.last_name}
                    </option>
                  );
                });
              })}
            </select>{" "}
            <div className="date-continer">
              <span>{t("chose_date")}</span>
              <input type="date" min={ToDay} onChange={getDate} />
            </div>
            <div className="doctor-time">
              {WorkSchedule &&
                WorkSchedule?.map((time) => {
                  return (
                    <article key={time.id}>
                      <input
                        type="radio"
                        id="age1"
                        name="age"
                        value={time.id}
                        onClick={(e) => setimeId(e.target.value)}
                      />
                      <label for="age1">{time.start_time}</label>
                    </article>
                  );
                })}
            </div>
            <Button variant="primary" type="submit" className="save-btn">
              {t("save")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* end  doctor*/}
    </>
  );
}

export default EditAppointmentModal;
