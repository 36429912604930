import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notifications from "../../../../Components/toastify/notifications";
import { ToastContainer } from "react-toastify";
import authController from "../../controller/AuthController";
import Spinner from "../../../../Components/Spinner";
import ImageRegister from "../../../../assets/hospital-register.png";
import "./RegisterForm.css";
// import AuthContext from '../../../store/auth-context';

const RegisterForm = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const phoneInputRef = useRef();
  // const hospitalNameInputRef = useRef();
  // const hospitalPhoneInputRef = useRef();

  // const authCtx = useContext(AuthContext);

  // const submitHandler = (event) => {
  //   event.preventDefault();
  //   setIsLoading(true);

  //   const enteredFirstName = firstNameInputRef.current.value;
  //   const enteredLastName = lastNameInputRef.current.value;
  //   const enteredEmail = emailInputRef.current.value;
  //   const enteredPassword = passwordInputRef.current.value;
  //   const enteredPhone = phoneInputRef.current.value;
  //   // const enteredHospitalName = passwordInputRef.current.value;
  //   // const enteredHospitalPhone = passwordInputRef.current.value;

  //   // optional: Add validation
  //   fetch("https://app.sehapracto.net/hospitaladminapi/register", {
  //     method: "POST",
  //     body: JSON.stringify({
  //       first_name: enteredFirstName,
  //       last_name: enteredLastName,
  //       email: enteredEmail,
  //       phone: enteredPhone,
  //       password: enteredPassword,
  //       country_id: 1,
  //       // returnSecureToken: true
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((res) => {
  //       // setIsLoading(false);
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         return res.json().then((data) => {
  //           let errorMessage = "Authentication failed!!!";
  //           // if (data && data.error && data.error.message) {
  //           //   errorMessage = data.error.message;
  //           // }
  //           setIsLoading(false);

  //           throw new Error(errorMessage);
  //         });
  //       }

  //       // should delete next line
  //       // return res.json();
  //     })
  //     .then((data) => {
  //       setIsLoading(false);
  //       // the next line may not be needed because there isn't token returned here
  //       // authCtx.login(data.token);
  // setAccountCreated(true);
  //       navigate("/login");
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       alert(err.message);
  //     });
  // };

  return (
    <div className="register-form1">
      <form
        id="contact3"
        onSubmit={(e) =>
          authController.handleRegister(
            e,
            setLoading,
            firstNameInputRef,
            lastNameInputRef,
            emailInputRef,
            passwordInputRef,
            phoneInputRef,
            navigate,
            notifications,
            t
          )
        }
      >
        <h3>{t("hospital_create_account")}</h3>
        <fieldset>
          <label htmlFor="first-name">{t("first_name_label")}</label>
          <input
            type="text"
            tabIndex="1"
            id="first-name"
            placeholder={t("first_name_label")}
            name="registered-first-name"
            ref={firstNameInputRef}
            required
          />
        </fieldset>
        <fieldset>
          <label htmlFor="last-name">{t("last_name_label")}</label>
          <input
            type="text"
            tabIndex="2"
            id="last-name"
            placeholder={t("last_name_label")}
            name="registered-last-name"
            ref={lastNameInputRef}
            required
          />
        </fieldset>
        <fieldset>
          <label htmlFor="phone">{t("phone_number_label")}</label>
          <input
            type="tel"
            tabIndex="3"
            id="phone"
            placeholder={t("phone_number_label")}
            name="registered-phone"
            ref={phoneInputRef}
            required
          />
        </fieldset>
        <fieldset>
          <label htmlFor="email">{t("email_label")}</label>
          <input
            type="email"
            tabIndex="4"
            id="email"
            placeholder={t("email_label")}
            name="registered-email"
            ref={emailInputRef}
            required
          />
        </fieldset>
        {/* <fieldset>
                    <label htmlFor="hospital-name">{t("hospital_name")}</label>
                    <input type="text" tabIndex="4" id='hospital-name' placeholder={t("hospital_name")} name='registered-hospital-name' ref={hospitalNameInputRef} />
                </fieldset>
                <fieldset>
                    <label htmlFor="hospital-phone">{t("hospital_number")}</label>
                    <input type="tel" tabIndex="5" id='hospital-phone' placeholder={t("hospital_number")} name='registered-hospital-phone' ref={hospitalPhoneInputRef} />
                </fieldset> */}
        <fieldset>
          <label htmlFor="password">{t("password_label")}</label>
          <input
            type="password"
            tabIndex="5"
            id="password"
            placeholder={t("password_label")}
            name="registered-password"
            ref={passwordInputRef}
            minLength="8"
            required
          />
        </fieldset>
        <p className="agreeing">
          {t("by_signing")}{" "}
          <a href="https://www.sehapracto.com/privacy-policy/">
            {t("terms_and_conditions")}
          </a>
        </p>
        <fieldset>
          <button
            name="submit"
            type="submit"
            id="contact-submit"
            className="newaccount1"
            data-submit="...Sending"
          >
            {isLoading ? <Spinner /> : t("create_new_account")}
          </button>
        </fieldset>
        <ToastContainer />
        {/* {accountCreated && <p className='created-successfully'>لقد انشأت حساب بنجاح</p>} */}
        {/* <fieldset>
          <div className='or'>{t("or")}</div>
        </fieldset> */}
        {/* <fieldset className='social-buttons'>
                    <button type='button' className='google-btn'><a href="#">{t("login_google")}</a></button>
                    <button type='button'><a href="#">{t("login_facebook")}</a></button>
                </fieldset> */}
        <fieldset className="noaccount">
          <p className="registered-already">
            {t("already_registered")} <a href="/login">{t("login")}</a>
          </p>
        </fieldset>
      </form>
      {/* <div className="imgall1"> */}
      <img src={ImageRegister} alt="img-register" />
      {/* </div> */}
    </div>
  );
};

export default RegisterForm;
