import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import notifications from "../../../../Components/toastify/notifications";
import { MdClose } from "react-icons/md";
import hospitalController from "../../controller/HospitalController";
import { HospitalDataContext } from "../../../../store/data-context";
import loader from "../../../../Components/loader.gif";
import "./HospitalGallery.css";

const HospitalGallery = (props) => {
  const { t } = useTranslation();
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const { data } = useContext(HospitalDataContext);

  const navigate = useNavigate();

  // const openImg = (imgSrc) => {
  //   setTempImgSrc(imgSrc);
  //   setModel(true);
  // };

  if (!data) {
    return <img src={loader} alt="" />;
  } else {
    return (
      <div className="div-cont1">
        <form className="gallery-cont">
          <h3>{t("hospital_gallery_h3")}</h3>
          <div className="gallery-blk">
            <button
              onClick={() => navigate("/gallery")}
              className="gallery-btn"
            >
              {t("add_to_gallery")}
            </button>
          </div>
        </form>

        <div className="images-cont">
          <div className={model ? "model open" : "model"}>
            <img src={tempImgSrc} alt="hospital-gallary" />
            <MdClose className="ex" onClick={() => setModel(false)} />
          </div>
          {data.length === 0 ? (
            <img src={loader} alt="" />
          ) : (
            data?.hospital[0]?.hospital_img.map((i) => (
              <section className="image-section">
                <button
                  className="delete-btn"
                  onClick={() =>
                    hospitalController.removeImage(i?.id, notifications, t)
                  }
                >
                  X
                </button>
                <img
                  src={i?.img}
                  key={i?.id}
                  alt=""
                  onClick={() =>
                    hospitalController.openImg(i?.img, setTempImgSrc, setModel)
                  }
                />
              </section>
            ))
          )}
        </div>
        <ToastContainer />
      </div>
    );
  }
};

export default HospitalGallery;
