import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import appointmentController from "../../controller/AppointmentController";
import { RiStethoscopeFill } from "react-icons/ri";
import { BsCalendarDateFill } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { IoPersonOutline } from "react-icons/io5";
import ReservationDetailsHcp from "../ReservationDetailsHcp/ReservationDetails";
import EditHcpAppointmentModal from "../EditHcpAppointmentModal/EditHcpAppointmentModal";
import "./PatientCardWide.css";
import InfoUserModal from "../InfoUserModal/InfoUserModal";

// Plugins

const PatientCardWideHcp = (props) => {
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { weekday } = useContext(HospitalDataContext);
  const [showHcpEdit, setShowHcpEdit] = useState(false);
  const handleCloseHcpEdit = () => setShowHcpEdit(!showHcpEdit);
  const handleShowHcpEdit = () => setShowHcpEdit(true);
  const handleAcceptance = () => setIsExpanded(true);
  const handleRejection = () => setIsExpanded(true);
  const [showInfo, setShowInfo] = useState(false);
  const handleCloseInfo = () => setShowInfo(!showInfo);
  const handleShowInfo = () => setShowInfo(true);

  // const dayName = (dateDay) => {
  //   // console.log(dateDay);
  //   const date = new Date(dateDay?.slice(0, 10));
  //   const numberDay = date.getDay();
  //   // console.log(date);
  //   console.log(numberDay);
  //   return weekday[numberDay];
  // };
  if (isExpanded) {
    return (
      <ReservationDetailsHcp
        firstName={props.firstName}
        lastName={props.lastName}
        department={props.department}
        date={props.date}
        accepted={props.accepted}
        finished={props.finished}
        id={props.appointmentId}
        day={props.day}
        time={props.time}
      />
    );
  } else {
    return (
      <div className="pwide-cont">
        {/* <div className='growing'>
                <div className='phead'><p><RiStethoscopeFill className='title-icon' />نوع الكشف</p></div>
                <div className='pbody'><p>كشف بالعيادة</p></div>
            </div> */}
        <div className="growing">
          <div className="phead">
            <p>
              <RiStethoscopeFill className="title-icon" />
              {t("department")}
            </p>
          </div>
          <div className="pbody">
            <p>
              {i18n.language === "ar" ? props.departmentAr : props.departmentEn}
            </p>
          </div>
        </div>
        <div className="growing">
          <div className="phead">
            <p>
              <IoPersonOutline className="title-icon" />
              {t("hcp_name")}
            </p>
          </div>
          <div className="pbody">
            <p>{t("general")}</p>
          </div>
        </div>
        <div className="growing">
          <div className="phead">
            <p>
              <BsCalendarDateFill className="title-icon" />
              {t("reservation_date")}{" "}
            </p>
          </div>
          <div className="pbody">
            <p>
              {props.date} {appointmentController.dayName(props.day, weekday)}
            </p>
          </div>
        </div>
        <div className="growing">
          <div className="phead">
            <p>
              <BiTime className="title-icon" />
              {t("reservation_time")}{" "}
            </p>
          </div>
          <div className="pbody">
            <p>{props.time}</p>
          </div>
        </div>
        <div className="growing">
          <div className="phead">
            <p>
              <IoPersonOutline className="title-icon" />
              {t("patient_name")}
            </p>
          </div>
          <div className="pbody">
            <p>{props.firstName + " " + props.lastName}</p>
          </div>
        </div>
        {/* <div className='growing'>
        <div className='phead'><p><AiOutlinePhone className="title-icon" />{t("phone_number_label")}</p></div>
        <div className='pbody'><p>05437467414</p></div>
      </div> */}
        <div className="growing">
          <div className="phead">
            <p>{t("details")}</p>
          </div>
          <div className="pbody card-btns">
            <button
              onClick={handleShowHcpEdit}
              id={props.appointmentId}
              className="first-btn"
            >
              {t("edit")}
            </button>
            <button
              onClick={handleShowInfo}
              id={props.appointmentId}
              className="first-btn"
            >
              {t("user_Info")}
            </button>
            {props.accepted && (
              <div className="action-yes">{t("reservation_done")}</div>
            )}
            {props.rejected && (
              <div className="action-no">{t("reservation_finished")}</div>
            )}
            {!props.accepted && !props.rejected && (
              <button
                onClick={handleAcceptance}
                id={props.appointmentId}
                className="first-btn"
              >
                {t("accept")}
              </button>
            )}
            {!props.accepted && !props.rejected && (
              <button
                onClick={handleRejection}
                id={props.appointmentId}
                className="second-btn"
              >
                {t("reject")}
              </button>
            )}
          </div>
          <EditHcpAppointmentModal
            handleCloseHcp={handleCloseHcpEdit}
            showHcp={showHcpEdit}
            id={props.appointmentId}
          />
          <InfoUserModal
            handleCloseInfo={handleCloseInfo}
            showInfo={showInfo}
            phone={props.phone}
            emailUser={props.email}
          />
        </div>

        {/* This was the old implemented card */}
        {/* <div className='phead'>
                <div><p>نوع الكشف</p></div>
                <div><p>موعد الحجز</p></div>
                <div><p>اسم المريض</p></div>
                <div><p>رقم الهاتف</p></div>
                <div className='details'><p>تفاصيل</p></div>
            </div>
            <div className='pbody'>
                <div><p>كشف بالعيادة</p></div>
                <div><p>الخميس 25 مارس 2022</p></div>
                <div><p>ليلاس الشعراني</p></div>
                <div><p>05437467414</p></div>
                <div className='details'><p>تم الحجز</p></div>
            </div> */}
        {/* {isAdded && (
        <AddedSuccessfullyModal changeCancel={() => setIsAdded()} text={t("appointment_accepted")} />
      )} */}
        {/* {isRemoved && (
        <AddedSuccessfullyModal changeCancel={() => setIsRemoved()} text={t("appointment_rejected")} />
      )} */}
      </div>
    );
  }
};

export default PatientCardWideHcp;
