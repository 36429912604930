// this is adding and removing doctor side tab AKA doctors side tab component
// this has the same styles as the MedicalSections component

import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { HospitalDataContext } from "../../../store/data-context";
// import doctorServices from "../services/DoctorServices";
import doctorController from "../controller/DoctorController";
import Spinner from "../../../Components/Spinner";
import notifications from "../../../Components/toastify/notifications";
import loader from "../../../Components/loader.gif";
import "./Doctors.css";

const Doctors = () => {
  const { data } = useContext(HospitalDataContext);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  if (data.length === 0) {
    return <img className="loader" src={loader} alt="" />;
  } else {
    return (
      <div className="schedule-cont backgound">
        <h3 className="doctors-h3">{t("doctors")}</h3>
        <div className="tab-switch">
          <div className="mytabs">
            {/* phone */}
            <input
              type="radio"
              id="tabfree"
              name="mytabs"
              defaultChecked="checked"
            />
            <label className="tab-label" htmlFor="tabfree">
              {t("add_doctor_by_phone")}
            </label>
            <div className="tab">
              <form
                // className="add-remove-all"
                onSubmit={(e) =>
                  doctorController.handleAddDoctor(
                    e,
                    setIsLoading,
                    phone,
                    selectedOption,
                    notifications,
                    t
                  )
                }
              >
                <div className="s-all1">
                  <div className="">
                    <span className="label4selections">
                      {t("medical_specialties")}
                    </span>
                  </div>
                  <br />

                  <div className="s-tag1">
                    <span
                      htmlFor="hospital-address1"
                      className="selection-label"
                    >
                      {t("choose_medical_specialty")}
                    </span>
                    {document.body.dir === "rtl" ? (
                      <select
                        className="w-full "
                        onChange={(e) =>
                          doctorController.onSelectChange(e, setSelectedOption)
                        }
                      >
                        <option value="invalid">
                          {t("choose_department_from_here")}
                        </option>
                        {data?.hospital[0]?.hospital_department.map((i) => (
                          <option
                            key={i?.id}
                            id={i.id}
                            value={i?.medical_specialty.name_ar}
                          >
                            {i?.medical_specialty.name_ar}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        className="w-full "
                        onChange={(e) =>
                          doctorController.onSelectChange(e, setSelectedOption)
                        }
                      >
                        <option value="invalid">
                          {t("choose_department_from_here")}
                        </option>
                        {data?.hospital[0]?.hospital_department.map((i) => (
                          <option
                            key={i?.id}
                            id={i.id}
                            value={i?.medical_specialty.name}
                          >
                            {i?.medical_specialty.name}
                          </option>
                        ))}
                      </select>
                    )}
                    <input
                      className="my-4 p-3 w-2/4 hospital-number-input"
                      placeholder={t("phone_number_label_in_english")}
                      name="hospital-number"
                      type="text"
                      tabIndex="4"
                      id="hospital-number-input"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value.trim())}
                      required
                    />
                  </div>
                  <button
                    name="submit-admin-account"
                    type="submit"
                    id="contact-submit1"
                    data-submit="...Sending"
                  >
                    {isLoading ? <Spinner /> : t("add")}
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
            {/* Email */}
            <input type="radio" id="tabgold" name="mytabs" />
            <label className="tab-label" htmlFor="tabgold">
              {t("add_doctor_by_email")}
            </label>

            <div className="tab">
              <form
                // className="add-remove-all"
                onSubmit={(e) =>
                  doctorController.handleAddDoctorByEmail(
                    e,
                    setIsLoading,
                    email,
                    setEmail,
                    setSelectedOption,
                    selectedOption,
                    notifications,
                    t
                  )
                }
              >
                <div className="s-all1">
                  <div className="">
                    <span className="label4selections">
                      {t("medical_specialties")}
                    </span>
                  </div>
                  <br />

                  <div className="s-tag1">
                    <span
                      htmlFor="hospital-address1"
                      className="selection-label"
                    >
                      {t("choose_medical_specialty")}
                    </span>
                    {document.body.dir === "rtl" ? (
                      <select
                        className="w-full"
                        onChange={(e) =>
                          doctorController.onSelectChange(e, setSelectedOption)
                        }
                      >
                        <option value="invalid">
                          {t("choose_department_from_here")}
                        </option>
                        {data.hospital[0].hospital_department.map((i) => (
                          <option
                            key={i?.id}
                            id={i.id}
                            value={i?.medical_specialty.name_ar}
                          >
                            {i?.medical_specialty.name_ar}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        className="w-full "
                        onChange={(e) =>
                          doctorController.onSelectChange(e, setSelectedOption)
                        }
                      >
                        <option value="invalid">
                          {t("choose_department_from_here")}
                        </option>
                        {data.hospital[0].hospital_department.map((i) => (
                          <option
                            key={i?.id}
                            id={i.id}
                            value={i?.medical_specialty.name}
                          >
                            {i?.medical_specialty.name}
                          </option>
                        ))}
                      </select>
                    )}
                    <input
                      className="my-4 p-3 w-2/4 hospital-number-input"
                      placeholder={t("email_label_doctor")}
                      name="hospital-number"
                      type="email"
                      tabIndex="4"
                      id="hospital-number-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.trim())}
                      required
                    />
                  </div>
                  <button
                    name="submit-admin-account"
                    type="submit"
                    id="contact-submit1"
                    data-submit="...Sending"
                  >
                    {isLoading ? <Spinner /> : t("add")}
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
            {/* <input type="radio" id="tabselver" name="mytabs" disabled />
            <label className="tab-label" htmlFor="tabselver">
              {t("edit_remove_doctor_account")}
            </label>
            <div className="tab">
              <div className="department"></div>
            </div> */}
            <ToastContainer />
          </div>
        </div>
      </div>

      // <form
      //   className="add-remove-all"
      //   onSubmit={(e) =>
      //     doctorController.handleAddDoctor(
      //       e,
      //       setIsLoading,
      //       phone,
      //       selectedOption,
      //       notifications,
      //       t
      //     )
      //   }
      // >
      //   <div className="tab-switch">
      //     <h3 className="doctors-h3">{t("doctors")}</h3>
      //     <div className="mytabs">
      //       <input
      //         type="radio"
      //         id="tabfree"
      //         name="mytabs"
      //         defaultChecked="checked"
      //       />
      //       <label htmlFor="tabfree" className="tab-label">
      //         {t("add_doctor")}
      //       </label>

      //       <input type="radio" id="tabgold" name="mytabs" />

      //       <label htmlFor="tabgold" className="tab-label">
      //         {t("edit_remove_doctor_account")}
      //       </label>
      //     </div>

      //     <fieldset className="s-all1">
      //       <div className="s-label">
      //         <label className="label4selections">
      //           {t("medical_specialties")}
      //         </label>
      //       </div>
      //       <br />
      //       <div className="s-tag1 w-full ">
      //         <label htmlFor="hospital-address1" className="selection-label">
      //           {t("choose_medical_specialty")}
      //         </label>
      //         {document.body.dir === "rtl" ? (
      //           <select
      //             className="w-full "
      //             onChange={(e) =>
      //               doctorController.onSelectChange(e, setSelectedOption)
      //             }
      //           >
      //             <option value="invalid">
      //               {t("choose_department_from_here")}
      //             </option>
      //             {data.hospital[0].hospital_department.map((i) => (
      //               <option
      //                 key={i?.id}
      //                 id={i.id}
      //                 value={i?.medical_specialty.name_ar}
      //               >
      //                 {i?.medical_specialty.name_ar}
      //               </option>
      //             ))}
      //           </select>
      //         ) : (
      //           <select
      //             className="w-full "
      //             onChange={(e) =>
      //               doctorController.onSelectChange(e, setSelectedOption)
      //             }
      //           >
      //             <option value="invalid">
      //               {t("choose_department_from_here")}
      //             </option>
      //             {data.hospital[0].hospital_department.map((i) => (
      //               <option
      //                 key={i?.id}
      //                 id={i.id}
      //                 value={i?.medical_specialty.name}
      //               >
      //                 {i?.medical_specialty.name}
      //               </option>
      //             ))}
      //           </select>
      //         )}
      //         <input
      //           className="my-4 p-3 w-2/4 hospital-number-input"
      //           placeholder={t("phone_number_label_in_english")}
      //           name="hospital-number"
      //           type="text"
      //           tabIndex="4"
      //           id="hospital-number-input"
      //           value={phone}
      //           onChange={(e) => setPhone(e.target.value.trim())}
      //           required
      //         />
      //       </div>
      //       <ToastContainer />
      //       <button
      //         name="submit-admin-account"
      //         type="submit"
      //         id="contact-submit1"
      //         data-submit="...Sending"
      //       >
      //         {isLoading ? <Spinner /> : t("add")}
      //       </button>
      //     </fieldset>
      //   </div>
      // </form>
    );
  }
};

export default Doctors;
