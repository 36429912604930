import axiosProvider from "../../../api/base";

class SectionsServices {
  // get-all-medical-specialties
  async get_all_medical_specialties() {
    const response = await axiosProvider.getRequest(
      "/get-all-medical-specialties"
    );
    return response;
  }

  // Add department to hospital
  async Add_department(id) {
    try {
      const response = await axiosProvider.postRequest("/add-department", {
        medical_specialty_id: id,
      });
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
  async toggle_doctor_depertment(id) {
    try {
      const response = await axiosProvider.postRequest(
        "/toggle-department",
        {
          department_id: id,
        }
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
}

const sectionsServices = new SectionsServices();

export default sectionsServices;
