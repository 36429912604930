import axiosProvider from "../../../api/base";

class AppointmentServices {
  // get All appointments
  async get_all_appointments() {
    try {
      const response = await axiosProvider.getRequest("/get-all-appointments");
      return response;
    } catch (error) {
      return error;
    }
  }

  // approve of patient appointment
  async approve_appointment(id) {
    try {
      const response = await axiosProvider.postRequest("/approve-appointment", {
        hospital_appointment_id: id,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  // reject patient appointment
  async reject_appointment(id) {
    try {
      const response = await axiosProvider.postRequest("/reject-appointment", {
        hospital_appointment_id: id,
      });
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  // finish patient appointment
  async finish_appointment(id) {
    try {
      const response = await axiosProvider.postRequest("/finish-appointment", {
        hospital_appointment_id: id,
      });
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
  async clinic_appointmet(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/edit-working-schedule",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async doctor_appointmet(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/create-doctor-schedule",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async hcp_appointmet(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/create-hcp-schedule",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async approve_hcp_appointment(id) {
    try {
      const response = await axiosProvider.postRequest(
        "/approve-hcp-appointment",
        {
          hospital_appointment_id: id,
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  // reject patient appointment
  async reject_hcp_appointment(id) {
    try {
      const response = await axiosProvider.postRequest(
        "/reject-hcp-appointment",
        {
          hospital_appointment_id: id,
        }
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  // finish patient appointment
  async finish_hcp_appointment(id) {
    try {
      const response = await axiosProvider.postRequest(
        "/finish-hcp-appointment",
        {
          hospital_appointment_id: id,
        }
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
  async edit_appointment(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/edit-appointment",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async edit_hcp_appointment(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/edit-hcp-appointment",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
}

const appointmentServices = new AppointmentServices();

export default appointmentServices;
