/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { HospitalDataContext } from "../../../../store/data-context";
import hospitalOfferServices from "../../services/HospitalOffersServices";
import hospitaloffersController from "../../controller/HospitalOffersController";
import Spinner from "../../../../Components/Spinner";
import hospitalServices from "../../../HospitalAccount/services/HospitalServices";
import Form from "react-bootstrap/Form";

import notifications from "../../../../Components/toastify/notifications";
import NavBar from "../../../../Components/Nav/NavComponent";
import useFetch from "../../../../Hooks/useFetch";
import loader from "../../../../Components/loader.gif";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EditHospitalOffers.css";

const EditHospitalOffers = () => {
  const { t } = useTranslation();
  const params = useParams();
  const offerId = params.uniqueID;
  const { data: offer } = useFetch({
    actionMethod: hospitalOfferServices.get_offer_by_id(offerId),
  });

  const [image, setImage] = useState({
    preview: null,
    file: null,
  });
  const [tts, setTitle] = useState();
  const [id, setId] = useState();

  const [titleEnglish, setTitleEnglish] = useState();
  const [titleArabic, setTitleArabic] = useState();
  const [titleTurkish, setTitleTrukish] = useState();
  const [details, setDetails] = useState();
  const [detailsEnglish, setDetailsEnglish] = useState();
  const [detailsArabic, setDetailsArabic] = useState();
  const [detailsTurkish, setDetailsTurkish] = useState();
  const [instruction, setInstruction] = useState();
  const [instructionEnglish, setInstructionEnglish] = useState();
  const [instructionArabic, setInstructionArabic] = useState();
  const [instructionTurkish, setInstructionTurkish] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [loading, setLoading] = useState(true);

  const fromData = {
    id,
    image,
    tts,
    titleEnglish,
    titleArabic,
    titleTurkish,
    details,
    detailsEnglish,
    detailsArabic,
    detailsTurkish,
    instruction,
    instructionEnglish,
    instructionArabic,
    instructionTurkish,
    endDate,
    startDate,
  };

  const handleDetails = (html) => {
    setDetails(html);
  };
  const handleDetailsEnglish = (html) => {
    setDetailsEnglish(html);
  };
  const handleDetailsArabic = (html) => {
    setDetailsArabic(html);
  };
  const handleDetailsTurkish = (html) => {
    setDetailsTurkish(html);
  };

  const handleInstruction = (html) => {
    setInstruction(html);
  };
  const handleInstructionEnglish = (html) => {
    setInstructionEnglish(html);
  };
  const handleInstructionArabic = (html) => {
    setInstructionArabic(html);
  };
  const handleinstructionTurkish = (html) => {
    setInstructionTurkish(html);
  };

  useEffect(() => {
    if (offer) {
      setLoading(false);
      setId(offer.offer.id);
      setTitle(offer.offer.title);
      setTitleArabic(offer.offer.title_ar);
      setTitleEnglish(offer.offer.title_en);
      setTitleTrukish(offer.offer.title_tr);
      setDetails(offer.offer.details);
      setDetailsEnglish(offer.offer.details_en);
      setDetailsArabic(offer.offer.details_ar);
      setDetailsTurkish(offer.offer.details_tr);
      setInstruction(offer.offer.instructions);
      setInstructionEnglish(offer.offer.instructions_en);
      setInstructionArabic(offer.offer.instructions_ar);
      setInstructionTurkish(offer.offer.instructions_tr);
      setEndDate(offer.offer.end_date);
      setStartDate(offer.offer.start_date);
      setImage({
        preview: offer.offer.cover_img,
        file: null,
      });
    }
  }, [offer]);

  if (loading) {
    return <img src={loader} alt="" />;
  } else {
    return (
      <div className="update-cont">
        <NavBar />
        <h2 className="edit-hos-data"> {t("create-offer")}</h2>
        {/* <div>
          <h2>HTML Content:</h2>
          <div dangerouslySetInnerHTML={{ __html: editorHtml }} />
        </div> */}

        <div className="conto8b">
          <form
            id="contact8b"
            onSubmit={(e) =>
              hospitaloffersController.handleEditHospitalOffers(
                e,
                fromData,
                notifications,
                t
                // navigate,
                // setShow
              )
            }
            encType="multipart/form-data"
          >
            <fieldset className="inno">
              <label htmlFor="upload-hospital" className="imgblock">
                {image.preview ? (
                  <img
                    src={image.preview}
                    name="hospital-photo"
                    alt="dummy"
                    className="object-cover w-40 h-40"
                  />
                ) : (
                  <>
                    <span className="fa-stack fa-4x mt-3 mb-2">
                      <i className="fa-solid fa-upload"></i>
                    </span>
                    <h5>{t("choose_hospital_image-offers")}</h5>
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-hospital"
                style={{ display: "none" }}
                onChange={(e) =>
                  hospitaloffersController.handleHospitalImageoffer(e, setImage)
                }
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="title">
                {t("title")}
                {/* {offer?.title} */}
              </label>
              <input
                placeholder={t("title")}
                name="title"
                type="text"
                tabIndex="1"
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                value={tts}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="title_english">
                {t("title_english")}
              </label>
              <input
                placeholder={t("title_english")}
                name="title_english"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitleEnglish(e.target.value)}
                value={titleEnglish}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="title_arabic">
                {t("title_arabic")}
              </label>
              <input
                placeholder={t("title_arabic")}
                name="title_arabic"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitleArabic(e.target.value)}
                value={titleArabic}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="title_turkish">
                {t("title_turkish")}
              </label>
              <input
                placeholder={t("title_turkish")}
                name="title_turkish"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitleTrukish(e.target.value)}
                value={titleTurkish}
                required
              />
            </fieldset>

            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="details">
                  {t("details")}
                </label>
              </div>
              <ReactQuill
                value={details}
                onChange={handleDetails}
                className="text-editor"
              />

              <br />
              {/* <div>
                <select
                  name="hospital-country"
                  id="hospital-address"
                  onChange={(e) =>
                    hospitaloffersController.handleChooseMedicalSpecialty(
                      e,
                      setMedicalSpecialty
                    )
                  }
                >
                  <option value="">--{t("choose_medical_specialties")}--</option>
                  {medicalSpecialties?.medical_specialties &&
                    medicalSpecialties?.medical_specialties.map(
                      (medicalSpecialtiy) => (
                        <option
                          key={medicalSpecialtiy.id}
                          value={medicalSpecialtiy.id}
                        >
                          {medicalSpecialtiy.name}
                        </option>
                      )
                    )}
                </select>
              </div> */}
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="details_english">
                  {t("details_english")}
                </label>
              </div>
              <ReactQuill
                value={detailsEnglish}
                onChange={handleDetailsEnglish}
                className="text-editor"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="details_arabic">
                  {t("details_arabic")}
                </label>
              </div>
              <ReactQuill
                value={detailsArabic}
                onChange={handleDetailsArabic}
                className="text-editor"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="details_turkish">
                  {t("details_turkish")}
                </label>
              </div>
              <ReactQuill
                value={detailsTurkish}
                onChange={handleDetailsTurkish}
                className="text-editor"
              />
            </fieldset>
            {/* <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="hospital-address">
                  {t("offerCategories")}
                </label>
              </div>
              <br />
              <div>
                <select
                  name="hospital-country"
                  id="hospital-address"
                  onChange={(e) =>
                    hospitaloffersController.handleChooseOffersCategory(
                      e,
                      setOfferCategory
                    )
                  }
                >
                  <option value="">--{t("choose_offer_categories")}--</option>
                  {offerCategories?.offer_categories &&
                    offerCategories?.offer_categories.map((offerCategoror) => (
                      <option key={offerCategoror.id} value={offerCategoror.id}>
                        {offerCategoror.name}
                      </option>
                    ))}
                </select>
              </div>
            </fieldset> */}

            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="instruction">
                  {t("instruction")}
                </label>
              </div>
              <ReactQuill
                value={instruction}
                onChange={handleInstruction}
                className="text-editor"
              />
            </fieldset>

            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="instruction_english">
                  {t("instruction_english")}
                </label>
              </div>
              <ReactQuill
                value={instructionEnglish}
                onChange={handleInstructionEnglish}
                className="text-editor"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="instruction_arabic">
                  {t("instruction_arabic")}
                </label>
              </div>
              <ReactQuill
                value={instructionArabic}
                onChange={handleInstructionArabic}
                className="text-editor"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="instruction_turkish">
                  {t("instruction_turkish")}
                </label>
              </div>
              <ReactQuill
                value={instructionTurkish}
                onChange={handleinstructionTurkish}
                className="text-editor"
              />
            </fieldset>

            <fieldset className="inno">
              <div className="icon-cont"></div>
              <label className="label4input" htmlFor="start_date">
                {t("start_date")}: {startDate.slice(0, 11)}
              </label>
              <input
                name="start_date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                tabIndex="8"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont"></div>
              <label className="label4input" htmlFor="end_date">
                {t("end_date")}:{endDate.slice(0, 11)}
              </label>
              <input
                name="end_date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                tabIndex="8"
                id="hospital-name"
              />
            </fieldset>
            {/* end */}
            {/* <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-name">
                {t("title")}
              </label>
              <input
                placeholder={t("title")}
                name="hospital-name"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-email">
                {t("details")}
              </label>
              <input
                placeholder={t("details")}
                name="hospital-email"
                type="text"
                tabIndex="1"
                id="hospital-email"
                onChange={(e) => setDetails(e.target.value)}
                value={details}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-number">
                {t("pt_instruction")}
              </label>
              <input
                placeholder={t("pt_instruction")}
                name="hospital-number"
                type="text"
                tabIndex="4"
                id="hospital-number"
                onChange={(e) => setPtInstruction(e.target.value)}
                value={ptInstruction}
                required
              />
            </fieldset>
  
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-name">
                {t("price")}:
              </label>
              <input
                placeholder={t("price")}
                name="facebook"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                tabIndex="5"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont"></div>
              <label className="label4input" htmlFor="hospital-name">
                {t("price_before")}:
              </label>
              <input
                placeholder={t("price_before")}
                name="instagram"
                type="number"
                value={priceBerfore}
                onChange={(e) => setPriceBefore(e.target.value)}
                tabIndex="6"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont"></div>
              <label className="label4input" htmlFor="hospital-name">
                {t("discount")}:
              </label>
              <input
                name="twitter"
                placeholder={t("discount")}
                type="text"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                tabIndex="7"
                id="hospital-name"
              />
            </fieldset>
          */}

            <ToastContainer />
            <fieldset>
              <button
                name="submit-hospital"
                type="submit"
                id="contact-submit2"
                data-submit="...Sending"
              >
                {loading ? <Spinner /> : t("save")}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
};

export default EditHospitalOffers;
