/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { HospitalDataContext } from "../../../../store/data-context";
import Loader from "../../../../Components/Loader/loader";
import hospitalServices from "../../services/HospitalServices";
import hospitalController from "../../controller/HospitalController";
import Spinner from "../../../../Components/Spinner";
import notifications from "../../../../Components/toastify/notifications";
import NavBar from "../../../../Components/Nav/NavComponent";
import { BsPlusCircle } from "react-icons/bs";

import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";

import "./UpdateHospital.css";

const UpdateHospital = () => {
  const { t } = useTranslation();
  const { countriesData, setShow, data, isLoading } =
    useContext(HospitalDataContext);

  const navigate = useNavigate();
  const {
    city_id,
    name: nameHospital,
    adress: adressHospital,
    phone: phoneHospital,
    phone2: phoneHospital2,
    email: emailHospital,
    bio: bioHospital,
    logo,
    facebook_link,
    instagram_link,
    linkedin_link,
    youtube_link,
    twitter_link,
    country_id,
  } = data?.hospital[0];
  const [hospitalProvince, setHospitalProvince] = useState("1");
  const [hospitalCity, setHospitalCity] = useState(city_id);
  const [name, setName] = useState(nameHospital);
  const [adress, setAdress] = useState(adressHospital);
  const [phone, setPhone] = useState(phoneHospital);
  const [phone2, setPhone2] = useState(phoneHospital2);
  const [email, setEmail] = useState(emailHospital);
  const [bio, setBio] = useState(bioHospital);
  const [image, setImage] = useState({
    preview: logo,
    file: null,
  });
  const [loading, setLoading] = useState(false);
  const [cities2, setCities2] = useState("");
  const [countryId, setCountryId] = useState("");
  const [facebook, setFacebook] = useState(facebook_link);
  const [instagram, setInstagram] = useState(instagram_link);
  const [linkedin, setLinkedin] = useState(linkedin_link);
  const [youtube, setYoutube] = useState(youtube_link);
  const [twitter, setTwitter] = useState(twitter_link);
  const [hospitalCountry, setHospitalCountry] = useState(country_id);
  useEffect(() => {
    async function fetchingCities() {
      try {
        const resCities = await hospitalServices.get_cities_for_country(
          countryId || 1
        );
        setCities2(resCities.cities);
      } catch (error) {}
    }
    fetchingCities();
  }, [countryId]);

  const fromData = {
    hospitalCountry,
    hospitalCity,
    hospitalProvince,
    name,
    email,
    adress,
    phone,
    phone2,
    bio,
    youtube,
    facebook,
    twitter,
    linkedin,
    instagram,
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="update-cont">
        <NavBar />
        <h2 className="edit-hos-data"> {t("update_hospital")} </h2>

        <div className="conto8b">
          <form
            id="contact8b"
            onSubmit={(e) =>
              hospitalController.handleUpdateHospitalData(
                e,
                setLoading,
                fromData,
                image,
                notifications,
                t,
                navigate,
                setShow
              )
            }
            encType="multipart/form-data"
          >
            <fieldset className="inno">
              <label htmlFor="upload-hospital" className="imgblock">
                {image.preview ? (
                  <img
                    src={image?.preview}
                    name="hospital-photo"
                    alt="dummy"
                    className="object-cover w-40 h-40"
                  />
                ) : (
                  <>
                    <span className="fa-stack fa-4x mt-3 mb-2">
                      <i className="fa-solid fa-upload"></i>
                    </span>
                    <h5>{t("choose_hospital_image")}</h5>
                  </>
                )}
                <div className="photo-plus-edit">
                  <BsPlusCircle />
                </div>
              </label>
              <input
                type="file"
                id="upload-hospital"
                style={{ display: "none" }}
                onChange={(e) =>
                  hospitalController.handleHospitalImage(e, setImage)
                }
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-name">
                {t("hospital_name")}
              </label>
              <input
                placeholder={t("hospital_name")}
                name="hospital-name"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-email">
                {t("email_label")}
              </label>
              <input
                placeholder={t("email_label")}
                name="hospital-email"
                type="text"
                tabIndex="1"
                id="hospital-email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-number">
                {t("phone_number_label")}
              </label>
              <input
                placeholder={t("phone_number_label")}
                name="hospital-number"
                type="number"
                tabIndex="4"
                id="hospital-number"
                onChange={(e) => setPhone(e.target.value.trim())}
                value={phone}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="mobile-number">
                {t("mobile_number_label")}
              </label>
              <input
                placeholder={t("mobile_number_label")}
                name="mobile-number"
                // disabled="disabled"
                disabled={data?.hospital[0]?.phone2 ? "disabled" : ""}
                type="number"
                tabIndex="4"
                id="mobile-number"
                onChange={(e) => setPhone2(e.target.value.trim())}
                value={phone2}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont">
                <AiFillFacebook className="social-icons face" />
              </div>
              <br />
              <label className="label4input" htmlFor="hospital-name">
                {t("facebook")}:
              </label>
              <input
                name="facebook"
                type="text"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
                tabIndex="5"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont">
                <AiOutlineInstagram className="social-icons insta" />
              </div>
              <label className="label4input" htmlFor="hospital-name">
                {t("instagram")}:
              </label>
              <input
                name="instagram"
                type="text"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                tabIndex="6"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont">
                <AiOutlineTwitter className="social-icons twitter" />
              </div>
              <label className="label4input" htmlFor="hospital-name">
                {t("twitter")}:
              </label>
              <input
                name="twitter"
                type="text"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                tabIndex="7"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont">
                <AiFillLinkedin className="social-icons linked" />
              </div>
              <label className="label4input" htmlFor="hospital-name">
                {t("linkedin")}:
              </label>
              <input
                name="linkedin"
                type="text"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
                tabIndex="8"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="inno">
              <div className="icon-cont">
                <AiFillYoutube className="social-icons tube" />
              </div>
              <label className="label4input" htmlFor="hospital-name">
                {t("youtube")}:
              </label>
              <input
                name="youtube"
                type="text"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
                tabIndex="9"
                id="hospital-name"
              />
            </fieldset>
            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="hospital-address">
                  {t("hospital_address")}
                </label>
              </div>
              <br />
              <div className="s-tag1">
                <div>
                  <select
                    name="hospital-country"
                    id="hospital-address"
                    onChange={(e) =>
                      hospitalController.handleChooseCountry(
                        e,
                        setHospitalCountry,
                        setCountryId
                      )
                    }
                    value={hospitalCountry}
                  >
                    <option value="">--{t("choose_country")}--</option>
                    {countriesData?.countries &&
                      countriesData?.countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <select
                    name="hospital-city"
                    id="hospital-address"
                    onChange={(e) =>
                      hospitalController.handleChooseCity(e, setHospitalCity)
                    }
                    value={hospitalCity}
                  >
                    <option value="">--{t("choose_city")}--</option>
                    {cities2 &&
                      cities2.map((city) => (
                        <option key={city.id} id={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <textarea
                placeholder={t("address_details")}
                tabIndex="10"
                name="hospital-address-details"
                id="hospital-address-details"
                cols="30"
                rows="4"
                onChange={(e) => setAdress(e.target.value)}
                value={adress}
              ></textarea>
            </fieldset>
            <fieldset className="inno innolast">
              <label className="label4input" htmlFor="hospital-info">
                {t("hospital_bio")}
              </label>
              <textarea
                placeholder={t("hospital_bio")}
                tabIndex="11"
                name="hospital-info"
                id="hospital-info"
                cols="30"
                rows="6"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                required
              ></textarea>
            </fieldset>
            <ToastContainer />
            <fieldset>
              <button
                name="submit-hospital"
                type="submit"
                id="contact-submit2"
                data-submit="...Sending"
              >
                {loading ? <Spinner /> : t("save")}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
};

export default UpdateHospital;
