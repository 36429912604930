/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

const useFetch = ({ actionMethod, dep }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchingData() {
      try {
        const result = await actionMethod;
        setData(result);
      } catch (err) {
        setError(err);
      }
    }
    fetchingData();
  }, [dep]);

  return { data, error };
};

export default useFetch;
