import ScheduleAppointment from "../components/ScheduleAppointment/ScheduleAppointment";

const ScheduleAppointmentView = () => {

    return (
        <>
            <ScheduleAppointment />
        </>
    );
}

export default ScheduleAppointmentView;