/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
import AuthContext from "../../../../store/auth-context";
import { HospitalDataContext } from "../../../../store/data-context";
import hospitalServices from "../../services/HospitalServices";
import hospitalController from "../../controller/HospitalController";
import Spinner from "../../../../Components/Spinner";
import NavBar from "../../../../Components/Nav/NavComponent";
import loader from "../../../../Components/loader.gif";
import "./CreateHospital.css";

const CreateHospital = () => {
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { countriesData } = useContext(HospitalDataContext);
  const [hospitalCountry, setHospitalCountry] = useState(1);
  const [hospitalCity, setHospitalCity] = useState(1);
  const [hospitalProvince, setHospitalProvince] = useState(1);
  const [name, setName] = useState("");
  const [adress, setAdress] = useState("");
  const [phone, setPhone] = useState("");
  const [bio, setBio] = useState("");
  const [errMsg, setErrMsg] = useState(null);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [loading, setLoading] = useState(false);
  const [hospitalEmail, setHospitalEmail] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [youtube, setYoutube] = useState("");
  const [twitter, setTwitter] = useState("");
  const [countryId, setCountryId] = useState("");
  const [cities2, setCities2] = useState("");

  useEffect(() => {
    // getting the cities for a specific country
    async function fetchingCities() {
      try {
        const resCities = await hospitalServices.get_cities_for_country(
          countryId || 1
        );
        setCities2(resCities.cities);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchingCities();
  }, [countryId]);

  const fromData = {
    hospitalCountry,
    hospitalCity,
    hospitalProvince,
    name,
    adress,
    email: hospitalEmail,
    phone,
    bio,
    logo: image.file,
    youtube,
    facebook,
    twitter,
    linkedin,
    instagram,
  };

  // const handleHospitalImage = (e) => {
  //   if (e.target.files.length) {
  //     setImage({
  //       preview: URL.createObjectURL(e.target.files[0]),
  //       file: e.target.files[0],
  //     });
  //   }
  // };

  // const handleHospitalData = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const resHos = await hospitalController.handleCreateHospital(fromData);
  //   if (!resHos) {
  //     setLoading(false);
  //     setErrMsg(
  //       "Please make sure to upload hospital profile picture and fill out all the fields above"
  //     );
  //     return;
  //   } else {
  //     authCtx.createHospital(resHos);
  //     setLoading(false);
  //     navigate("/");
  //     window.location.reload(false);
  //   }
  // };

  // const handleChooseCountry = (e) => {
  //   setHospitalCountry2(e.target.value);
  //   setCountryId2(e.target.value);
  // };

  // const handleChooseCity = (e) => {
  //   const index = e.target.selectedIndex;
  //   const el = e.target.childNodes[index];
  //   const option = el.getAttribute("id");
  //   setHospitalCity2(option);
  // };

  if (!countriesData || !cities2) {
    return <img src={loader} alt="" />;
  } else {
    return (
      <div>
        <NavBar />
        <div className="conto8a">
          <form
            id="contact8a"
            onSubmit={(e) =>
              hospitalController.handleHospitalData(
                e,
                setLoading,
                fromData,
                setErrMsg,
                navigate,
                authCtx
              )
            }
          >
            <fieldset className="inno">
              <label htmlFor="upload-hospital" className="imgblock">
                {image.preview ? (
                  <img
                    src={image.preview}
                    name="hospital-photo"
                    alt="dummy"
                    className="object-cover w-40 h-40"
                  />
                ) : (
                  <>
                    <span className="fa-stack fa-4x mt-3 mb-2">
                      <i className="fa-solid fa-upload"></i>
                    </span>
                    <h5>{t("choose_hospital_image")}</h5>
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-hospital"
                style={{ display: "none" }}
                onChange={(e) =>
                  hospitalController.handleHospitalImage(e, setImage)
                }
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-name">
                {t("hospital_name")}
              </label>
              <input
                placeholder={t("hospital_name")}
                name="hospital-name"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-email">
                {t("email_label")}
              </label>
              <input
                placeholder={t("email_label")}
                name="hospital-email"
                type="email"
                tabIndex="2"
                id="hospital-email"
                value={hospitalEmail}
                onChange={(e) => setHospitalEmail(e.target.value)}
              />
            </fieldset>
            {/* <fieldset className='inno'>
              <label className='label4input' htmlFor="hospital-site">{t("website_label")}</label>
              <input placeholder={t("website_label")} name='hospital-site' type="text" tabIndex="3" id='hospital-site'
                value={hospitalSite} onChange={e => setHospitalSite(e.target.value)} />
            </fieldset> */}
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-number">
                {t("phone_number_label")}
              </label>
              <input
                placeholder={t("phone_number_label_in_english")}
                name="hospital-number"
                type="number"
                tabIndex="4"
                id="hospital-number"
                onChange={(e) => setPhone(e.target.value.trim())}
                value={phone}
                required
              />
            </fieldset>
            {/* <fieldset className='inno'>
              <label className='label4input' htmlFor="hospital-links">{t("other_links")}</label>
              <input placeholder={t("other_links")} name='hospital-links' type="text" tabIndex="5" id='hospital-links'
                value={hospitalLinks} onChange={e => setHospitalLinks(e.target.value)} />
            </fieldset> */}

            <fieldset className="inno">
              <div className="icon-cont">
                <AiFillFacebook className="social-icons face" />
              </div>
              <br />
              <label className="label4input" htmlFor="hospital-name">
                {t("facebook")}:
              </label>
              <input
                name="facebook"
                type="text"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
                tabIndex="5"
                id="hospital-name"
              />
              {/* value={data?.hospital[0]?.name || ""} onChange={(e) => setName(e.target.value.trim())} required */}
            </fieldset>

            <fieldset className="inno">
              <div className="icon-cont">
                <AiOutlineInstagram className="social-icons insta" />
              </div>
              <label className="label4input" htmlFor="hospital-name">
                {t("instagram")}:
              </label>
              <input
                name="instagram"
                type="text"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                tabIndex="6"
                id="hospital-name"
              />
              {/* value={data?.hospital[0]?.name || ""} onChange={(e) => setName(e.target.value.trim())} required */}
            </fieldset>

            <fieldset className="inno">
              <div className="icon-cont">
                <AiOutlineTwitter className="social-icons twitter" />
              </div>
              <label className="label4input" htmlFor="hospital-name">
                {t("twitter")}:
              </label>
              <input
                name="twitter"
                type="text"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                tabIndex="7"
                id="hospital-name"
              />
              {/* value={data?.hospital[0]?.name || ""} onChange={(e) => setName(e.target.value.trim())} required */}
            </fieldset>

            <fieldset className="inno">
              <div className="icon-cont">
                <AiFillLinkedin className="social-icons linked" />
              </div>
              <label className="label4input" htmlFor="hospital-name">
                {t("linkedin")}:
              </label>
              <input
                name="linkedin"
                type="text"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
                tabIndex="8"
                id="hospital-name"
              />
              {/* value={data?.hospital[0]?.name || ""} onChange={(e) => setName(e.target.value.trim())} required */}
            </fieldset>

            <fieldset className="inno">
              <div className="icon-cont">
                <AiFillYoutube className="social-icons tube" />
              </div>
              <label className="label4input" htmlFor="hospital-name">
                {t("youtube")}:
              </label>
              <input
                name="youtube"
                type="text"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
                tabIndex="9"
                id="hospital-name"
              />
              {/* value={data?.hospital[0]?.name || ""} onChange={(e) => setName(e.target.value.trim())} required */}
            </fieldset>

            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="hospital-address">
                  {t("hospital_address")}
                </label>
              </div>
              <br />
              <div className="s-tag1">
                <div>
                  <select
                    name="hospital-country"
                    id="hospital-address"
                    onChange={(e) =>
                      hospitalController.handleChooseCountry(
                        e,
                        setHospitalCountry,
                        setCountryId
                      )
                    }
                  >
                    <option value="">--{t("choose_country")}--</option>
                    {countriesData?.countries?.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                    {/* the next line is old from salah's edit */}
                    {/* {countries && countries.countries.map((country) => <option key={country.id} value={country.id}>{country.name}</option>)} */}
                  </select>
                </div>
                <div>
                  <select
                    name="hospital-city"
                    id="hospital-address"
                    onChange={(e) =>
                      hospitalController.handleChooseCity(e, setHospitalCity)
                    }
                  >
                    <option value="">--{t("choose_city")}--</option>
                    {cities2.map((city) => (
                      <option key={city.id} id={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                    {/* the next line is old from salah's edit */}
                    {/* {cities2 && cities2.cities.map((city) => <option key={city.id} value={city.name}>{city.name}</option>)} */}
                  </select>
                </div>
                {/* <select name="hospital-governorate" id="hospital-address" value={hospitalProvince} onChange={e => setHospitalProvince(e.target.value)} >
                  <option value="invalid">--{t("choose_governorate")}--</option>
                  <option value="istanbul">اسطنبول</option>
                  <option value="cairo">القاهرة</option>
                  <option value="doha">الدوحة</option>
                </select> */}
              </div>
            </fieldset>

            <fieldset>
              <textarea
                placeholder={t("address_details")}
                tabIndex="10"
                name="hospital-address-details"
                id="hospital-address-details"
                cols="30"
                rows="4"
                onChange={(e) => setAdress(e.target.value)}
                value={adress}
                // required
              ></textarea>
            </fieldset>
            <fieldset className="inno innolast">
              <label className="label4input" htmlFor="hospital-info">
                {t("hospital_bio")}
              </label>
              <textarea
                placeholder={t("hospital_bio")}
                tabIndex="11"
                name="hospital-info"
                id="hospital-info"
                cols="30"
                rows="6"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                required
              ></textarea>
            </fieldset>

            {errMsg && (
              <p
                className={`bg-red-50 text-red-600 px-4 py-2 rounded-lg border border-red-100`}
              >
                {errMsg}
              </p>
            )}

            <fieldset>
              <button
                name="submit-hospital"
                type="submit"
                id="contact-submit2"
                data-submit="...Sending"
              >
                {loading ? <Spinner /> : t("save")}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
};

export default CreateHospital;
