import { useState } from "react";
import { useTranslation } from "react-i18next";
import NavComponent from "../../../../Components/Nav/NavComponent";
// import axiosProvider from "../../../../api/base";
import authController from "../../controller/AuthController";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [isDone, setIsDone] = useState(null);

  // const handleRecoveryEmail = (e) => {
  //   setRecoveryEmail(e.target.value);
  // };

  // const handlePasswordRecovery = async (e) => {
  //   e.preventDefault();
  //   // LOGIC GOES HERE FOR PASSWORD RESET
  //   try {
  //     const response = await axiosProvider.postRequest("/forgot-password", {
  //       email: recoveryEmail,
  //     });
  //     if (response.message === "success") {
  //       setIsDone(response.info);
  //     } else {
  //       setIsDone(response.error);
  //     }
  //   } catch (error) {}

  //   setRecoveryEmail("");
  // };
  return (
    <div>
      <NavComponent />
      <div className="forgot-container">
        <form
          id="contact4"
          onSubmit={(e) =>
            authController.handlePasswordRecovery(
              e,
              recoveryEmail,
              setIsDone,
              setRecoveryEmail
            )
          }
        >
          <h3>{t("forgot_password")}</h3>
          <p className="noworry">{t("no_worry")}</p>
          <fieldset>
            <label htmlFor="email2" className="recovery-mail">
              {t("email_label")}
            </label>
            <input
              type="email"
              tabIndex="1"
              id="email2"
              value={recoveryEmail}
              onChange={(e) =>
                authController.handleRecoveryEmail(e, setRecoveryEmail)
              }
              required
              autoFocus
            />
          </fieldset>
          {isDone && (
            <p
              className={`bg-green-50 text-red-600 px-4 py-2 my-2 rounded-lg border border-red-100`}
            >
              {isDone}
            </p>
          )}
          <fieldset>
            <button
              name="submit"
              type="submit"
              id="contact-submit"
              data-submit="...Sending"
            >
              {t("get_new_password")}
            </button>
          </fieldset>
          <fieldset className="noaccount">
            <p>
              <a href="/login">{t("back_to_login")}</a>
            </p>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
