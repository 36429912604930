/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import loader from "../../../../Components/loader.gif";
import { HospitalDataContext } from "../../../../store/data-context";
import hospitalServices from "../../../HospitalAccount/services/HospitalServices";
import hospitaloffersController from "../../controller/HospitalOffersController";
import Spinner from "../../../../Components/Spinner";
import notifications from "../../../../Components/toastify/notifications";
import NavBar from "../../../../Components/Nav/NavComponent";
import useFetch from "../../../../Hooks/useFetch";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EditCoupon.css";
import hospitalOffersServices from "../../services/HospitalOffersServices";

const EditCoupon = () => {
  const { t } = useTranslation();
  const { typesDiscount } = useContext(HospitalDataContext);
  const navigate = useNavigate();
  const params = useParams();
  const offerId = params.uniqueID;

  const { data: coupon } = useFetch({
    actionMethod: hospitalOffersServices.getCouponById(offerId),
  });
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [typesDiscounId, setTypesDiscountId] = useState("");
  const [numberDuplicated, setnumberDuplicated] = useState("");
  const [typesDuplicatedId, setTypesDuplicatedId] = useState("");
  // const [typesDiscount, setTypesDiscount] = useState("");
  const [typesDuplicated, settypesDuplicated] = useState([
    { id: 0, name: t("daily"), value: 1 },
    { id: 1, name: t("weekly"), value: 7 },
    { id: 2, name: t("monthly"), value: 30 },
    { id: 3, name: t("annual"), value: 365 },
  ]);
  const [discountPercentage, setdiscountPercentageId] = useState("");
  const [savedAmount, setSavedAmount] = useState("");
  const [howToUse, setHowToUse] = useState("");

  const [loading, setLoading] = useState(false);

  const handleDetails = (html) => {
    setHowToUse(html);
  };
  const fromData = {
    offerId,
    title,
    price,
    typesDiscounId,
    typesDuplicatedId,
    numberDuplicated,
    discountPercentage,
    savedAmount,
    howToUse,
  };

  useEffect(() => {
    if (coupon) {
      setTitle(coupon.coupon.title);
      setPrice(coupon.coupon.price);
      setTypesDiscountId(coupon.coupon.discount_type_id);
      setnumberDuplicated(coupon.coupon.number_uses);
      setTypesDuplicatedId(coupon.coupon.use_frequency);
      setdiscountPercentageId(coupon.coupon.discount_percentage);
      setSavedAmount(coupon.coupon.saved_amount);
      setHowToUse(coupon.coupon.how_to_use);
    }
  }, [coupon]);
  if (0) {
    return <img src={loader} alt="" />;
  } else {
    return (
      <div className="update-cont">
        <NavBar />
        <h2 className="edit-hos-data"> {t("edit_coupon")}</h2>

        <div className="conto8b">
          <form
            id="contact8b"
            onSubmit={(e) =>
              hospitaloffersController.handleEditCoupon(
                e,
                fromData,
                notifications,
                t,
                navigate
              )
            }
            encType="multipart/form-data"
          >
            <fieldset className="inno">
              <label className="label4input" htmlFor="hospital-name">
                {t("title_coupon")}
              </label>
              <input
                placeholder={t("title_coupon")}
                name="hospital-name"
                type="text"
                tabIndex="1"
                id="hospital-name"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="Price">
                {t("price")}
              </label>
              <input
                placeholder={t("title_english")}
                name="Price"
                type="number"
                tabIndex="1"
                id="Price"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="type_of_discount">
                {t("type_of_discount")}
              </label>
              <div className="s-tag1">
                <div>
                  <select
                    name="type_of_discount"
                    id="type_of_discount"
                    onChange={(e) => setTypesDiscountId(e.target.value)}
                    value={typesDiscounId}
                  >
                    <option value="">--{t("choose_type_of_discount")}--</option>
                    {typesDiscount?.discount_types?.map((typeDiscount) => (
                      <option key={typeDiscount.id} value={typeDiscount.id}>
                        {typeDiscount.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </fieldset>

            <fieldset className="inno">
              <label className="label4input" htmlFor="NO_Duplicated">
                {t("NO_Duplicated")}
              </label>
              <input
                placeholder={t("NO_Duplicated")}
                name="NO_Duplicated"
                type="text"
                tabIndex="1"
                id="NO_Duplicated"
                onChange={(e) => setnumberDuplicated(e.target.value)}
                value={numberDuplicated}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="Type_of_Duplicated">
                {t("type_of_duplicated")}
              </label>
              <div className="s-tag1">
                <div>
                  <select
                    name="hospital-country"
                    id="hospital-address"
                    onChange={(e) => setTypesDuplicatedId(e.target.value)}
                    value={typesDuplicatedId}
                  >
                    <option value="">
                      --{t("choose_type_of_duplicated")}--
                    </option>
                    {typesDuplicated?.map((typeDiscount) => (
                      <option key={typeDiscount.id} value={typeDiscount.id}>
                        {typeDiscount.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </fieldset>

            <fieldset className="inno">
              <label className="label4input" htmlFor="discount_percentage">
                {t("discount_percentage")}
              </label>
              <input
                placeholder={t("discount_percentage")}
                name="discount_percentage"
                type="text"
                tabIndex="1"
                id="discount_percentage"
                onChange={(e) => setdiscountPercentageId(e.target.value)}
                value={discountPercentage}
                required
              />
            </fieldset>
            <fieldset className="inno">
              <label className="label4input" htmlFor="saved_amount">
                {t("saved_amount")}
              </label>
              <input
                placeholder={t("saved_amount")}
                name="saved_amount"
                type="number"
                tabIndex="1"
                id="saved_amount"
                onChange={(e) => setSavedAmount(e.target.value)}
                value={savedAmount}
                required
              />
            </fieldset>
            {/* <fieldset className="inno">
              <label className="label4input" htmlFor="how_to_use">
                {t("how_to_use")}
              </label>
              <input
                placeholder={t("how_to_use")}
                name="how_to_use"
                type="text"
                tabIndex="1"
                id="how_to_use"
                onChange={(e) => setHowToUse(e.target.value)}
                value={howToUse}
                required
              />
            </fieldset> */}

            <fieldset className="s-all1">
              <div className="s-label">
                <label className="label4input" htmlFor="how_to_use">
                  {t("how_to_use")}
                </label>
              </div>
              <ReactQuill
                value={howToUse}
                onChange={handleDetails}
                className="text-editor"
              />

              <br />
            </fieldset>
            <ToastContainer />
            <fieldset>
              <button
                name="submit-hospital"
                type="submit"
                id="contact-submit2"
                data-submit="...Sending"
              >
                {loading ? <Spinner /> : t("save")}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
};

export default EditCoupon;
