import { createContext, useContext, useEffect, useState } from "react";
import { fetchData } from "../Hooks/useFetchAllApi";
import { useTranslation } from "react-i18next";
import hospitalServices from "..//modules/HospitalAccount/services/HospitalServices";
import adminServices from "../modules/AdminAccount/services/AdminServices";
import doctorServices from "../modules/Doctors/services/DoctorServices";
import hcpServices from "../modules/hcp/services/HcpServices";
import appointmentServices from "../modules/Appointment/services/AppointmentServices";
import sectionsServices from "../modules/MedicalDepartments/services/SectionsServices";
import hospitalOffersServices from "..//modules/hopitalOffers/services/HospitalOffersServices";
import Loader from "../Components/Loader/loader";
import AuthContext from "./auth-context";

export const HospitalDataContext = createContext();

const mainApis = {
  data: hospitalServices.get_hospital,
  admin: adminServices.get_hospital_admin,
  appointments: appointmentServices.get_all_appointments,
  countriesData: hospitalServices.get_countries,
  Doctors: doctorServices.get_departments,
  hcp: hcpServices.get_hcp_staff,
  medicalSpecialties: sectionsServices.get_all_medical_specialties,
  hcpSpecialties: hcpServices.get_all_hcp_specialties,
  Offers: hospitalOffersServices.get_offers,
  offerCategories: hospitalOffersServices.get_offer_categories,
  typesDiscount: hospitalOffersServices.getdiscountTypes,
  bookedCoupons: hospitalOffersServices.getBookedCoupons,
};

export const HospitalContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);

  const [allData, setAllData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDataAsync = async () => {
    const data = await fetchData(mainApis);
    setAllData(data);
    setIsLoading(false);
  };
  useEffect(() => {
    if (token) {
      fetchDataAsync();
    }
  }, [token]);

  const week = [
    { id: 0, day: t("Saturday"), value: "sat" },
    { id: 1, day: t("Sunday"), value: "sun" },
    { id: 2, day: t("Monday"), value: "m" },
    { id: 3, day: t("Tuesday"), value: "tu" },
    { id: 4, day: t("Wednesday"), value: "w" },
    { id: 5, day: t("Thursday"), value: "th" },
    { id: 6, day: t("Friday"), value: "f" },
  ];
  const weekCurrent = [
    { id: 0, day: t("Sunday"), value: "sun" },
    { id: 1, day: t("Monday"), value: "m" },
    { id: 2, day: t("Tuesday"), value: "tu" },
    { id: 3, day: t("Wednesday"), value: "w" },
    { id: 4, day: t("Thursday"), value: "th" },
    { id: 5, day: t("Friday"), value: "f" },
    { id: 6, day: t("Saturday"), value: "sat" },
  ];
  const times = [
    { id: 0, hour: "1:00" },
    { id: 1, hour: "2:00" },
    { id: 2, hour: "3:00" },
    { id: 3, hour: "4:00" },
    { id: 4, hour: "5:00" },
    { id: 5, hour: "6:00" },
    { id: 6, hour: "7:00" },
    { id: 7, hour: "8:00" },
    { id: 8, hour: "9:00" },
    { id: 9, hour: "10:00" },
    { id: 10, hour: "11:00" },
    { id: 11, hour: "12:00" },
    { id: 12, hour: "13:00" },
    { id: 13, hour: "14:00" },
    { id: 14, hour: "15:00" },
    { id: 15, hour: "16:00" },
    { id: 16, hour: "17:00" },
    { id: 17, hour: "18:00" },
    { id: 18, hour: "19:00" },
    { id: 19, hour: "20:00" },
    { id: 20, hour: "21:00" },
    { id: 21, hour: "22:00" },
    { id: 22, hour: "23:00" },
    { id: 23, hour: "24:00" },
  ];
  const duration = [
    { id: 0, time: "10" },
    { id: 1, time: "15" },
    { id: 2, time: "20" },
    { id: 3, time: "30" },
    { id: 4, time: "45" },
    { id: 5, time: "60" },
  ];
  const weekday = [
    t("Sunday"),
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
  ];
  return (
    <HospitalDataContext.Provider
      value={{
        week,
        times,
        duration,
        weekday,
        handleClose,
        show,
        setShow,
        weekCurrent,
        isLoading,
        ...allData,
      }}
    >
      {token ? isLoading ? <Loader /> : children : children}
    </HospitalDataContext.Provider>
  );
};

export default HospitalContextProvider;
