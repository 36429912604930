import axiosProvider from "../../../api/base";

class DoctorServices {
  // Add Doctor
  async Add_doctor(data) {
    try {
      const response = await axiosProvider.postRequest("/add-doctor", {
        phone: data.phone,
        hospital_department_id: data.hospital_department_id,
      });
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  async Add_doctor_by_email(data) {
    try {
      const response = await axiosProvider.postRequest("/add-doctor-email", {
        email: data.email,
        hospital_department_id: data.hospital_department_id,
      });
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
  // Get Admin Profile
  async get_hospital_admin() {
    const response = await axiosProvider.getRequest("/get-hospital-admin");
    return response;
  }

  // Get Admin Hospital
  async get_hospital() {
    try {
      const response = await axiosProvider.getRequest("/hospital");
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }

  // Get All Departments
  async get_departments() {
    try {
      const response = await axiosProvider.getRequest("/get-all-staff");
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
}

const doctorServices = new DoctorServices();

export default doctorServices;
