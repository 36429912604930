/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./DoctorModalProfile.css";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import notifications from "../../../../Components/toastify/notifications";
import doctorController from "../../controller/DoctorController";
function DoctorModalProfile({ handleCloseDr, showDr, doctorId }) {
  const { t } = useTranslation();
  const [durationTime, setDurationTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [errortime, setErrorTime] = useState("");
  const [checkedstate, setCheckedstate] = useState(false);
  const [workDays, setWorkDays] = useState([]);

  const { times, duration } = useContext(HospitalDataContext);

  return (
    <>
      {/* here doctor */}

      <Modal show={showDr} onHide={handleCloseDr}>
        <Modal.Header>
          <Modal.Title className="title-model-clinic-appointment">
            {t("doctor_working_hours")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={(e) =>
              doctorController.handleDoctorApppointment(
                e,
                doctorId,
                startTime,
                endTime,
                workDays,
                durationTime,
                notifications,
                setStartTime,
                setEndTime,
                setWorkDays,
                setDurationTime,
                handleCloseDr,
                t
              )
            }
          >
            {t("doctor_working_days")}
            <Form.Group className="mb-3 week">
              <Form.Group>
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Saturday")}
                  value="sat"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    doctorController.handleKeywords(e, workDays, setWorkDays)
                  }
                />
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Sunday")}
                  value="sun"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    doctorController.handleKeywords(e, workDays, setWorkDays)
                  }
                />
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Monday")}
                  value="m"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    doctorController.handleKeywords(e, workDays, setWorkDays)
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Tuesday")}
                  value="tu"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    doctorController.handleKeywords(e, workDays, setWorkDays)
                  }
                />
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Wednesday")}
                  value="w"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    doctorController.handleKeywords(e, workDays, setWorkDays)
                  }
                />
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Thursday")}
                  defaultChecked={!!checkedstate}
                  value="th"
                  onChange={(e) =>
                    doctorController.handleKeywords(e, workDays, setWorkDays)
                  }
                />
              </Form.Group>

              <Form.Check
                reverse
                type="checkbox"
                // id="default-checkbox"
                label={t("Friday")}
                value="f"
                defaultChecked={!!checkedstate}
                onChange={(e) =>
                  doctorController.handleKeywords(e, workDays, setWorkDays)
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t("work_time_doctor")} </Form.Label>
              <br />
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) =>
                  doctorController.handleStartTime(e, setStartTime)
                }
              >
                <option value=""> {t("from")} </option>
                {times.map((time) => (
                  <option key={time.id} value={time.hour}>
                    {time.hour}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <>
                <select
                  className="select-times"
                  name=""
                  id=""
                  onChange={(e) =>
                    doctorController.handleEndTime(
                      e,
                      startTime,
                      setErrorTime,
                      setEndTime
                    )
                  }
                >
                  <option value=""> {t("to")} </option>

                  {times.map((time) => (
                    <option key={time.id} value={time.hour}>
                      {time.hour}
                    </option>
                  ))}
                </select>
                <span>{errortime}</span>
              </>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("appointment_duration")}</Form.Label>
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) =>
                  doctorController.handleDurationTime(e, setDurationTime)
                }
              >
                <option value=""> {t("duration")} </option>

                {duration.map((time) => (
                  <option key={time.id} value={time.time}>
                    {time.time}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Button variant="primary" type="submit" className="save-btn">
              {t("save")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* end  doctor*/}
    </>
  );
}

export default DoctorModalProfile;
