import "./DoctorProfile.component.css";
import { useParams, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import profileDoctorServices from "../../services/ProfileDoctorServices";
import doctorController from "../../controller/DoctorController";
import notifications from "../../../../Components/toastify/notifications";
import { ToastContainer } from "react-toastify";
import loader from "../../../../Components/loader.gif";
import useFetch from "../../../../Hooks/useFetch";
import { HospitalDataContext } from "../../../../store/data-context";
import DoctorModalProfile from "./../DoctorModalProfile/DoctorModalProfile";

const DoctorProfile = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDr, setShowDr] = useState(false);

  const handleCloseDr = () => setShowDr(false);
  const handleShowDr = () => setShowDr(true);
  const enteredDoctorId = params.uniqueID;
  const { data: hosData } = useContext(HospitalDataContext);
  const { data: doctor } = useFetch({
    actionMethod: profileDoctorServices.get_doctor_profile(enteredDoctorId),
  });
  if (!doctor || hosData.length === 0) {
    return <img src={loader} alt="" />;
  } else {
    return (
      <div className="dprofile-cont">
        <div className="doc-all">
          <p className="doc-name">
            {doctor?.doctor?.title +
              " " +
              doctor?.doctor?.first_name +
              " " +
              doctor?.doctor?.last_name}
          </p>
          <p className="hospital-name">{hosData?.hospital[0]?.name}</p>
          <img className="doc-pic" alt="" />
        </div>
        <div className="tab-switch">
          <div className="mytabs">
            <input
              type="radio"
              id="tabfree"
              name="mytabs"
              defaultChecked="checked"
            />
            <label htmlFor="tabfree">البيانات الشخصية</label>
            <div className="tab">
              <div className="profile-grid">
                <div className="sm">
                  <img src={doctor?.doctor?.profile_img} alt="profile_img" />
                  <Button
                    onClick={(e) =>
                      doctorController.handleDeleteDoctor(
                        e,
                        navigate,
                        doctor.doctor.hospital_doctor[0]?.id,
                        notifications,
                        t
                      )
                    }
                    type="submit"
                    className="delete-doctor-btn"
                  >
                    {t("delete")}
                  </Button>
                  {/* <div className='doctor-schedule'>
                    <h3>جدول أعمال الطبيب</h3>
                    <p>لم يتم تحديد أي مواعيد بعد من فضلك قم بتعديل الملف الشخصي وإضافة مواعيد العمل المتاحة لديك لاستقبال الحجوزات</p>
                  </div> */}
                </div>
                <div className="lg">
                  <h3 className="doctor-name">
                    {doctor?.doctor?.title +
                      "  " +
                      doctor?.doctor?.first_name +
                      "  " +
                      doctor?.doctor?.last_name}
                  </h3>
                  {/* <p>{params.uniqueID}</p> */}
                  <p className="blued bolded">
                    {doctor?.doctor?.medical_specialty?.name_ar}
                  </p>
                  <p className="dif-col">{hosData?.hospital[0]?.name}</p>
                  <p className="blued doc2-info">{doctor?.doctor?.bio}</p>
                  <div className="dflexed">
                    <h3 className="fixedh">التخصص</h3>
                    <p className="spec-name">
                      {doctor?.doctor?.medical_specialty?.name_ar}
                    </p>
                  </div>
                  {/* <div className='dflexed'>
                    <h3 className='fixedh'>الدرجة العلمية</h3>
                    <p>لا توجد اضافات</p>
                  </div> */}
                  {/* <h4 className='margined-down'>بيانات العمل</h4> */}
                  {/* <h3 className='fixedh margined-down2'>خدمات الطبيب</h3> */}
                  <div className="doc-location">
                    {/* <h3 className='fixedh margined'>{t("reservation_details")}</h3> */}
                    <h3 className="fixedh margined">تفاصيل الحجز</h3>
                    <p>
                      {" "}
                      <span className="info-faded"> العنوان: </span>{" "}
                      {doctor?.doctor?.adress}{" "}
                    </p>
                    {/* <p><span className='info-faded'>مواعيد العمل </span>  السبت - الخميس (10am - 5pm)</p> */}
                    <p>
                      {" "}
                      <span className="info-faded"> رقم الهاتف : </span>{" "}
                      {doctor?.doctor?.phone}{" "}
                    </p>
                    <p>
                      {" "}
                      <span className="info-faded"> سعر الكشف: </span>{" "}
                      {doctor?.doctor?.examination_fee} ليرة{" "}
                    </p>
                    <p>
                      {" "}
                      <span className="info-faded"> مدة الانتظار: </span>{" "}
                      {doctor?.doctor?.waiting_time} دقيقة{" "}
                    </p>
                  </div>
                  <div className="instructions">
                    <h3 className="fixedh"> تعليمات للمرضى</h3>
                    <div className="instruct">
                      {/* <div className='flex1'>
                        <div>ضرورة الحضور بالكمامة</div>
                        <div>الدخول للطبيب باسبقية الحضور</div>
                      </div>
                      <div className='flex1'>
                        <div>عدم احضار الاطفال</div>
                        <div>اعطاء بيانات المريض كاملة </div>
                      </div> */}
                      {doctor?.doctor?.pt_instruction}
                    </div>
                  </div>
                  <button
                    className="doctor-appintment-btn"
                    onClick={handleShowDr}
                  >
                    {t("doctor_appointments")}
                  </button>
                </div>
              </div>
            </div>

            <input type="radio" id="tabgold" name="mytabs" />
            <label htmlFor="tabgold">التقييمات</label>
            <div className="tab">
              <h2>لا يوجد تقييمات حتى الان</h2>
            </div>
          </div>
        </div>
        <DoctorModalProfile
          handleCloseDr={handleCloseDr}
          showDr={showDr}
          doctorId={doctor?.doctor?.hospital_doctor[0]?.id}
        />
        <ToastContainer />
      </div>
    );
  }
};

export default DoctorProfile;
