import React, { useState, useCallback } from "react";

// let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  account: "",
  isLoggedIn: false,
  thereIsHospital: false,
  login: (data) => {},
  logout: () => {},
  createHospital: (reshos) => {},
});

// const calculateRemainingTime = (expirationTime) => {
//     const currentTime = new Date().getTime();
//     const adjExpirationTime = new Date(expirationTime).getTime();

//     const remainingDuration = adjExpirationTime - currentTime;

//     return remainingDuration;
// };

// const retrieveStoredToken = () => {
//     const storedToken = localStorage.getItem('token');
//     // const storedExpirationDate = localStorage.getItem('expirationTime');

//     // const remainingTime = calculateRemainingTime(storedExpirationDate);

//     // if (remainingTime <= 3600) {
//     //     localStorage.removeItem('token');
//     //     // localStorage.removeItem('expirationTime');
//     //     return null;
//     // }

//     return {
//         token: storedToken,
//         // duration: remainingTime,
//     };
// };

export const AuthContextProvider = (props) => {
  // const tokenData = retrieveStoredToken();

  // let initialToken;
  // if (tokenData) {
  //     initialToken = tokenData.token;
  // }

  const [token, setToken] = useState(() => localStorage.getItem("token"));
  const [account, setAccount] = useState(
    () => localStorage.getItem("account") || false
  );

  const userIsLoggedIn = !!token;
  const thereIsHospital = !!account;

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem("token");
    setAccount(false);
    localStorage.removeItem("account");
    // localStorage.removeItem('expirationTime');

    // if (logoutTimer) {
    //     clearTimeout(logoutTimer);
    // }
  }, []);

  const loginHandler = (data) => {
    localStorage.setItem("token", data.token);
    setToken(data.token);
    // i should here set the incoming data to a state like account but for hospital data

    if (data.hospital_admin.hospital_id) {
      setAccount(true);
      localStorage.setItem("account", true);
    } else return;

    // setAccount(data.hospital_admin.hospital_id);
    // i am not sure of the next line
    // createHospital();

    // localStorage.setItem('expirationTime', expirationTime);

    // const remainingTime = calculateRemainingTime(expirationTime);

    // logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const hospitalHandler = (reshos) => {
    if (reshos.message === "success") {
      setAccount(true);
      localStorage.setItem("account", true);
    } else return;
  };

  // useEffect(() => {
  //     if (tokenData) {
  //         console.log(tokenData.duration);
  //         logoutTimer = setTimeout(logoutHandler, tokenData.duration);
  //     }
  // }, [tokenData, logoutHandler]);

  // useEffect(() => {
  //     const accountData = localStorage.getItem('data');
  //     setAccount(JSON.parse(accountData));
  // }, []);

  const contextValue = {
    token: token,
    // the next line could be like this => data: data
    account: account,
    isLoggedIn: userIsLoggedIn,
    thereIsHospital: thereIsHospital,
    login: loginHandler,
    logout: logoutHandler,
    createHospital: hospitalHandler,
  };

  // console.log({ dataFromContext: account });
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

// This is the old code only for reference.
// import React, { useState, useEffect } from 'react';

// const AuthContext = React.createContext({
//     token: '',
//     account: '',
//     // hospital: '',
//     isLoggedIn: false,
//     // isCreated: false,
//     login: (data) => { },
//     logout: () => { },
//     // hospitalCreated: () => { }
// });

// export const AuthContextProvider = (props) => {
//     const initialToken = localStorage.getItem('token');
//     const [token, setToken] = useState(initialToken);
//     // const initialHospital = localStorage.getItem('hospital');
//     // const [hospital, setHospital] = useState(initialHospital);
//     const [account, setAccount] = useState(null);

//     const userIsLoggedIn = !!token;
//     // const hospitalIsCreated = !!hospital;

//     useEffect(() => {
//         const accountData = localStorage.getItem('data');
//         setAccount(JSON.parse(accountData));
//     }, []);
//     console.log({ dataFromContext: account });

//     const loginHandler = (data) => {
//         setAccount(data.hospital_admin);
//         setToken(data.token);
//         localStorage.setItem('token', data.token);
//         localStorage.setItem('data', JSON.stringify(data.hospital_admin));
//     };

//     // const hospitalIsCreatedHandler = () => {
//     //     setHospital(true);
//     //     localStorage.setItem('hospital', true);
//     // };

//     const logoutHandler = () => {
//         setAccount(null);
//         setToken(null);
//         // setHospital(null);
//         localStorage.removeItem('token');
//         localStorage.removeItem('data');
//     };

//     const contextValue = {
//         token: token,
//         account: account,
//         // hospital: hospital,
//         isLoggedIn: userIsLoggedIn,
//         // isCreated: hospitalIsCreated,
//         login: loginHandler,
//         logout: logoutHandler,
//         // hospitalCreated: hospitalIsCreatedHandler
//     };

//     return (
//         <AuthContext.Provider value={contextValue}>
//             {props.children}
//         </AuthContext.Provider>
//     );
// };

// export default AuthContext;
