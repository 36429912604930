/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./ClinicModal.css";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import appointmentController from "../../controller/AppointmentController";
import notifications from "../../../../Components/toastify/notifications";

function ClinicModal({ handleClose, show }) {
  const { t } = useTranslation();

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [errortime, setErrorTime] = useState("");
  const [workDays, setWorkDays] = useState([]);
  const [checkedstate, setCheckedstate] = useState(false);
  const { times } = useContext(HospitalDataContext);
  // const handleClinicApppointment = async (e) => {
  //   e.preventDefault();
  //   const res = await appointmentController.handleClinicAppointment(
  //     startTime,
  //     endTime,
  //     workDays.toString()
  //   );
  //   if (res.message === "success") {
  //     notifications.notifySuccess(t("done_clinic_appointment"));
  //     setStartTime("");
  //     setEndTime("");
  //     setWorkDays([]);
  //     handleClose();
  //   } else {
  //     if (res.error.work_days) {
  //       notifications.notifyError(t("error_work_day"));
  //     } else if (res.error.start_time) {
  //       notifications.notifyError(t("error_start_time"));
  //     } else {
  //       notifications.notifyError(t("error_end_time"));
  //     }
  //   }
  // };
  // const handleKeywords = (e) => {
  //   let updateDays = [...workDays];
  //   if (e.target.checked) {
  //     updateDays = [...workDays, e.target.value];
  //   } else {
  //     updateDays.splice(workDays.indexOf(e.target.value), 1);
  //   }
  //   setWorkDays(updateDays);
  // };
  // const handleStartTime = (e) => {
  //   setStartTime(e.target.value);
  // };
  // const handleEndTime = (e) => {
  //   setEndTime(e.target.value);
  // };
  // const checkTime = (e) => {
  //   e.preventDefault();

  //   const start_time = Number(startTime.split(":"));
  //   const end_time = Number(endTime.split(":"));

  //   if (end_time[0] > start_time[0]) {
  //     setErrorTime("You Must chose a time bigger than startTime");
  //     setEndTime("");
  //     setStartTime("");
  //   }
  //   console.log(errortime);
  // };
  return (
    <>
      {/* here clinic */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="title-model-clinic-appointment">
            {t("clinic_working_hours")}
          </Modal.Title>
        </Modal.Header>

        {t("")}

        <Modal.Body>
          <Form
            onSubmit={(e) =>
              appointmentController.handleClinicApppointment(
                e,
                startTime,
                endTime,
                workDays,
                notifications,
                setStartTime,
                setEndTime,
                setWorkDays,
                handleClose,
                t
              )
            }
          >
            {t("clinic_working_days")}
            <Form.Group className="mb-3 week">
              <Form.Group>
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Saturday")}
                  value="sat"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    appointmentController.handleKeywords(
                      e,
                      workDays,
                      setWorkDays
                    )
                  }
                />
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Sunday")}
                  value="sun"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    appointmentController.handleKeywords(
                      e,
                      workDays,
                      setWorkDays
                    )
                  }
                />
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Monday")}
                  value="m"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    appointmentController.handleKeywords(
                      e,
                      workDays,
                      setWorkDays
                    )
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Tuesday")}
                  value="tu"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    appointmentController.handleKeywords(
                      e,
                      workDays,
                      setWorkDays
                    )
                  }
                />
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Wednesday")}
                  value="w"
                  defaultChecked={!!checkedstate}
                  onChange={(e) =>
                    appointmentController.handleKeywords(
                      e,
                      workDays,
                      setWorkDays
                    )
                  }
                />
                <Form.Check
                  reverse
                  type="checkbox"
                  // id="default-checkbox"
                  label={t("Thursday")}
                  defaultChecked={!!checkedstate}
                  value="th"
                  onChange={(e) =>
                    appointmentController.handleKeywords(
                      e,
                      workDays,
                      setWorkDays
                    )
                  }
                />
              </Form.Group>

              <Form.Check
                reverse
                type="checkbox"
                // id="default-checkbox"
                label={t("Friday")}
                value="f"
                defaultChecked={!!checkedstate}
                onChange={(e) =>
                  appointmentController.handleKeywords(e, workDays, setWorkDays)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("work_time")} </Form.Label>
              <br />
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) => {
                  appointmentController.handleStartTime(e, setStartTime);
                }}
              >
                <option value=""> {t("from")} </option>
                {times.map((time) => (
                  <option key={time.id} value={time.hour}>
                    {time.hour}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <>
                <select
                  className="select-times"
                  name=""
                  id=""
                  onChange={(e) =>
                    // checkTime(e);
                    appointmentController.handleEndTime(
                      e,
                      startTime,
                      setErrorTime,
                      setEndTime
                    )
                  }
                >
                  <option value=""> {t("to")} </option>

                  {times.map((time) => (
                    <option key={time.id} value={time.hour}>
                      {time.hour}
                    </option>
                  ))}
                </select>
                <span>{errortime}</span>
              </>
            </Form.Group>

            <Button variant="primary" type="submit" className="save-btn">
              {t("save")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* end  clinic*/}
    </>
  );
}

export default ClinicModal;
