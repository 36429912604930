import axiosProvider from "../../../api/base";

class HcpProfileServices {
  // Get Doctor Profile Info
  async get_hcp_profile(uniqueID) {
    try {
      const response = await axiosProvider.getRequest(
        `/get-hcp-profile/${uniqueID}`
      );
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
  async delelet_hcp(hcpID) {
    try {
      const response = await axiosProvider.postRequest("/delete-hcp", {
        hcp_id: hcpID,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
  async get_work_hour_profile_hcp(hospitalHcpID, day) {
    try {
      const response = await axiosProvider.getRequest(
        `/get-hcp-day-schedule/${hospitalHcpID}/${day}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }
  async hcp_appointment_form_profile(data) {
    try {
      const response = await axiosProvider.postRequest(
        "/create-hcp-week-schedule",
        data
      );
      return response;
    } catch (error) {
      // console.log({ error });
      return error;
    }
  }
}

const hcpProfileServices = new HcpProfileServices();

export default hcpProfileServices;
