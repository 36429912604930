import LogInForm from "../components/Login/LogInForm";
import NavComponent from "../../../Components/Nav/NavComponent";
const LogIn = () => {
  return (
    <div>
      <NavComponent />
      <LogInForm />
    </div>
  );
};

export default LogIn;
