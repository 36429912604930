import { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, ThemeProvider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { HospitalDataContext } from "../../../../store/data-context";
import loader from "../../../../Components/loader.gif";
import "./HcpList.css";

const HcpList = () => {
  const { hcp } = useContext(HospitalDataContext);
  const { t } = useTranslation();
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Almarai",
        "Nunito",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });

  if (hcp.length === 0) {
    return <img className="loader" src={loader} alt="" />;
  } else {
    return (
      <div className="dlist-cont">
        <h2 className="choose">{t("choose_specialty")}</h2>
        <div className="acc-cont list-group">
          {document.body.dir === "rtl" ? (
            <ThemeProvider theme={theme}>
              {hcp?.hospital_hcp_departments?.map((dep) => (
                <Accordion key={dep.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Avatar
                      className="avatar"
                      alt="Remy Sharp"
                      src={dep.specialty.img}
                    />
                    <Typography className="dep-name">
                      {dep.specialty.name_ar}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component={"span"}>
                      {dep.hospital_hcp.map((hcp) => (
                        <Link
                          className="doctor-link list-group-item list-group-item-action av-name"
                          key={hcp.hcp.unique_id}
                          to={`/hcpprofile/${hcp.hcp.unique_id}`}
                        >
                          <Avatar
                            className="avatar"
                            alt="Remy Sharp"
                            src={hcp.hcp.profile_img}
                          />
                          {hcp.hcp.first_name + " " + hcp.hcp.last_name}
                        </Link>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={theme}>
              {hcp?.hospital_hcp_departments?.map((dep) => (
                <Accordion key={dep.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Avatar
                      className="avatar"
                      alt="Remy Sharp"
                      src={dep.specialty.img}
                    />
                    <Typography className="dep-name">
                      {dep.specialty.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {dep.hospital_hcp.map((hcp) => (
                        <Link
                          className="doctor-link"
                          key={hcp.hcp.unique_id}
                          to={`/hcpprofile/${hcp.hcp.unique_id}`}
                        >
                          <a
                            className="list-group-item list-group-item-action av-name"
                            href="#home"
                          >
                            <Avatar
                              className="avatar"
                              alt="Remy Sharp"
                              src={hcp.hcp.profile_img}
                            />
                            {hcp.hcp.first_name + " " + hcp.hcp.last_name}
                          </a>
                        </Link>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </ThemeProvider>
          )}
        </div>
      </div>
    );
  }
};

export default HcpList;
