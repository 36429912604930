import { toast } from "react-toastify";

class notifications {
  static notifySuccess(messages) {
    toast.success(messages);
  }
  static notifyError(messages) {
    toast.error(messages);
  }
}

export default notifications;
