/* eslint-disable no-unused-vars */
import { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavBar from "../../../../Components/Nav/NavComponent";
import hospitalServices from "../../services/HospitalServices";
import hospitalController from "../../controller/HospitalController";
import Spinner from "../../../../Components/Spinner";
import useFetch from "../../../../Hooks/useFetch";
// import { HospitalDataContext } from "../../../../store/data-context";

import { ToastContainer } from "react-toastify";
import notifications from "../../../../Components/toastify/notifications";
import "./TranslateHospital.css";

const TranslateHospital = () => {
  const { t } = useTranslation();
  // const { data, week } = useContext(HospitalDataContext);
  const { data } = useFetch({
    actionMethod: hospitalServices.get_hospital(),
  });
  // const navigate = useNavigate();
  const [name_ar, setNameAr] = useState("");
  const [name_en, setNameEn] = useState("");
  const [name_tr, setNameTr] = useState("");
  const [adress_ar, setAdress_ar] = useState("");
  const [adress_en, setAdress_en] = useState("");
  const [adress_tr, setAdress_tr] = useState("");
  const [bio_ar, setBioAr] = useState("");
  const [bio_en, setBioEn] = useState("");
  const [bio_tr, setBioTr] = useState("");
  const [loading, setLoading] = useState(false);

  const fromData = {
    name_ar,
    name_en,
    name_tr,
    adress_ar,
    adress_en,
    adress_tr,
    bio_ar,
    bio_en,
    bio_tr,
  };

  // const handleHospitalData = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   if (
  //     name_ar === "" ||
  //     name_en === "" ||
  //     name_tr === "" ||
  //     adress_ar === "" ||
  //     adress_en === "" ||
  //     adress_tr === "" ||
  //     bio_ar === "" ||
  //     bio_en === "" ||
  //     bio_tr === ""
  //   ) {
  //     notifications.notifyError(t("epmty_form_translate"));
  //   } else {
  //     const resHos = await hospitalController.handleTranslateHospital(fromData);
  //     setLoading(false);
  //     notifications.notifySuccess(t("done_translate"));
  //   }
  // };

  return (
    <div className="update-cont">
      <NavBar />
      <h2 className="edit-hos-data">تعديل بيانات المشفى</h2>
      <div className="conto8b">
        <form
          id="contact8b"
          onSubmit={(e) =>
            hospitalController.handleHospitalDataTranslate(
              e,
              setLoading,
              fromData,
              name_ar,
              name_en,
              name_tr,
              adress_ar,
              adress_en,
              adress_tr,
              bio_ar,
              bio_en,
              bio_tr,
              t,
              notifications
            )
          }
          encType="multipart/form-data"
        >
          <fieldset className="inno">
            <label className="label4input" htmlFor="hospital-name">
              {t("hospital_name")}
            </label>
            <label className="label-value" htmlFor="hospital-name">
              {data && data?.hospital[0]?.name}
            </label>
            <label className="label4input" htmlFor="hospital-name_ar">
              Arabic
            </label>
            <input
              placeholder={t("hospital_name")}
              name="hospital-name_ar"
              type="text"
              tabIndex="1"
              id="hospital-name"
              onChange={(e) => setNameAr(e.target.value)}
              value={name_ar}
              required
            />
            <label className="label4input" htmlFor="hospital-name_en">
              English
            </label>
            <input
              placeholder={t("hospital_name")}
              name="hospital-name_en"
              type="text"
              tabIndex="1"
              id="hospital-name"
              onChange={(e) => setNameEn(e.target.value)}
              value={name_en}
              required
            />
            <label className="label4input" htmlFor="hospital-name_tr">
              Turkish
            </label>
            <input
              placeholder={t("hospital_name")}
              name="hospital-name-tr"
              type="text"
              tabIndex="1"
              id="hospital-name"
              onChange={(e) => setNameTr(e.target.value)}
              value={name_tr}
              required
            />
          </fieldset>

          <fieldset className="inno">
            <label className="label4input">عنوان</label>
            <label className="label-value">
              {data && data?.hospital[0]?.adress}
            </label>
            <label
              className="label4input"
              htmlFor="hospital-address-details_ar"
            >
              Arabic
            </label>
            <textarea
              placeholder={t("address_details")}
              tabIndex="1"
              name="hospital-address-details_ar"
              id="hospital-address-details"
              onChange={(e) => setAdress_ar(e.target.value)}
              value={adress_ar}
              // required
            ></textarea>
            <label
              className="label4input"
              htmlFor="hospital-address-details_en"
            >
              English
            </label>
            <textarea
              placeholder={t("address_details")}
              tabIndex="10"
              name="hospital-address-details_en"
              id="hospital-address-details"
              cols="30"
              rows="4"
              onChange={(e) => setAdress_en(e.target.value)}
              value={adress_en}
              // required
            ></textarea>
            <label
              className="label4input"
              htmlFor="hospital-address-details_en"
            >
              Turkish
            </label>
            <textarea
              placeholder={t("address_details")}
              tabIndex="10"
              name="hospital-address-details_tr"
              id="hospital-address-details"
              cols="30"
              rows="4"
              onChange={(e) => setAdress_tr(e.target.value)}
              value={adress_tr}
              // required
            ></textarea>
          </fieldset>

          <fieldset className="inno innolast">
            <label className="label4input" htmlFor="hospital-info">
              {t("hospital_bio")}
            </label>
            <label className="label-value">
              {data && data?.hospital[0]?.bio}
            </label>
            <label className="label4input" htmlFor="hospital-info_ar">
              Arabic
            </label>
            <textarea
              placeholder={t("hospital_bio")}
              tabIndex="11"
              name="hospital-info_ar"
              id="hospital-info"
              cols="30"
              rows="6"
              value={bio_ar}
              onChange={(e) => setBioAr(e.target.value)}
              required
            ></textarea>
            <label className="label4input" htmlFor="hospital-info_en">
              English
            </label>
            <textarea
              placeholder={t("hospital_bio")}
              tabIndex="11"
              name="hospital-info"
              id="hospital-info_en"
              cols="30"
              rows="6"
              value={bio_en}
              onChange={(e) => setBioEn(e.target.value)}
              required
            ></textarea>
            <label className="label4input" htmlFor="hospital-info_tr">
              Turkish
            </label>
            <textarea
              placeholder={t("hospital_bio")}
              tabIndex="11"
              name="hospital-info_tr"
              id="hospital-info"
              cols="30"
              rows="6"
              value={bio_tr}
              onChange={(e) => setBioTr(e.target.value)}
              required
            ></textarea>
          </fieldset>
          <ToastContainer />
          <fieldset>
            <button
              name="submit-hospital"
              type="submit"
              id="contact-submit2"
              data-submit="...Sending"
            >
              {loading ? <Spinner /> : t("save")}
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default TranslateHospital;
