import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Spinner({ color = "white" }) {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <CircularProgress style={{ color: color }} />
    </div>
  );
}
