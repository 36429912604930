/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import loader from "../../../../Components/loader.gif";
import { HospitalDataContext } from "../../../../store/data-context";
import hospitalServices from "../../../HospitalAccount/services/HospitalServices";
import hospitaloffersController from "../../controller/HospitalOffersController";
import Spinner from "../../../../Components/Spinner";
import notifications from "../../../../Components/toastify/notifications";
import NavBar from "../../../../Components/Nav/NavComponent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Coupon.css";
import CreateCoupon from "../CreateCoupon/CreateCoupon";
import hospitalOffersServices from "../../services/HospitalOffersServices";
import InfoUserCouponModal from "../InfoUserCouponModal/InfoUserCouponModal";
import PatientCouponCard from "../PatientCouponCard/PatientCouponCard";

const Coupon = () => {
  const { t } = useTranslation();
  const { typesDiscount, bookedCoupons } = useContext(HospitalDataContext);
  const navigate = useNavigate();
  const params = useParams();
  const offerId = params.uniqueID;

  const [loading, setLoading] = useState(false);
  const [Coupons, setCoupons] = useState([]);

  useEffect(() => {
    async function fetchingCities() {
      try {
        const res = await hospitalOffersServices.getAllCouponsById(offerId);
        setCoupons(res.coupons);
      } catch (error) {}
    }
    fetchingCities();
  }, [offerId]);
  const getDiscountName = (discount_type_id) => {
    const result = typesDiscount?.discount_types?.filter(
      (discountType) => discountType.id === discount_type_id
    );
    return result[0].name;
  };
  if (0) {
    return <img src={loader} alt="" />;
  } else {
    return (
      <div className="update-cont">
        <NavBar />
        <h2 className="edit-hos-data"> {t("coupons")}</h2>

        <div className="schedule-cont">
          <div className="tab-switch">
            <div className="mytabs coupon-tabs">
              <input
                type="radio"
                id="tabfree"
                name="mytabs"
                defaultChecked="checked"
              />
              <label className="tab-label" htmlFor="tabfree">
                {t("coupons")}
              </label>
              <div className="tab">
                <div className="s-tag1">
                  <div className="coupon">
                    <button
                      className="create-offer"
                      onClick={() => navigate(`/create-coupon/${offerId}`)}
                    >
                      {t("create_coupon")}
                    </button>
                    {Coupons &&
                      Coupons?.map((coupon) => (
                        <div className="pwide-cont card-offers" key={coupon.id}>
                          <div className="growing">
                            <div className="phead">
                              <p>{t("title_coupon")}</p>
                            </div>
                            <div className="pbody">
                              <p>{coupon.title}</p>
                            </div>
                          </div>
                          <div className="growing">
                            <div className="phead">
                              <p>{t("type_of_discount")}</p>
                            </div>
                            <div className="pbody">
                              <p>{getDiscountName(coupon.discount_type_id)}</p>
                            </div>
                          </div>
                          <div className="growing">
                            <div className="phead">
                              <p>{t("details")}</p>
                            </div>
                            <div className="pbody card-btns">
                              <button
                                onClick={() =>
                                  navigate(`/edit-coupon/${coupon.id}`)
                                }
                                id={coupon.id}
                                className="first-btn"
                              >
                                {t("edit")}
                              </button>
                              <button
                                onClick={(e) =>
                                  hospitaloffersController.toggleCouponStatus(
                                    e,
                                    coupon.id,
                                    notifications,
                                    t
                                  )
                                }
                                className={
                                  coupon.is_active ? "hide" : "show-hcp"
                                }
                              >
                                {coupon.is_active ? t("hidden") : t("show")}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <input type="radio" id="tabgold" name="mytabs" />
              <label className="tab-label" htmlFor="tabgold">
                {t("booked_coupons")}
              </label>
              <div className="tab">
                <div className="s-tag1">
                  <div className="coupon">
                    {bookedCoupons &&
                      bookedCoupons?.coupons.map((reservedCoupon) => (
                        <PatientCouponCard
                          key={reservedCoupon.id}
                          id={reservedCoupon.id}
                          name={reservedCoupon.offering.title}
                          create_at={reservedCoupon.created_at}
                          last_used={reservedCoupon.last_used}
                          status={reservedCoupon.status}
                          first_name={reservedCoupon.user.first_name}
                          last_name={reservedCoupon.user.last_name}
                          email={reservedCoupon.user.email}
                          phone={reservedCoupon.user.phone}
                          max_allowed_uses={reservedCoupon.max_allowed_uses}
                          number_used={reservedCoupon.number_used}
                        />
                      ))}
                  </div>
                </div>
              </div>

              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Coupon;
