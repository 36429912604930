import {
  Nav,
  Navbar,
  Container,
  NavDropdown,
  FormGroup,
  Button,
  Form,
} from "react-bootstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavComponent.css";
import AuthContext from "../../store/auth-context";
// import logo from './logo-white-copy.jpeg';
// import logo from '../../../public/logo-white-copy.jpeg';

const NavBar = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logoutHandler = () => {
    authCtx.logout();
    navigate("/");
  };

  const languages = [
    {
      code: "ar",
      name: "العربية",
      country_code: "sa",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
      dir: "ltr",
    },
    {
      code: "tr",
      name: "Turkish",
      country_code: "tr",
      dir: "ltr",
    },
  ];
  const currentLanguageCode = cookies.get("i18next") || "ar";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "rtl";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <div className="navbar-cont">
      <Navbar bg="white" expand="md" className="navbar-all">
        <Container fluid>
          <Navbar.Brand className="nav-img-cont" href="/">
            <img src="https://www.sehapracto.com/pics/logo.png" alt="logo" />
            {/* contents of the img tag alt="" src="../../../public/logo-white-copy.jpeg" width="20px" height="20px" className="d-inline-block align-top" */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            {currentLanguage.code === "en" && (
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link href="/" className="ms-3 me-3 navtext text-black">
                  Home
                </Nav.Link>
                <Nav.Link
                  href="https://www.sehapracto.com/en/about/"
                  className="ms-3 me-3 navtext text-black"
                >
                  About Us
                </Nav.Link>
                {/* <Nav.Link href={null} className='ms-3 me-3 navtext text-black'>{t("medical_staff")}</Nav.Link> */}
                <Nav.Link
                  href="https://www.sehapracto.com/en/call_us/"
                  className="ms-3 me-3 navtext text-black"
                >
                  Contact Us
                </Nav.Link>
              </Nav>
            )}
            {currentLanguage.code === "ar" && (
              <Nav
                className="ms-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link href="/" className="ms-3 me-3 navtext text-black">
                  الرئيسية
                </Nav.Link>
                <Nav.Link
                  href="https://www.sehapracto.com/about/"
                  className="ms-3 me-3 navtext text-black"
                >
                  من نحن
                </Nav.Link>
                {/* <Nav.Link href={null} className='ms-3 me-3 navtext text-black'>الكادر الطبي</Nav.Link> */}
                <Nav.Link
                  href="https://www.sehapracto.com/call_us/"
                  className="ms-3 me-3 navtext text-black"
                >
                  اتصل بنا
                </Nav.Link>
              </Nav>
            )}
            {currentLanguage.code === "tr" && (
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link href="/" className="ms-3 me-3 navtext text-black">
                  Anasayfa
                </Nav.Link>
                <Nav.Link
                  href="https://www.sehapracto.com/en/about/"
                  className="ms-3 me-3 navtext text-black"
                >
                  Hakkımızda
                </Nav.Link>
                {/* <Nav.Link href={null} className='ms-3 me-3 navtext text-black'>{t("medical_staff")}</Nav.Link> */}
                <Nav.Link
                  href="https://www.sehapracto.com/en/call_us/"
                  className="ms-3 me-3 navtext text-black"
                >
                  İletişim
                </Nav.Link>
              </Nav>
            )}
            {/* old implemented code before adding Turkish */}
            {/* {currentLanguage.code === "en" ? ( */}
            {/* <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll > */}
            {/* <Nav.Link href='/' className='ms-3 me-3 navtext text-black'>Home</Nav.Link> */}
            {/* <Nav.Link href='https://www.sehapracto.com/en/about/' className='ms-3 me-3 navtext text-black'>About Us</Nav.Link> */}
            {/* <Nav.Link href={null} className='ms-3 me-3 navtext text-black'>{t("medical_staff")}</Nav.Link> */}
            {/* <Nav.Link href='https://www.sehapracto.com/en/call_us/' className='ms-3 me-3 navtext text-black'>Contact Us</Nav.Link> */}
            {/* </Nav> */}
            {/* ) : ( */}
            {/* <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll > */}
            {/* <Nav.Link href='/' className='ms-3 me-3 navtext text-black'>الرئيسية</Nav.Link> */}
            {/* <Nav.Link href='https://www.sehapracto.com/about/' className='ms-3 me-3 navtext text-black'>من نحن</Nav.Link> */}
            {/* <Nav.Link href={null} className='ms-3 me-3 navtext text-black'>الكادر الطبي</Nav.Link> */}
            {/* <Nav.Link href='https://www.sehapracto.com/call_us/' className='ms-3 me-3 navtext text-black'>اتصل بنا</Nav.Link> */}
            {/* </Nav> */}
            {/* )} */}
            <NavDropdown
              className="lang-selector"
              title={t("language")}
              id="basic-nav-dropdown"
            >
              {languages.map(({ code, name, country_code }) => (
                <NavDropdown.Item
                  key={country_code}
                  href="#"
                  onClick={() => {
                    i18next.changeLanguage(code);
                    window.location.reload(false);
                    return;
                  }}
                >
                  <span
                    className={`flag-icon flag-icon-${country_code}`}
                  ></span>
                  {name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            {/* {!isLoggedIn ?
                            <Form action='#' className="d-flex formob1">
                                <Button variant="outline-primary" className='btn btn-primary text-white px-4 py-2'>
                                    <a href="/login">{t("login")}</a>
                                </Button>
                            </Form> :
                            <Form action='#' className="d-flex formob1">
                                <Button variant="outline-primary" className='btn btn-primary text-white px-4 py-2'>
                                    <a onClick={logoutHandler}>{t("signout")}</a>
                                </Button>
                            </Form>
                        } */}
            {!isLoggedIn ? (
              <Form>
                <FormGroup role="form">
                  <Button
                    variant="outline-primary"
                    className="btn btn-primary nav-custom text-white btn-large centerButton px-4 py-2"
                    type="submit"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {t("login")}
                  </Button>
                </FormGroup>
              </Form>
            ) : (
              <Form onSubmit={logoutHandler}>
                <FormGroup role="form">
                  <Button
                    className="btn btn-primary nav-custom text-white btn-large centerButton px-4 py-2"
                    type="submit"
                  >
                    {t("signout")}
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
