// import Footer from "../../../Components/footer/Footer";
import NavComponent from "../../../Components/Nav/NavComponent";
import AdminMain from "../components/AdminMain/AdminMain";

const Home = () => {
  return (
    <div>
      <NavComponent />
      <AdminMain />
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
