/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./HcpModal.css";
import { useTranslation } from "react-i18next";
import { HospitalDataContext } from "../../../../store/data-context";
import appointmentController from "../../controller/AppointmentController";
import notifications from "../../../../Components/toastify/notifications";
function HcpModal({ handleCloseHcp, showHcp }) {
  const { t } = useTranslation();
  const [endTime, setEndTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [errortime, setErrorTime] = useState("");

  const [durationTime, setDurationTime] = useState("");
  const [hcpId, setHcpId] = useState();
  const [day, setDay] = useState("");
  const { week, times, hcp, duration } = useContext(HospitalDataContext);
  // const handleHcpApppointment = async (e) => {
  //   e.preventDefault();
  //   const res = await appointmentController.handleHcpAppointment(
  //     hcpId,
  //     startTime,
  //     endTime,
  //     durationTime,
  //     day
  //   );

  //   if (res.message === "success") {
  //     notifications.notifySuccess(t("done_hcp_appointment"));
  //     setStartTime("");
  //     setEndTime("");
  //     setDay();
  //     setDurationTime();
  //     setHcpId();
  //     handleCloseHcp();
  //   } else {
  //     if (hcpId === undefined) {
  //       notifications.notifyError(t("error_id_hcp"));
  //     } else if (res.error.day) {
  //       notifications.notifyError(t("error_work_day"));
  //     } else if (res.error.start) {
  //       notifications.notifyError(t("error_start_time"));
  //     } else if (res.error.end) {
  //       notifications.notifyError(t("error_end_time"));
  //     } else {
  //       notifications.notifyError(t("error_duration"));
  //     }
  //   }
  // };
  // const handleStartTime = (e) => {
  //   setStartTime(e.target.value);
  // };
  // const handleEndTime = (e) => {
  //   setEndTime(e.target.value);
  // };
  // const handleDurationTime = (e) => {
  //   setDurationTime(e.target.value);
  // };
  // const handleDay = (e) => {
  //   setDay(e.target.value);
  // };
  return (
    <>
      {/* here Hcp */}
      <Modal show={showHcp} onHide={handleCloseHcp}>
        <Modal.Header>
          <Modal.Title className="title-model-clinic-appointment">
            {t("hcp_working_hours")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={(e) =>
              appointmentController.handleHcpApppointment(
                e,
                hcpId,
                startTime,
                endTime,
                durationTime,
                day,
                notifications,
                t,
                setStartTime,
                setEndTime,
                setDay,
                setDurationTime,
                setHcpId,
                handleCloseHcp
              )
            }
          >
            {t("choose_hcp")}
            <select
              className="select-times"
              name="hospital-country"
              id="hospital-address1"
              value={hcpId}
              onChange={(e) => setHcpId(e.target.value)}
            >
              <option value="invalid">-- {t("choose_hcp")} --</option>
              {hcp?.hospital_hcp_departments?.map((depart) => {
                return depart?.hospital_hcp.map((hcp) => {
                  return (
                    <option key={hcp.hcp.id} value={hcp.id}>
                      {hcp.hcp?.title
                        ? hcp.hcp.title
                        : "" +
                          " " +
                          hcp.hcp?.first_name +
                          " " +
                          hcp.hcp?.last_name}
                    </option>
                  );
                });
              })}
            </select>{" "}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {t("hcp_working_days")}
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) => appointmentController.handleDay(e, setDay)}
              >
                <option value=""> {t("day")} </option>

                {week.map((day) => (
                  <option key={day.id} value={day.value}>
                    {day.day}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("work_time")} </Form.Label>
              <br />
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) =>
                  appointmentController.handleStartTime(e, setStartTime)
                }
              >
                <option value=""> {t("from")} </option>
                {times.map((time) => (
                  <option key={time.id} value={time.hour}>
                    {time.hour}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <>
                <select
                  className="select-times"
                  name=""
                  id=""
                  onChange={(e) =>
                    appointmentController.handleEndTime(
                      e,
                      startTime,
                      setErrorTime,
                      setEndTime
                    )
                  }
                >
                  <option value=""> {t("to")} </option>

                  {times.map((time) => (
                    <option key={time.id} value={time.hour}>
                      {time.hour}
                    </option>
                  ))}
                </select>
                <span>{errortime}</span>
              </>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("appointment_duration")}</Form.Label>
              <select
                className="select-times"
                name=""
                id=""
                onChange={(e) =>
                  appointmentController.handleDurationTime(e, setDurationTime)
                }
              >
                <option value=""> {t("duration")} </option>

                {duration.map((time) => (
                  <option key={time.id} value={time.time}>
                    {time.time}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Button variant="primary" type="submit" className="save-btn">
              {t("save")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* end  clinic*/}
    </>
  );
}

export default HcpModal;
